/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback } from 'react';
import { AppContext, AppProps } from 'next/app';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { SagaStore, wrapper } from '../redux/reduxStore';
import setupI18N from '../utils/localization/i18n';
import '../styles/globals.css';
import { END } from 'redux-saga';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Palette from 'styles/Palette';
import { DataStorage } from 'utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsyncAction, logout } from 'redux/auth/AuthActions';
import { useRouter } from 'next/router';
import { setUpRequestInterceptor } from 'redux/repository';
import { setLocale, toggleIsLoading } from 'redux/appState/AppStateActions';
import useIsFnB from 'hooks/useIsFnB';
import {
  handlePageView,
  trackEnteredFoodMode,
  trackEnteredEComMode,
  initAnalytics,
  trackBuyerSession,
} from 'utils/analytics';
import { initYbugIO } from 'utils/ybugio';
import TagManager from 'react-gtm-module';
import {
  DEFER_LOADING_ANALYTICS_TIME_MILLIS,
  DEFER_LOADING_SENTRY_TIME_MILLIS,
  DEFER_LOADING_YBUG_TIME_MILLIS,
  isServer,
} from 'utils/constants';
import { initSentry } from 'utils/sentry';
import { sGetStore } from 'redux/store/StoreSelectors';
import { sGetIsLoading } from 'redux/appState/AppStateSelectors';

const LoadingAnimation = dynamic(() => import('components/LoadingAnimation/LoadingAnimation'));

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Source Sans Pro'].join(','),
  },
  palette: {
    primary: {
      light: '#76d5c1',
      main: Palette.ZAAPI2,
      dark: '#007464',
      contrastText: Palette.WHITE,
    },
  },
});

setupI18N();
const AUTH_ROUTES = ['/[slug]/account-details'];

const GOOGLE_API_KEYS = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

const WrappedApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.removeItem('uniqueProductList');
    };
  });
  const isLoading = useSelector(sGetIsLoading);
  useHandleAuthState();
  useTrackStoreMode();
  useInitAnalytics();
  useInitYbugIO();
  useUpdateVisitorCount();
  useInitGTM();
  useInitSentry();

  return (
    <ThemeProvider theme={theme}>
      <Script
        strategy="lazyOnload"
        onLoad={() => {
          setTimeout(() => {
            initAnalytics();
          }, DEFER_LOADING_ANALYTICS_TIME_MILLIS);
        }}
      />
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEYS}&libraries=places&region=TH&language=th`}
        strategy="beforeInteractive"
      />
      <>
        {isLoading && <LoadingAnimation />}

        <Component {...pageProps} />
      </>
    </ThemeProvider>
  );
};

const useHandleAuthState = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isFnB = useIsFnB();

  const handleAuthState = useCallback(async () => {
    try {
      dispatch(toggleIsLoading(true));
      const access_token = DataStorage.load({ key: 'access_token' });
      if (access_token && access_token !== null && access_token.length > 0) {
        await setUpRequestInterceptor();
        dispatch(loginAsyncAction(access_token));
        dispatch(setLocale());
      } else {
        if (AUTH_ROUTES.includes(router.pathname)) {
          router.replace(`/${router.query.slug || ''}`);
        } else {
          dispatch(logout());
          dispatch(setLocale());
        }
      }

      handlePageView(router.pathname, router.asPath, isFnB);
    } finally {
      dispatch(toggleIsLoading(false));
    }
  }, [router]);

  useEffect(() => {
    handleAuthState();
  }, [router.pathname]);
};

const useTrackStoreMode = () => {
  const isFnB = useIsFnB();

  useEffect(() => {
    setTimeout(() => {
      if (isFnB) {
        trackEnteredFoodMode();
      } else {
        trackEnteredEComMode();
      }
    }, DEFER_LOADING_ANALYTICS_TIME_MILLIS);
  }, [isFnB]);
};

const useInitAnalytics = () => {
  const ISSERVER = typeof window === 'undefined';

  useEffect(() => {
    if (!ISSERVER) {
      setTimeout(() => {
        initAnalytics();
      }, DEFER_LOADING_ANALYTICS_TIME_MILLIS);
    }
  }, [ISSERVER]);
};

const useInitYbugIO = () => {
  const ISSERVER = typeof window === 'undefined';

  useEffect(() => {
    if (!ISSERVER) {
      setTimeout(() => {
        initYbugIO();
      }, DEFER_LOADING_YBUG_TIME_MILLIS);
    }
  }, [ISSERVER]);
};

const useInitGTM = () => {
  useEffect(() => {
    if (!isServer) {
      setTimeout(() => {
        TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM });
      }, DEFER_LOADING_ANALYTICS_TIME_MILLIS);
    }
  }, []);
};

const useUpdateVisitorCount = () => {
  const ISSERVER = typeof window === 'undefined';
  const store = useSelector(sGetStore);

  useEffect(() => {
    if (!ISSERVER) {
      trackBuyerSession(store);
    }
  }, [ISSERVER, store]);
};

const useInitSentry = () => {
  useEffect(() => {
    if (!isServer) {
      setTimeout(() => {
        initSentry();
      }, DEFER_LOADING_SENTRY_TIME_MILLIS);
    }
  }, []);
};

export default wrapper.withRedux(WrappedApp);

export const getInitialProps = async ({ Component, ctx }: AppContext) => {
  // 1. Wait for all page actions to dispatch
  const pageProps = {
    ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
  };
  // 2. Stop the saga if on server
  if (ctx.req) {
    (ctx as any).store.dispatch(END);
    await ((ctx as any).store as SagaStore).sagaTask.toPromise();
  }
  // 3. Return props
  return {
    pageProps,
  };
};
