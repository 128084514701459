import { createSelector } from 'reselect';
import { RootState } from 'redux/reduxStore';
import find from 'lodash/find';
import { DiscountType } from 'types/discount';

export const sGetIsSelfPickUp = (state: RootState) => state.cart.isSelfPickUp;

export const sGetFinalCartPrice = (state: RootState) => state.cart.finalTotalPrice || state.cart.totalPrice;

export const sGetCart = (state: RootState) => state.cart;
export const sGetCartId = (state: RootState) => state.cart.id;
export const sGetStoreIdInCart = (state: RootState) => state.cart.storeId;

export const sGetErrorPostalCode = (state: RootState) => state.cart.errorPostalCode;

export const sGetPaymentMethodInfo = (state: RootState) => state.cart.paymentMethodInfo;
export const sGetPaymentMethodValidateForm = (state: RootState) => state.cart.paymentMethodValidateForm;
export const sGetIsCheckoutSuccess = (state: RootState) => state.cart.isCheckoutSuccess;

export const sGetErrorRemarks = (state: RootState) => state.cart.errorRemarks;
export const sGetCartItems = (state: RootState) => state.cart.items;
export const sGetCartDeliveryOption = (state: RootState) => state.cart?.deliveryOption;
export const sGetBaseShippingFee = createSelector(sGetCart, cart => cart.shippingFee || cart.flatShippingFee || 0);
export const sGetTotalShippingFee = createSelector(
  sGetIsSelfPickUp,
  sGetBaseShippingFee,
  (isSelfPickUp, baseShippingFee) => (isSelfPickUp ? 0 : baseShippingFee),
);

export const sGetCartDiscountRes = (state: RootState) => state.cart.discountRes;
export const sGetCartManualDiscount = createSelector(sGetCartDiscountRes, discount =>
  find(discount, { type: DiscountType.MANUAL }),
);

export const sGetCartTotalPrice = (state: RootState) => state.cart.totalPrice;
