/* eslint-disable @typescript-eslint/no-explicit-any */

import { DiscountActions, IDiscountGeneralAction } from './DiscountActions';
import {
  requestCreateDiscount,
  requestDeleteDiscount,
  requestGetDiscounts,
  requestReorderDiscount,
  requestSearchDiscounts,
  requestUpdateDiscount,
} from './DiscountRepository';
import { Store } from '../../types';
import { put, takeEvery, all, select, takeLatest } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';
import { RootState } from '../reduxStore';
import { logError } from '../../utils/error';
import { Discount, DiscountFilterInputParams } from '../../types/discount';

const call: any = Effects.call;

export const getStore = (state: RootState) => state.store;

function* fetchDisplayedDiscountsAsync(action: IDiscountGeneralAction<DiscountFilterInputParams>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    const discounts: Discount[] = yield call(
      requestSearchDiscounts,
      store.id,
      action.data.type,
      action.data.searchText,
    );
    yield put({ type: DiscountActions.SET_DISPLAYED_DISCOUNTS_DATA, data: discounts });
  } catch (error) {
    logError(error);
  }
}

function* watchFetchDisplayedDiscountsAsync() {
  yield takeEvery(DiscountActions.SET_DISPLAYED_DISCOUNTS_DATA_ASYNC, fetchDisplayedDiscountsAsync);
}

export function* fetchDiscountsAsync() {
  const store: Partial<Store> = yield select(getStore);
  const discounts: Discount[] = yield call(requestGetDiscounts, store.id);
  yield put({ type: DiscountActions.SET_DISCOUNTS_DATA, data: discounts });
}

function* watchFetchDiscountsAsync() {
  yield takeLatest(DiscountActions.SET_DISCOUNTS_DATA_ASYNC, fetchDiscountsAsync);
}

function* deleteDiscountAsync(action: IDiscountGeneralAction<Partial<Discount>>) {
  const store: Partial<Store> = yield select(getStore);
  yield call(requestDeleteDiscount, store?.id, action.data.id);
  yield put({ type: DiscountActions.DELETE_DISCOUNT, data: action.data });
}

function* watchDeleteDiscountAsync() {
  yield takeEvery(DiscountActions.DELETE_DISCOUNT_ASYNC, deleteDiscountAsync);
}

function* updateDiscountAsync(action: IDiscountGeneralAction<Partial<Discount>>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    yield call(requestUpdateDiscount, store?.id, action.data.id, action.data);
    yield put({ type: DiscountActions.UPDATE_DISCOUNT, data: action.data });
  } catch (error) {
    logError(error);
  }
}

function* watchUpdateDiscountAsync() {
  yield takeEvery(DiscountActions.UPDATE_DISCOUNT_ASYNC, updateDiscountAsync);
}

function* reorderDiscountAsync(action: IDiscountGeneralAction<Partial<Discount>[]>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    yield call(requestReorderDiscount, store?.id, action.data);
    yield put({ type: DiscountActions.REORDER_DISCOUNT, data: action.data });
  } catch (error) {
    logError(error);
  }
}

function* watchReorderDiscountAsync() {
  yield takeEvery(DiscountActions.REORDER_DISCOUNT_ASYNC, reorderDiscountAsync);
}

function* createDiscountAsync(action: IDiscountGeneralAction<Partial<Discount>>) {
  try {
    const createdDiscount: Discount = yield call(requestCreateDiscount, action.data);
    yield put({ type: DiscountActions.CREATE_DISCOUNT, data: createdDiscount });
  } catch (error) {
    logError(error);
  }
}

function* watchCreateDiscountAsync() {
  yield takeEvery(DiscountActions.CREATE_DISCOUNT_ASYNC, createDiscountAsync);
}

function* discountSaga() {
  yield all([
    watchFetchDisplayedDiscountsAsync(),
    watchDeleteDiscountAsync(),
    watchUpdateDiscountAsync(),
    watchFetchDiscountsAsync(),
    watchReorderDiscountAsync(),
    watchCreateDiscountAsync(),
  ]);
}

export default discountSaga;
