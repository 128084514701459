import axios from 'axios';
import {
  ActionResponse,
  IProductCart,
  IUpdateDeliveryOptionParams,
  IUpdateDiscountParams,
  PaymentStatusResponseDto,
} from 'types';
const API_AUTHORIZATION = process.env.NEXT_PUBLIC_API_STORE;

export const requestPutCart = async (storeId: string, data: IProductCart, dryRun = false) => {
  const res = await axios.put(`${API_AUTHORIZATION}/api/store/buyer/cart/store/${storeId}?dryRun=${dryRun}`, data);
  return res.data;
};

export const requestGetCart = (cartId: string) => {
  return axios.get(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId?.replace(/['"]+/g, '')}`).then(res => res.data);
};
export const requestCartCheckout = (cartId: string, data) => {
  return axios
    .post(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId?.replace(/['"]+/g, '')}/checkout`, data)
    .then(res => res.data);
};
export const requestGetShippingFee = (cartId: string, data: IUpdateDeliveryOptionParams) => {
  if (!cartId) return;
  return axios
    .get<ActionResponse>(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId?.replace(/['"]+/g, '')}/shipping-fee`, {
      params: data,
    })
    .then(res => res.data);
};
export const requestPutDeliveryOption = (cartId: string, data: IUpdateDeliveryOptionParams) => {
  return axios
    .put<ActionResponse>(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId}/shipping-fee`, null, { params: data })
    .then(res => res.data);
};
export const requestApplyDiscount = (cartId: string, data: IUpdateDiscountParams) => {
  return axios
    .post(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId?.replace(/['"]+/g, '')}/apply-discount`, null, {
      params: data,
    })
    .then(res => res.data);
};

export const requestRemoveDiscount = (cartId: string, data: IUpdateDiscountParams) => {
  return axios
    .post(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId?.replace(/['"]+/g, '')}/remove-discount`, null, {
      params: data,
    })
    .then(res => res.data);
};

export const requestGetProductAfterLogin = (storeId: string) => {
  return axios.get(`${API_AUTHORIZATION}/api/store/buyer/cart/store/${storeId}`).then(res => res.data);
};

export const putRefreshCartDetail = (cartId: string) => {
  return axios.put(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId}/refresh`).then(res => res.data);
};

export const requestGetCartPaymentStatus = async (cartId: string) => {
  return axios
    .get<PaymentStatusResponseDto>(`${API_AUTHORIZATION}/api/store/buyer/cart/${cartId}/payment-status`)
    .then(res => {
      return res.data;
    });
};
