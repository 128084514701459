import { SavedMethod, SavedPaymentMethodsDto } from 'types/merchant';
import {
  ConfirmModalData,
  ErrorModalData,
  ILocationToastState,
  IUpdateDeliveryOptionParams,
  ModalWithButton,
  PaymentImageModal,
  PostCodeData,
  StickyBarData,
  SuccessModalData,
} from '../../types';
import { DEFAULT_APP_STATE } from './AppStateReducer';

export enum AppStateActions {
  SET_PRODUCT_BOTTOM_SHEET_SHOWING = 'SET_PRODUCT_BOTTOM_SHEET_SHOWING',
  TOGGLE_APP_STATE_FLAG = 'TOGGLE_APP_STATE_FLAG',
  UPDATE_ERROR_MODAL_DATA = 'UPDATE_ERROR_MODAL_DATA',
  UPDATE_CONFIRM_MODAL_DATA = 'UPDATE_CONFIRM_MODAL_DATA',
  UPDATE_SUCCESS_MODAL_DATA = 'UPDATE_SUCCESS_MODAL_DATA',
  UPDATE_STICKY_BAR_DATA = 'UPDATE_STICKY_BAR_DATA',
  UPDATE_POSTCODE_DATA = 'UPDATE_POSTCODE_DATA',
  GET_POSTCODE_DATA_ASYNC = 'GET_POSTCODE_DATA_ASYNC',
  UPDATE_POSTCODE_TXT = 'UPDATE_POSTCODE_TXT',
  TOGGLE_POSTCODE_ERROR = 'TOGGLE_POSTCODE_ERROR',
  TOGGLE_HIDE_POSTCODE = 'TOGGLE_HIDE_POSTCODE',

  TOGGLE_DIALOG_LOGIN = 'TOGGLE_DIALOG_LOGIN',
  SHOW_MODAL_WITH_BUTTON = 'SHOW_MODAL_WITH_BUTTON',
  HIDE_MODAL_WITH_BUTTON = 'HIDE_MODAL_WITH_BUTTON',
  TOGGLE_MODEL_SUCCESS = 'SHOW_MODEL_SUCCESS',

  SET_TITlE_FORM = 'SET_TITlE_FORM',
  SET_LOCALE = 'SET_LOCALE',
  SET_LOCALE_ASYNC = 'SET_LOCALE_ASYNC',

  SET_STEP_CHECKOUT = 'SET_STEP_CHECKOUT',
  TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING',
  SCREEN_HEADER_STYLE = 'SCREEN_HEADER_STYLE',
  ORDER_CHECKOUT_STEP = 'ORDER_CHECKOUT_STEP',
  TOGGLE_HAS_SHIPPING_FEE = 'TOGGLE_HAS_SHIPPING_FEE',

  SHOW_PAYMENT_IMAGE_MODAL = 'SHOW_PAYMENT_IMAGE_MODAL',
  HIDE_PAYMENT_IMAGE_MODAL = 'HIDE_PAYMENT_IMAGE_MODAL',
  SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS',
  CREATE_SAVED_METHODS_ASYNC = 'CREATE_SAVED_METHODS_ASYNC',
  DELETE_SAVED_METHODS_ASYNC = 'DELETE_SAVED_METHODS_ASYNC',
  GET_SAVED_METHODS_ASYNC = 'GET_SAVED_METHODS_ASYNC',
  TOGGLE_TRUE_MONEY_PAYMENT = 'TOGGLE_TRUE_MONEY_PAYMENT',
  TOGGLE_SHOW_PAYMENT_MODAL = 'TOGGLE_SHOW_PAYMENT_MODAL',

  TOGGLE_SHOW_LOADING_MODAL_WITH_TEXT = 'TOGGLE_SHOW_LOADING_MODAL_WITH_TEXT',
  TOGGLE_HIDE_LOADING_MODAL_WITH_TEXT = 'TOGGLE_HIDE_LOADING_MODAL_WITH_TEXT',
  SET_IS_BUYER_FILL_ADDRESS = 'SET_IS_BUYER_FILL_ADDRESS',

  SET_IS_POLLING_CART_PAYMENT_STATUS = 'SET_IS_POLLING_CART_PAYMENT_STATUS',
  SET_STICKY_POSTCODE_BANNER_HEIGHT = 'SET_STICKY_POSTCODE_BANNER_HEIGHT',
  SET_LOCATION_TOAST_STATE = 'SET_LOCATION_TOAST_STATE',
  TOGGLE_IS_LOCATION_ON_MAP_OUT_OF_RANGE = 'TOGGLE_IS_LOCATION_ON_MAP_OUT_OF_RANGE',
  GET_LOCATION_ON_MAP_DISTANCE_ASYNC = 'GET_LOCATION_ON_MAP_DISTANCE_ASYNC',
  TOGGLE_IS_PENDING_TRANSACTION = 'TOGGLE_IS_PENDING_TRANSACTION',

  SET_DISABLED_ADDRESS_FIELDS = 'SET_DISABLED_ADDRESS_FIELDS',
}

export interface IAppStateItem<T> {
  key: string;
  value: T;
}

export interface IAppStateGeneralAction<T> {
  type: AppStateActions;
  data: T;
}

export const setProductBottomSheetShowingAction = (isShowing: boolean): IAppStateGeneralAction<boolean> => {
  return { type: AppStateActions.SET_PRODUCT_BOTTOM_SHEET_SHOWING, data: isShowing };
};

export const toggleAppStateFlag = (key: string, value: boolean): IAppStateGeneralAction<IAppStateItem<boolean>> => {
  return { type: AppStateActions.TOGGLE_APP_STATE_FLAG, data: { key, value } };
};

export const toggleIsProductsLoading = (loading: boolean) => toggleAppStateFlag('isProductsLoading', loading);

export const toggleIsCategoriesLoading = (loading: boolean) => toggleAppStateFlag('isCategoriesLoading', loading);

export const toggleIsSearchProductsLoading = (loading: boolean) => {
  return toggleAppStateFlag('isSearchProductsLoading', loading);
};

export const updateErrorModalData = (
  modalData: Partial<ErrorModalData>,
): IAppStateGeneralAction<Partial<ErrorModalData>> => {
  return { type: AppStateActions.UPDATE_ERROR_MODAL_DATA, data: modalData };
};

export const updateConfirmModalData = (
  modalData: Partial<ConfirmModalData>,
): IAppStateGeneralAction<Partial<ConfirmModalData>> => {
  return { type: AppStateActions.UPDATE_CONFIRM_MODAL_DATA, data: modalData };
};

export const resetConfirmModalData = (): IAppStateGeneralAction<Partial<ConfirmModalData>> => {
  return {
    type: AppStateActions.UPDATE_CONFIRM_MODAL_DATA,
    data: DEFAULT_APP_STATE.confirmModalData,
  };
};

export const updateSuccessModalData = (
  modalData: Partial<SuccessModalData>,
): IAppStateGeneralAction<Partial<SuccessModalData>> => {
  return { type: AppStateActions.UPDATE_SUCCESS_MODAL_DATA, data: modalData };
};

export const updateStickyBarData = (
  stickyBarData: Partial<StickyBarData>,
): IAppStateGeneralAction<Partial<StickyBarData>> => {
  return { type: AppStateActions.UPDATE_STICKY_BAR_DATA, data: stickyBarData };
};

export const updatePostCodeData = (
  postcodeData: Partial<PostCodeData>,
): IAppStateGeneralAction<Partial<PostCodeData>> => {
  return { type: AppStateActions.UPDATE_POSTCODE_DATA, data: postcodeData };
};

export const getPostCodeDataAsync = (data): IAppStateGeneralAction<IUpdateDeliveryOptionParams> => {
  return { type: AppStateActions.GET_POSTCODE_DATA_ASYNC, data };
};

export const updatePostCodeTxt = (postcode: string): IAppStateGeneralAction<string> => {
  return { type: AppStateActions.UPDATE_POSTCODE_TXT, data: postcode };
};

export const togglePostCodeError = (newState: boolean): IAppStateGeneralAction<boolean> => {
  return { type: AppStateActions.TOGGLE_POSTCODE_ERROR, data: newState };
};

export const toggleHidePostCode = (newState: boolean): IAppStateGeneralAction<boolean> => {
  return { type: AppStateActions.TOGGLE_HIDE_POSTCODE, data: newState };
};

export const toggleDialogLogin = (newState: boolean): IAppStateGeneralAction<boolean> => {
  return {
    type: AppStateActions.TOGGLE_DIALOG_LOGIN,
    data: newState,
  };
};

export const showModalWithButton = (newState: ModalWithButton): IAppStateGeneralAction<ModalWithButton> => {
  return {
    type: AppStateActions.SHOW_MODAL_WITH_BUTTON,
    data: newState,
  };
};
export const hideModalWithButton = (): { type: AppStateActions } => {
  return {
    type: AppStateActions.HIDE_MODAL_WITH_BUTTON,
  };
};

export const showPaymentImageModal = (
  newState: Partial<PaymentImageModal>,
): IAppStateGeneralAction<Partial<PaymentImageModal>> => {
  return {
    type: AppStateActions.SHOW_PAYMENT_IMAGE_MODAL,
    data: newState,
  };
};
export const hidePaymentImageModal = (): { type: AppStateActions } => {
  return {
    type: AppStateActions.HIDE_PAYMENT_IMAGE_MODAL,
  };
};

export const toggleModelSuccess = (newState: boolean): IAppStateGeneralAction<boolean> => {
  return {
    type: AppStateActions.TOGGLE_MODEL_SUCCESS,
    data: newState,
  };
};

export const setTitleForm = (newState: string): IAppStateGeneralAction<string> => {
  return {
    type: AppStateActions.SET_TITlE_FORM,
    data: newState,
  };
};

export const setLocale = (): { type: AppStateActions } => {
  return {
    type: AppStateActions.SET_LOCALE_ASYNC,
  };
};

export const toggleHasShippingFee = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_HAS_SHIPPING_FEE, data };
};
export const toggleIsLoading = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_IS_LOADING, data };
};

export const setScreenHeaderStyle = (data: string) => {
  return { type: AppStateActions.SCREEN_HEADER_STYLE, data };
};

export const setOrderCheckoutStep = (data: number) => {
  return { type: AppStateActions.ORDER_CHECKOUT_STEP, data };
};
export const updatePaymentMethods = (data: unknown[]) => {
  return { type: AppStateActions.SET_PAYMENT_METHODS, data };
};
export const toggleTrueMoneyPayment = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_TRUE_MONEY_PAYMENT, data };
};
export const toggleShowPaymentModal = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_SHOW_PAYMENT_MODAL, data };
};
export const createSavedMethodsAsync = (data: SavedPaymentMethodsDto) => {
  return {
    type: AppStateActions.CREATE_SAVED_METHODS_ASYNC,
    data,
  };
};
export const fetchSavedMethodAsync = () => {
  return {
    type: AppStateActions.GET_SAVED_METHODS_ASYNC,
  };
};
export const deleteSavedMethodAsync = (data: SavedMethod) => {
  return {
    type: AppStateActions.DELETE_SAVED_METHODS_ASYNC,
    data,
  };
};
export const toggleShowLoadingModalWithText = (data: string) => {
  return {
    type: AppStateActions.TOGGLE_SHOW_LOADING_MODAL_WITH_TEXT,
    data,
  };
};
export const toggleHideLoadingModalWithText = () => {
  return {
    type: AppStateActions.TOGGLE_HIDE_LOADING_MODAL_WITH_TEXT,
  };
};
export const setIsBuyerFillAddress = (data: boolean) => {
  return {
    type: AppStateActions.SET_IS_BUYER_FILL_ADDRESS,
    data,
  };
};
export const setIsPollingCartPaymentStatus = (data: boolean) => {
  return { type: AppStateActions.SET_IS_POLLING_CART_PAYMENT_STATUS, data };
};
export const setPostCodeBannerHeight = (data: number) => {
  return { type: AppStateActions.SET_STICKY_POSTCODE_BANNER_HEIGHT, data };
};
export const setLocationToastState = (data: ILocationToastState) => {
  return { type: AppStateActions.SET_LOCATION_TOAST_STATE, data };
};
export const toggleIsLocationOnMapOutOfRange = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_IS_LOCATION_ON_MAP_OUT_OF_RANGE, data };
};
export const getLocationOnMapDistanceAsync = (data: IUpdateDeliveryOptionParams) => {
  return { type: AppStateActions.GET_LOCATION_ON_MAP_DISTANCE_ASYNC, data };
};
export const toggleIsPendingTransaction = (data: boolean) => {
  return { type: AppStateActions.TOGGLE_IS_PENDING_TRANSACTION, data };
};
export const setDisabledAddressFieldsAction = (data: string[]) => {
  return { type: AppStateActions.SET_DISABLED_ADDRESS_FIELDS, data };
};
