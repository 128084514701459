import { all } from 'redux-saga/effects';
import appStateSaga from './appState/AppStateSaga';
import storeSaga from './store/StoreSaga';
import productSaga from './product/ProductSaga';
import orderSaga from './orders/OrderSaga';
import categorySaga from './category/CategorySaga';
import discountSaga from './discount/DiscountSaga';
import authSaga from './auth/AuthSaga';
import cartSaga from './cart/CartSaga';
import merchantSaga from './merchant/MerchantSaga';
import commonSaga from './common/CommonSaga';
function* rootSaga() {
  yield all([
    storeSaga(),
    productSaga(),
    categorySaga(),
    discountSaga(),
    authSaga(),
    cartSaga(),
    appStateSaga(),
    orderSaga(),
    merchantSaga(),
    commonSaga(),
  ]);
}

export default rootSaga;
