import {
  toggleHasShippingFee,
  toggleHideLoadingModalWithText,
  toggleShowLoadingModalWithText,
} from './../appState/AppStateActions';
import { IUpdateManualOrderCustomerDetails } from './../../types/order';
import { put, takeEvery, all, select, call } from 'redux-saga/effects';
import { RootState } from '../reduxStore';
import { DataStorageKeys, PaymentStatusResponseDto, Store } from '../../types';
import { EPaymentStatus, IOrder } from '../../types/order';
import {
  requestGetOrderById,
  requestGetOrderPaymentStatus,
  requestGetOrders,
  requestUpdateManualCustomerShippingAddress,
} from './OrderRepository';
import { IOrderGeneralAction, OrderActions, storeOrderById, updateSummaryCartFromOrder } from './OrderActions';
import { logError } from 'utils/error';
import { requestGetCartPaymentStatus } from 'redux/cart/CartRepository';
import { trackPlaceOrder } from 'utils/analytics';
import { logout, setCheckoutAsGuestAction, setSelectedAddress } from 'redux/auth/AuthActions';
import { convertCustomerDetailToCheckout } from 'utils/order';
import {
  resetCartAction,
  setFinalTotalPriceOfCart,
  setIsSelfPickUp,
  setShippingFeeAction,
  updateCartAction,
} from 'redux/cart/CartAction';
import { updateCartProductAction } from 'redux/product/ProductActions';
import { convertProductsFromCart, removeDataStorageProducts } from 'utils/products';
import { map } from 'lodash';
import { DiscountType } from 'types/discount';
import { sGetStoreId } from 'redux/store/StoreSelectors';
import { sGetOrderId } from './OrderSelectors';
import { PaymentMethod } from 'types/merchant';
import { DataStorage } from 'utils/storage';
import { createPaymentFromOrder } from 'redux/merchant/MerchantAction';
import i18n from 'i18next';

export const getStore = (state: RootState) => state.store;

function* fetchOrdersAsync(action) {
  try {
    const store: Partial<Store> = yield select(getStore);
    const orders: IOrder[] = yield call(requestGetOrders, action?.data, store?.id);
    yield put({ type: OrderActions.FETCH_ALL_ORDERS, data: { orders, tab: action?.data } });
  } catch (e) {
    logError(e);
  }
}

function* watchFetchOrdersAsync() {
  yield takeEvery(OrderActions.FETCH_ALL_ORDERS_ASYNC, fetchOrdersAsync);
}

function* fetchOrdersByIdAsync(action) {
  try {
    const order: IOrder = yield call(requestGetOrderById, action.data);
    yield put(storeOrderById(order));
  } catch (error) {
    logError(error);
  }
}

function* watchFetchOrdersByIdAsync() {
  yield takeEvery(OrderActions.FETCH_ORDER_ASYNC, fetchOrdersByIdAsync);
}

// function for polling payment status, both cart and order
function* getPaymentStatusResponseAsync(action) {
  try {
    const { id, isCartPaymentStatus } = action.data;
    const res: PaymentStatusResponseDto = yield call(
      isCartPaymentStatus ? requestGetCartPaymentStatus : requestGetOrderPaymentStatus,
      id,
    );

    if (isCartPaymentStatus && res.paymentData.status === EPaymentStatus.PAID) {
      // Successful order, track event
      yield call(trackPlaceOrder, res.orderId);
    }

    yield put({ type: OrderActions.SET_PAYMENT_STATUS_RESPONSE, data: res });
  } catch (error) {
    logError(error);
  }
}

function* watchGetPaymentStatusResponseAsync() {
  yield takeEvery(OrderActions.GET_PAYMENT_STATUS_RESPONSE_ASYNC, getPaymentStatusResponseAsync);
}
function* fetchManualOrderByIdAsync(action) {
  try {
    const order: IOrder = yield call(requestGetOrderById, action.data);
    yield put(logout());
    yield put(resetCartAction());
    removeDataStorageProducts();

    yield put(storeOrderById(order));
    yield put(setCheckoutAsGuestAction(true));
    yield call(updateStateCustomerDetailFromManualOrder, order);
    yield put(updateCartProductAction(convertProductsFromCart(map(order.items, o => ({ ...o, price: o.finalPrice })))));
    yield call(updateStateDiscountFromManualOrder, order);
  } catch (error) {
    logError(error);
  }
}

function* watchFetchManualOrderByIdAsync() {
  yield takeEvery(OrderActions.FETCH_MANUAL_ORDER_ASYNC, fetchManualOrderByIdAsync);
}
function* updateManualOrderCustomerShippingAsync(action: IOrderGeneralAction<IUpdateManualOrderCustomerDetails>) {
  try {
    const storeId = yield select(sGetStoreId);
    const orderId = yield select(sGetOrderId);
    const order: IOrder = yield call(requestUpdateManualCustomerShippingAddress, storeId, orderId, action.data);
    yield put(storeOrderById(order));
    yield call(updateStateCustomerDetailFromManualOrder, order);
    yield call(updateStateDiscountFromManualOrder, order);
  } catch (error) {
    logError(error);
  }
}

function* watchUpdateManualOrderCustomerShippingAsync() {
  yield takeEvery(OrderActions.UPDATE_MANUAL_ORDER_CUSTOMER_SHIPPING_ASYNC, updateManualOrderCustomerShippingAsync);
}
function* updateStateCustomerDetailFromManualOrder(order: Partial<IOrder>) {
  const { customerDetails } = order;
  yield put(setSelectedAddress(convertCustomerDetailToCheckout(customerDetails)));
  yield put(setIsSelfPickUp(customerDetails?.isSelfPickedUp || false));
  yield put(setFinalTotalPriceOfCart(order.total));
  yield put(setShippingFeeAction(order.shippingFee || order?.deliveryProfile?.amount));
  yield put(toggleHasShippingFee(true));
}
function* updateStateDiscountFromManualOrder(order: Partial<IOrder>) {
  const { customDiscount } = order;
  let discountRes = [];
  if (customDiscount) {
    discountRes = [
      {
        code: customDiscount?.name,
        type: DiscountType.MANUAL,
        ruleType: customDiscount?.rule,
        discountedAmount: customDiscount?.amount,
      },
    ];
  }
  yield put(
    updateCartAction({
      discountRes,
      subTotal: order?.subTotal,
      manualDiscountAmount: customDiscount?.amount || undefined,
      finalTotalPrice: order.total,
      totalPrice: order.total,
    }),
  );
}
function* watchUpdateSummaryCartFromOrder() {
  yield takeEvery(OrderActions.UPDATE_SUMMARY_CART_FROM_ORDER_ACTION, (action: IOrderGeneralAction<IOrder>) =>
    updateStateDiscountFromManualOrder(action.data),
  );
}
function* completeOrderPaymentFlowAsync(action: IOrderGeneralAction<IOrder>) {
  try {
    yield put(toggleShowLoadingModalWithText(i18n.t('Please wait...\nYour payment is being processed.')));
    const order = action.data;
    if (order.paymentData.transactionData?.paymentMethod === PaymentMethod.mobile_banking) {
      DataStorage.save({ key: DataStorageKeys.orderId, data: order.id });
    }
    yield put(setShippingFeeAction(order.shippingFee || 0));
    yield put(updateSummaryCartFromOrder(order));
    yield put(storeOrderById(order));
    yield put(
      createPaymentFromOrder({
        paymentInformation: {
          ...order.paymentData.transactionData,
          paymentMethod: order.paymentData.transactionData?.paymentMethod,
        },
      }),
    );
  } catch (error) {
    logError(error);
  } finally {
    yield put(toggleHideLoadingModalWithText());
  }
}
function* watchCompleteOrderPaymentFlowAsync() {
  yield takeEvery(OrderActions.COMPLETE_ORDER_PAYMENT_FLOW_ASYNC_ACTION, completeOrderPaymentFlowAsync);
}

function* orderSaga() {
  yield all([
    watchFetchOrdersAsync(),
    watchFetchOrdersByIdAsync(),
    watchGetPaymentStatusResponseAsync(),
    watchFetchManualOrderByIdAsync(),
    watchUpdateManualOrderCustomerShippingAsync(),
    watchUpdateSummaryCartFromOrder(),
    watchCompleteOrderPaymentFlowAsync(),
  ]);
}

export default orderSaga;
