// import { API_STORE } from '../../@env';
import { ActionResponse, UploadPhotoResponse } from '../../types';
import { Discount, DiscountFilterInputParams, DiscountType } from '../../types/discount';

import axios from 'axios';
const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

export const requestGetDiscounts = async (storeId: string) => {
  return axios.get<Discount[]>(`${API_STORE}/api/store/buyer/store/${storeId}/discounts`).then(res => res.data);
};

export const requestSearchDiscounts = async (storeId: string, type: DiscountType, searchText: string) => {
  const filterParams: DiscountFilterInputParams = {};
  if (searchText) {
    filterParams.searchText = searchText;
  }

  if (type) {
    filterParams.type = type;
  }

  return axios
    .get<Discount[]>(`${API_STORE}/api/store/buyer/store/${storeId}/discounts`, { params: filterParams })
    .then(res => res.data);
};

export const requestDeleteDiscount = async (storeId: string, discountId: string) => {
  return axios
    .delete<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/discounts/${discountId}`)
    .then(res => res.data);
};

export const requestUpdateDiscount = async (
  storeId: string,
  discountId: string,
  updatedDiscount: Partial<Discount>,
) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/discounts/${discountId}`, updatedDiscount)
    .then(res => res.data);
};

export const requestUploadDiscountImage = async (formData: FormData, storeId: string) => {
  return axios
    .post<UploadPhotoResponse>(`${API_STORE}/api/store/store/${storeId}/discount/photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const requestCreateDiscount = async (data: Discount) => {
  return axios.post<Discount>(`${API_STORE}/api/store/discounts`, data).then(res => res.data);
};

export const requestReorderDiscount = async (storeId: string, updatedDiscounts: Partial<Discount>[]) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/discount/reorder`, updatedDiscounts)
    .then(res => res.data);
};
