import orderBy from 'lodash/orderBy';
import padStart from 'lodash/padStart';
import _ from 'lodash';
import { EStatusOrder, IItemOrder, IOrder } from 'types/order';
import dayjs from 'dayjs';

export const getDefaultOrderImage = (isFnB?: boolean) => {
  return `/images/${isFnB ? 'CategoryImageDefaultFnb' : 'CategoryImageDefault'}.png`;
};

export const parseOrderNumber = (orderNumber?: number) => {
  return padStart(orderNumber?.toString(), 3, '0');
};

export const getOrderImage = (items: IItemOrder[]) => {
  if (items.length > 0) {
    const orderItems = orderBy(items, ['unitPrice'], ['desc']);
    const itemWithHighestPrice = orderItems[0];
    if (itemWithHighestPrice.productPhotoUrls.length) {
      return itemWithHighestPrice.productPhotoUrls[0];
    }
  }
  return null;
};

export interface IOrderListItem {
  date: string;
  data: IOrder[];
  dateOriginal: string;
}

export const groupOrders = (orders: IOrder[], statusFilter?: EStatusOrder): IOrderListItem[] => {
  return _(orders)
    .filter(order => (statusFilter ? order.status === statusFilter : true))
    .groupBy(order => dayjs(order.createdAt).format('DD MMM YYYY'))
    .toPairs()
    .map(order => ({
      date: order[0],
      data: orderBy(order[1], ['createdAt'], ['desc']),
      dateOriginal: dayjs(order[0]).format('YYYY-MM-DD'),
    }))
    .orderBy(['dateOriginal'], ['desc'])
    .value();
};
