import { FreeShipType } from './../../types/index';
import { fetchStoreInfoBySlugActionAsync } from './../store/StoreActions';
import { setFinalTotalPriceOfCart, setIsSelfPickUp, setShippingFeeAction } from 'redux/cart/CartAction';
import { ICheckoutPayload } from './../../types/merchant';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { AppStateActions, toggleHasShippingFee, toggleIsLoading } from 'redux/appState/AppStateActions';
import { DiscountActions } from 'redux/discount/DiscountActions';
import { ProductActions } from 'redux/product/ProductActions';
import { requestGetProducts } from 'redux/product/ProductRepository';
import { RootState } from 'redux/reduxStore';
import {
  ExpressedPreferenceEnum,
  IFetchCartDetailByIdParams,
  IProductCart,
  ISelectedProductGetFromCart,
  IUpdateDeliveryOptionParams,
  Product,
} from 'types';
import { convertProductsFromCart, removeDataStorageProducts } from 'utils/products';
import { DataStorage } from 'utils/storage';
import { CartAction, CartGeneralAction, updateCartAction } from './CartAction';
import {
  requestCartCheckout,
  requestGetCart,
  requestGetShippingFee,
  requestPutCart,
  requestApplyDiscount,
  requestRemoveDiscount,
  requestGetProductAfterLogin,
  putRefreshCartDetail,
  requestPutDeliveryOption,
} from './CartRepository';
import i18n from 'i18next';
import { trackOrderFlowOrderPlaced, trackOrderPlacedEnableNotification } from 'utils/analytics';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { contentOfTrackingOrderFlow } from 'utils/convert';
import { getModalDataFromError, logError } from 'utils/error';
import { fetchOrderById } from 'redux/orders/OrderActions';
import { sGetDefaultAddress, sGetSelectedAddress } from 'redux/auth/AuthSelectors';
import produce from 'immer';
import router from 'next/router';

// eslint-disable-next-line no-promise-executor-return
const delay = time => new Promise(resolve => setTimeout(resolve, time));
const getStoreId = (state: RootState) => state.store.id;
const getCartId = (state: RootState) => state.cart.id;

function* buyerCreateOrUpdateCart(action: CartGeneralAction<IProductCart>) {
  const storeId = yield select(getStoreId);
  try {
    const newCart = yield call(requestPutCart, storeId, action.data);
    yield put({
      type: CartAction.UPDATE_CART,
      data: newCart,
    });
    yield call(updateProductInCart, newCart.items);
    yield call(delay, 3000);
  } catch (e) {
    logError(e);
    yield put({
      type: AppStateActions.SHOW_MODAL_WITH_BUTTON,
      data: {
        headerTxt: i18n.t(`Sorry, it seems there was an error.\nPlease try again`),
        acceptTxt: i18n.t('Try Again'),
      },
    });
  }
}

function* watchUpdateAsyncAction() {
  yield takeEvery(CartAction.UPDATE_CART_ASYNC, buyerCreateOrUpdateCart);
}

function* getAllProductsInCart() {
  const cartId = yield select(getCartId);
  try {
    const res = yield call(requestGetCart, cartId);
    if (res)
      yield put({
        type: CartAction.GET_ALL_PRODUCTS_IN_CART,
        data: res,
      });
  } catch (error) {
    logError(error);
  }
}

function* watchGetAllProductsInCartAsyncAction() {
  yield takeEvery(CartAction.GET_ALL_PRODUCTS_IN_CART_ASYNC, getAllProductsInCart);
}

function* getCartCheckout(action: CartGeneralAction<ICheckoutPayload>) {
  const tempCartId = DataStorage.load({ key: 'cartId' });
  let cartId;
  if (isUndefined(tempCartId)) cartId = yield select(getCartId);
  else cartId = tempCartId;
  try {
    yield put(toggleIsLoading(true));

    const selectedAddress = yield select(sGetSelectedAddress);
    const defaultAddress = yield select(sGetDefaultAddress);
    const newData = produce(action.data, draft => {
      if (draft.shippingInformation.shippingAddress) {
        draft.shippingInformation.shippingAddress.buyerAddrId = selectedAddress?.id || defaultAddress?.id;
      }
    });
    const res = yield call(requestCartCheckout, cartId, newData);
    if (res) {
      const orderId = res.id;
      DataStorage.save({ key: 'currentOrderId', data: orderId });

      yield put({
        type: CartAction.SET_IS_CHECKOUT_SUCCESS,
        data: true,
      });

      yield put(fetchOrderById(orderId));

      const storeName = yield select((state: RootState) => state.store.businessName);
      const shippingFee = yield select((state: RootState) => state.cart.shippingFee);

      trackOrderFlowOrderPlaced(contentOfTrackingOrderFlow(res, storeName, shippingFee));

      const enableNotification = res.customerDetails.allowLineOrderNotification;
      trackOrderPlacedEnableNotification({
        enable_notification: enableNotification ? 'Yes' : 'No',
        notification_channel: 'line',
      });
    }
    DataStorage.remove({ key: 'discountCode' });
    removeDataStorageProducts();
  } catch (error) {
    logError(error);
    yield put({
      type: AppStateActions.SHOW_MODAL_WITH_BUTTON,
      data: getModalDataFromError(error.response?.data),
    });
  } finally {
    yield put(toggleIsLoading(false));
  }
}

function* watchGetCartCheckout() {
  yield takeEvery(CartAction.CART_CHECKOUT_ASYNC, getCartCheckout);
}

function* getShippingFee(action) {
  const tempCartId = DataStorage.load({ key: 'cartId' });
  let cartId;
  if (isUndefined(tempCartId)) cartId = yield select(getCartId);
  else cartId = tempCartId;
  try {
    const res = yield call(requestGetShippingFee, cartId, action.data);
    if (res) {
      yield put(setShippingFeeAction(res.shippingFee));
      yield put({
        type: CartAction.GET_FINAL_TOTAL_PRICE,
        data: res.totalPrice,
      });
      yield call(getSpecificCart);
      yield put({
        type: CartAction.SET_ERROR_POSTAL_CODE,
        data: '',
      });
      if (res?.freeShipType === FreeShipType.MANUAL) {
        yield put(updateCartAction({ manualDiscountAmount: res?.discountedAmount }));
      }
      yield put(toggleHasShippingFee(true));
    }
  } catch (error) {
    logError(error);
    yield put({
      type: CartAction.SET_ERROR_POSTAL_CODE,
      data: error.response?.data?.message,
    });
    yield put(toggleHasShippingFee(false));
    yield put({
      type: CartAction.GET_FINAL_TOTAL_PRICE,
      data: 0,
    });
  }
}
function* watchGetShippingFee() {
  yield takeEvery(CartAction.GET_SHIPPING_FEE_ASYNC, getShippingFee);
}

function* applyDiscount(action) {
  const cartId = yield select(getCartId);
  try {
    const res = yield call(requestApplyDiscount, cartId, action.data);
    yield put({
      type: CartAction.UPDATE_CART,
      data: res,
    });
    yield put({
      type: DiscountActions.SET_DISCOUNT_ERROR_MESSAGE,
      data: '',
    });
    if (!isEmpty(res?.items)) {
      yield call(updateProductInCart, res.items);
    }
  } catch (error) {
    logError(error);
    yield put({
      type: DiscountActions.SET_DISCOUNT_ERROR_MESSAGE,
      data: error.response?.data?.message,
    });
  }
}

function* watchApplyDiscount() {
  yield takeEvery(CartAction.APPLY_DISCOUNT_ASYNC, applyDiscount);
}

function* removeDiscount(action) {
  const cartId = yield select(getCartId);
  try {
    const res = yield call(requestRemoveDiscount, cartId, action.data);
    if (res) {
      yield put({
        type: CartAction.UPDATE_CART,
        data: res,
      });
      if (!isEmpty(res?.items)) {
        yield call(updateProductInCart, res.items);
      }
    }
  } catch (error) {
    logError(error);
  }
}

function* watchRemoveDiscount() {
  yield takeEvery(CartAction.REMOVE_DISCOUNT_ASYNC, removeDiscount);
}

function* getBuyerLatestCartId() {
  try {
    const storeId = yield select(getStoreId);
    const manualOrderId = router.query.manualOrderId;
    if (storeId && isEmpty(manualOrderId)) {
      const res = yield call(requestGetProductAfterLogin, storeId);
      removeDataStorageProducts();
      if (res.storeId === storeId) {
        yield put({
          type: CartAction.UPDATE_CART,
          data: res,
        });
        if (res?.deliveryOption) {
          yield put(setIsSelfPickUp(res?.deliveryOption === ExpressedPreferenceEnum.SELF_PICKUP));
        }
        if (!isEmpty(res.appliedDiscountIds)) {
          yield put({
            type: DiscountActions.SET_DISCOUNT_FROM_CARTID,
            data: res.appliedDiscountIds,
          });
        }
        if (!isEmpty(res?.items)) {
          yield call(updateProductInCart, res.items);
        }
      } else {
        yield put({
          type: ProductActions.UPDATE_CART_PRODUCTS,
          data: [],
        });
      }
    }
  } catch (error) {
    logError(error);
  }
}

function* watchGetBuyerLatestCartId() {
  yield takeEvery(CartAction.GET_CART_FROM_LOGIN, getBuyerLatestCartId);
}

function* updateProductInCart(items: ISelectedProductGetFromCart[]) {
  try {
    const storeId = yield select(getStoreId);
    const allProducts = yield call(requestGetProducts, storeId);
    const newProducts = convertProductsFromCart(items as ISelectedProductGetFromCart[], allProducts as Product[]);
    yield put({
      type: ProductActions.UPDATE_CART_PRODUCTS,
      data: newProducts,
    });
  } catch (error) {
    logError(error);
  }
}

function* getSpecificCart() {
  const tempCartId = DataStorage.load({ key: 'cartId' });
  let cartId;
  if (isUndefined(tempCartId)) cartId = yield select(getCartId);
  else cartId = tempCartId;
  try {
    const res = yield call(requestGetCart, cartId);
    const storeId = yield select(getStoreId);
    if (res?.storeId === storeId) {
      yield put({
        type: CartAction.UPDATE_CART,
        data: res,
      });

      if (!isEmpty(res?.items)) {
        yield call(updateProductInCart, res.items);
      }
      yield put({
        type: DiscountActions.SET_DISCOUNT_FROM_CARTID,
        data: res.appliedDiscountIds,
      });
      if (res?.deliveryOption) {
        yield put(setIsSelfPickUp(res?.deliveryOption === ExpressedPreferenceEnum.SELF_PICKUP));
      }
    } else {
      DataStorage.remove({ key: 'indexProductToUpdateInCart' });
      removeDataStorageProducts();
      yield put({
        type: ProductActions.UPDATE_CART_PRODUCTS,
        data: [],
      });
    }
  } catch (error) {
    logError(error);
  }
}

function* watchGetSpecificCart() {
  yield takeEvery(CartAction.GET_CART_DETAIL, getSpecificCart);
}

function* refreshCartDetail() {
  try {
    const tempCartId = DataStorage.load({ key: 'cartId' });
    const cartIdFromRedux = yield select(getCartId);
    const cartId = cartIdFromRedux || tempCartId;
    if (cartId) {
      yield call(putRefreshCartDetail, cartId);
      yield call(getSpecificCart);
    }
  } catch (error) {
    logError(error);
  }
}

function* watchRefreshCartDetail() {
  yield takeEvery(CartAction.REFRESH_CART_DETAIL_ASYNC, refreshCartDetail);
}

function* getTotalPriceWhenSelfPickUp(action) {
  const tempCartId = DataStorage.load({ key: 'cartId' });
  let cartId;
  if (isUndefined(tempCartId)) cartId = yield select(getCartId);
  else cartId = tempCartId;
  try {
    const res = yield call(requestGetShippingFee, cartId, { isSelfPickedUp: action.data });
    if (res) {
      yield put({
        type: CartAction.GET_FINAL_TOTAL_PRICE,
        data: res.totalPrice,
      });
    }
  } catch (error) {
    logError(error);
  }
}

function* watchGetTotalPriceWhenSelfPickUp() {
  yield takeEvery(CartAction.GET_TOTAL_PRICE_SELF_PICKUP_ASYNC, getTotalPriceWhenSelfPickUp);
}
function* updateDeliveryOptionAsync(action: CartGeneralAction<IUpdateDeliveryOptionParams>) {
  try {
    const cartId = yield select(getCartId);
    const { isSelfPickedUp, finalTotalPrice } = action.data;
    yield call(requestPutDeliveryOption, cartId, action.data);
    yield put(setIsSelfPickUp(isSelfPickedUp));
    if (isNumber(finalTotalPrice)) {
      yield put(setFinalTotalPriceOfCart(finalTotalPrice));
    }
  } catch (error) {
    logError(error);
  }
}

function* watchUpdateDeliveryOptionAsync() {
  yield takeEvery(CartAction.UPDATE_CART_DELIVERY_OPTION_ASYNC, updateDeliveryOptionAsync);
}
function* fetchCartByIdAsync(action: CartGeneralAction<IFetchCartDetailByIdParams>) {
  try {
    yield put(fetchStoreInfoBySlugActionAsync(action.data.slug));
    yield put(updateCartAction({ id: action.data.cartId as '' }));
    yield call(getSpecificCart);
  } catch (error) {
    logError(error);
  }
}

function* watchFetchCartByIdAsync() {
  yield takeEvery(CartAction.FETCH_CART_BY_ID_ASYNC_ACTION, fetchCartByIdAsync);
}
function* cartSaga() {
  yield all([
    watchUpdateAsyncAction(),
    watchGetAllProductsInCartAsyncAction(),
    watchGetCartCheckout(),
    watchGetShippingFee(),
    watchApplyDiscount(),
    watchRemoveDiscount(),
    watchGetBuyerLatestCartId(),
    watchGetSpecificCart(),
    watchGetTotalPriceWhenSelfPickUp(),
    watchRefreshCartDetail(),
    watchUpdateDeliveryOptionAsync(),
    watchFetchCartByIdAsync(),
  ]);
}

export default cartSaga;
