import { IProductCart, ISelectedProductGetFromCart, Product, Store, Variants } from 'types';
import { Discount, DiscountRuleType, DiscountType } from 'types/discount';
import {
  trackViewContentScroll100,
  trackViewContentScroll25,
  trackViewContentScroll50,
  trackViewContentScroll75,
} from './analytics';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import map from 'lodash/map';
import some from 'lodash/some';
import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';
import xorBy from 'lodash/xorBy';
import findIndex from 'lodash/findIndex';
import nth from 'lodash/nth';
import intersection from 'lodash/intersection';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import isNaN from 'lodash/isNaN';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import forEach from 'lodash/forEach';
import { DataStorage } from './storage';
import produce from 'immer';
import { HLS_FILE_TYPE, isServer } from './constants';
import head from 'lodash/head';
import Hls from 'hls.js';
import { isHLSVideoSource } from './media';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isNumber from 'lodash/isNumber';

export const convertProductsToAdd = (cartProducts, productSetType = '') => {
  if (!isEmpty(cartProducts)) {
    let totalObj = [];
    totalObj = cartProducts.map((e: Product) => {
      const obj = {
        productId: e.id,
        selectedVariants:
          isEmpty(e.variantConfig) || (!isEmpty(e.variants) && isEmpty(e.variants[0]?.appliedVariants))
            ? []
            : e.variants,
        quantity: e.quantity,
        remarks: e?.remark,
        productSetType,
      };
      return obj;
    });
    return totalObj;
  } else return [];
};

export const convertProductsFromCart = (productFromCart: ISelectedProductGetFromCart[], allProducts?: Product[]) => {
  const finalProduct = [];
  productFromCart.forEach(element => {
    const originalProduct = find(allProducts, ['id', element.productId]);
    let obj = originalProduct;
    obj = {
      ...obj,
      id: element.productId,
      variants: element.selectedVariants,
      quantity: element.quantity,
      remark: element?.remarks,
      finalPrice: element?.price,
      unitPrice: element?.originalPrice,
      photoUrls: element?.productPhotoUrls || originalProduct?.photoUrls,
      name: element?.productName || originalProduct?.name,
    };
    finalProduct.push(obj);
  });
  return finalProduct;
};

export const transformProductQuantity = (originalProduct: Product, value: Product, maxQuantity: number) => {
  const newQuantityCanBe = value.quantity + originalProduct.quantity;
  return {
    ...value,
    quantity: newQuantityCanBe >= maxQuantity && maxQuantity !== 0 ? maxQuantity : newQuantityCanBe,
  };
};
export const isAlreadyExisted = (allCartProducts: Product[], newProductAdded: Product) => {
  const existedProductInCart = find(allCartProducts, { id: newProductAdded.id, remark: newProductAdded.remark });
  if (existedProductInCart && existedProductInCart.remark !== newProductAdded.remark) return false;
  else if (existedProductInCart) return true;
};
export const getIndexOfExistedProduct = (allCartProducts: Product[], value: Product) => {
  const indexSelected = keys(pickBy(allCartProducts, ['id', value?.id]));
  let checkArr = NaN;
  indexSelected.forEach(e => {
    const isDuplicate = checkDuplicateOfVariant(value, allCartProducts[parseInt(e, 10)]);
    if (isDuplicate) checkArr = parseInt(e, 10);
  });
  return checkArr;
};

export const checkDuplicateOfVariant = (value: Product, originalProduct: Product) => {
  const isFnB = value.type === 'F&B';
  if (!isFnB) {
    if (isEmpty(xorWith(originalProduct.variants[0]?.appliedVariants, value?.variants[0]?.appliedVariants, isEqual))) {
      return true;
    } else return false;
  } else {
    const variants = map(originalProduct.variants, 'appliedVariants[0]');
    const newVariants = map(value.variants, 'appliedVariants[0]');
    if (!isEmpty(xorBy(newVariants, variants, 'option'))) return false;
    else return true;
  }
};
export const isUpdateProduct = (allCartProducts: Product[], value: Product, maxQuantity: number) => {
  let isUpdated = false;
  let originalProduct: Product;
  let newProduct: Product = value;
  let index = NaN;
  if (isAlreadyExisted(allCartProducts, value)) {
    isUpdated = true;
    if (isEmpty(value.variantConfig)) {
      index = findIndex(allCartProducts, { id: value.id, remark: value?.remark });
      originalProduct = find(allCartProducts, { id: value.id, remark: value?.remark });
      newProduct = transformProductQuantity(originalProduct, value, maxQuantity);
    } else {
      index = getIndexOfExistedProduct(allCartProducts, value);
      if (!isNaN(index)) {
        originalProduct = nth(allCartProducts, index);
        newProduct = transformProductQuantity(originalProduct, value, maxQuantity);
      } else isUpdated = false;
    }
  } else isUpdated = false;
  return {
    isUpdated,
    newProduct,
    index,
  };
};

export const getAllNewProductsInCart = (allCartProducts: Product[], value: Product, index: number) => {
  if (nth(allCartProducts, index)) {
    return map(allCartProducts, (e: Product, i) => {
      if (i === index) return value;
      else return e;
    });
  } else return concat(allCartProducts, value);
};

export const getOriginalNonFnBProductVariants = (value: Product, newValue: Product) => {
  const err = [];
  let productSelected: Variants = {};
  value?.variantConfig?.forEach(e => {
    if (!some(newValue?.variants[0]?.appliedVariants, ['name', e?.name])) {
      err.push(e?.name);
    }
  });
  if (isEmpty(err)) {
    productSelected = find(value?.variants, (o: Variants) => {
      return isEmpty(xorWith(o.appliedVariants, newValue?.variants[0]?.appliedVariants, isEqual));
    });
  }
  return {
    err,
    productSelected,
  };
};
export const convertProductForm = (product: Product) => {
  const isNonVariant = isEmpty(product?.variantConfig) || isEmpty(product?.variants);
  const newProduct: Product = {
    id: product?.id,
    name: product?.name,
    unitPrice: product?.unitPrice,
    variants: !isNonVariant
      ? [
          {
            addedPrice: 0,
            appliedVariants: [],
            numberInStock: 0,
          },
        ]
      : [],
    variantConfig: !isNonVariant ? product?.variantConfig : [],
    type: product?.type,
    description: product?.description,
    numberInStock: product?.numberInStock,
    unitType: product?.unitType,
    unitDetail: product?.unitDetail,
    displayProductEnabled: product?.displayProductEnabled,
    deliveryProfileId: product?.deliveryProfileId,
    categoryIds: product?.categoryIds,
    photoUrls: product?.photoUrls,
    isInStock: product?.isInStock,
    media: product?.media,
  };
  return newProduct;
};

export const getErrorFNBVariants = (selectedProduct: Product, newVariants: Variants[]) => {
  const err = [];
  map(selectedProduct?.variantConfig, (e, index) => {
    if (filter(newVariants, ['appliedVariants[0].name', e?.name])?.length < e?.minChoice) {
      err.push(index);
    }
  });
  return { err };
};

export const getListOfProductDiscount = (e: Discount, selectedProduct: Product) => {
  return (
    e?.type === DiscountType.AUTOMATIC &&
    (e?.rule?.type === DiscountRuleType.BUY_X_GET_Y || e?.rule?.type === DiscountRuleType.FREE_SHIP) &&
    (e?.applicability?.allProductEnabled ||
      intersection(e?.applicability?.categoryIds, selectedProduct?.categoryIds)?.length !== 0 ||
      e?.applicability?.productIds?.includes(selectedProduct?.id as string))
  );
};
export const generateNewProduct = (
  selectedProduct: Product,
  variant: Variants[],
  obj: { quantity: number; productDetailPrice: number; remarkText?: string },
) => {
  const newObj: Product = {
    ...selectedProduct,
    variants: variant,
    quantity: obj.quantity,
    finalPrice: obj?.productDetailPrice || selectedProduct?.finalPrice,
    remark: obj?.remarkText,
  };
  return newObj;
};

export const checkIsSoldOut = (isFnB: boolean, product: Product) => {
  return (product?.numberInStock === 0 && !isFnB) || (!product?.isInStock && isFnB);
};

export const generateSellerToTrack = (store: Partial<Store>, selectedProduct: Product) => {
  return {
    seller_id: store.id,
    seller_name: store.businessName,
    product_id: selectedProduct?.id,
    product_name: selectedProduct?.name,
  };
};
export const setTrackingScroll = (scrollPercentage: number, scrollTracked) => {
  if (scrollPercentage === 1 && !scrollTracked.current[100]) {
    trackViewContentScroll100();
    scrollTracked.current[100] = true;
  } else if (scrollPercentage >= 0.75 && !scrollTracked.current[75]) {
    scrollTracked.current[75] = true;
    trackViewContentScroll75();
  } else if (scrollPercentage >= 0.5 && !scrollTracked.current[50]) {
    trackViewContentScroll50();
    scrollTracked.current[50] = true;
  } else if (scrollPercentage > 0.25 && !scrollTracked.current[25]) {
    trackViewContentScroll25();
    scrollTracked.current[25] = true;
  }
  return scrollTracked;
};

export const removeDataStorageProducts = () => {
  DataStorage.remove({ key: 'ProductsInCart' });
  DataStorage.remove({ key: 'cartId' });
  DataStorage.remove({ key: 'manualDiscountCode' });
};
export const convertPayloadToGetDryRunCheckout = (product: Product) => {
  const currentCart = {
    items: convertProductsToAdd([product]),
  };
  const newForm: IProductCart = produce(currentCart, draft => {
    draft['items'][0]['quantity'] = 1;
  });
  return newForm;
};

export const isHlsFile = (fileUrl: string) => {
  const fileExt = fileUrl?.split('.')?.pop();

  return HLS_FILE_TYPE.includes(fileExt);
};

export const isElementScrollIntoView = (element: HTMLVideoElement) => {
  if (isServer) return false;
  const rect = element.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  if (elemBottom - elemTop > window.innerHeight) {
    const excessHeight = elemBottom - elemTop - window.innerHeight;
    return elemTop >= -excessHeight && elemBottom <= window.innerHeight + excessHeight;
  }
  return elemTop >= 0 && elemBottom <= window.innerHeight;
};

export const handleHLSVideos = (elementList: HTMLVideoElement[]) => {
  forEach(elementList, (o: HTMLVideoElement) => {
    if (Hls.isSupported()) {
      if (isHLSVideoSource(o.src)) {
        const hls = new Hls();
        hls.attachMedia(o);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(o.getElementsByTagName('source')[0].src);
        });
      }
    }
  });
};

export const autoplayVideOnScrollEvent = (elementList: HTMLVideoElement[], playFirstWithoutScroll = false) => {
  if (isServer) return undefined;
  const firstVideo = head(elementList);
  if (
    playFirstWithoutScroll &&
    firstVideo &&
    isElementScrollIntoView(firstVideo) &&
    !(firstVideo?.currentTime > 0 && !firstVideo?.paused && !firstVideo?.ended)
  ) {
    firstVideo.muted = true;
    firstVideo.play();
  }
  window.addEventListener('scroll', () => {
    let anotherVideoIsPlaying = false;

    forEach(elementList, (o: HTMLVideoElement) => {
      if (isElementScrollIntoView(o) && !anotherVideoIsPlaying) {
        o.muted = true;
        o.play();
        anotherVideoIsPlaying = true;
      } else {
        o.pause();
      }
    });
  });
};

export const transformProductDescription = (source: string) => {
  return source
    .replace(/<img/g, '<img style="max-width:100%; min-width:100%; object-fit:cover" ')
    .replace(/<video/g, '<video playsinline ');
};
export enum EVariantPriceField {
  ADDED_PRICE = 'addedPrice',
  FINAL_PRICE = 'finalPrice',
}
export const getMinVariantPrice = (product: Product, priceField: EVariantPriceField) => {
  return minBy(product.variants, priceField);
};
const getMaxVariantPrice = (product: Product, priceField: EVariantPriceField) => {
  return maxBy(product.variants, priceField);
};
export const getProductVariantPriceMinMaxRange = (product: Product, variantPriceAfterDiscount = false) => {
  if (product?.variants?.length) {
    let priceField = EVariantPriceField.ADDED_PRICE;
    if (isNumber(product?.variants[0]?.finalPrice)) {
      priceField = EVariantPriceField.FINAL_PRICE;
    }
    const minVariantPrice = getMinVariantPrice(product, priceField)[priceField];
    const maxVariantPrice = getMaxVariantPrice(product, priceField)[priceField];

    return {
      addRange: [
        variantPriceAfterDiscount ? product.finalVariantPrice.min : minVariantPrice,
        variantPriceAfterDiscount ? product.finalVariantPrice.max : maxVariantPrice,
      ],
      isFinalVariantPrice: true,
    };
  } else {
    return { addRange: [0, 0], isFinalVariantPrice: false };
  }
};
export const shouldShowOldPrice = (product: Product, isFnB?: boolean) => {
  if (!isEmpty(product?.variants) && !isFnB) {
    const minVariantPrice = getMinVariantPrice(product, EVariantPriceField.FINAL_PRICE);
    return minVariantPrice?.finalPrice !== product?.finalPrice;
  } else {
    return product?.unitPrice !== product?.finalPrice;
  }
};
