import {
  ActionResponse,
  Category,
  DeliveryProfile,
  CategoryFilterInputParams,
  UploadPhotoResponse,
  EReadEntityBy,
} from 'types';

import axios from 'axios';
import { transformSlug } from 'utils/convert';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

export const requestGetCategory = async (storeId: string) => {
  return axios.get<Category[]>(`${API_STORE}/api/store/buyer/store/${storeId}/category`).then(res => res.data);
};

export const requestSearchCategory = async (storeId: string, filterParams: CategoryFilterInputParams) => {
  return axios
    .get<Category[]>(`${API_STORE}/api/store/store/${storeId}/category`, { params: filterParams })
    .then(res => res.data);
};

export const requestDeleteCategory = async (storeId: string, categoryId: string) => {
  return axios
    .delete<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/category/${categoryId}`)
    .then(res => res.data);
};

export const requestUpdateCategory = async (storeId: string, categoryId: string, updateCategory: Partial<Category>) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/category/${categoryId}`, updateCategory)
    .then(res => res.data);
};

export const requestGetDeliveryProfiles = async (storeId: string) => {
  return axios.get<DeliveryProfile[]>(`${API_STORE}/api/store/store/${storeId}/delivery-profile`).then(res => res.data);
};

export const requestCreateDeliveryProfile = async (data: DeliveryProfile, storeId: string) => {
  return axios
    .post<DeliveryProfile>(`${API_STORE}/api/store/store/${storeId}/delivery-profile`, data)
    .then(res => res.data);
};

export const requestUploadCategoryImage = async (formData: FormData, storeId: string) => {
  return axios
    .post<UploadPhotoResponse>(`${API_STORE}/api/store/store/${storeId}/category/photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const requestCreateCategory = async (data: Category, storeId: string) => {
  return axios.post<Category>(`${API_STORE}/api/store/store/${storeId}/category`, data).then(res => res.data);
};

export const requestReorderCategory = async (storeId: string, updatedCategories: Partial<Category>[]) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/category/reorder`, updatedCategories)
    .then(res => res.data);
};

export const requestGetCategoriesByStoreSlug = async (storeSlug: string) => {
  return axios
    .get<Category[]>(`${API_STORE}/api/store/buyer/store/${transformSlug(storeSlug)}/category`, {
      params: {
        readStoreBy: EReadEntityBy.SLUG,
      },
    })
    .then(res => res.data);
};

export const requestGetCategoryBySlug = async (storeSlug: string, categorySlug: string) => {
  return axios
    .get<Category[]>(
      `${API_STORE}/api/store/buyer/store/${transformSlug(storeSlug)}/category/${transformSlug(categorySlug)}`,
      {
        params: {
          readStoreBy: EReadEntityBy.SLUG,
          readCategoryBy: EReadEntityBy.SLUG,
        },
      },
    )
    .then(res => res.data);
};
