import { select } from 'redux-saga/effects';
import { sGetBuyer } from 'redux/auth/AuthSelectors';
import {
  sGetBaseShippingFee,
  sGetCart,
  sGetFinalCartPrice,
  sGetPaymentMethodInfo,
  sGetTotalShippingFee,
} from 'redux/cart/CartSelectors';
import { sGetProductsInCart } from 'redux/product/ProductSelectors';
import { sGetStore } from 'redux/store/StoreSelectors';
import { ExpressedPreferenceEnum } from 'types';
import { EEvent, ITrackAddress, ITrackErrorPopUp, ITrackPayment, ITrackPlaceOrder } from 'types/analytics';
import { trackEvent } from '..';

export function trackPaymentsEnabledCheckoutShippingContinue() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Shipping_Continue);
}

export function trackPaymentsEnabledCheckoutPaymentsContinue(options: ITrackPayment) {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Payments_Continue, options);
}

export function trackPaymentsEnabledCheckoutConfirmPlaceOrder(options: ITrackPlaceOrder) {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Confirm_PlaceOrder, options);
}

export function trackPaymentsEnabledCheckoutProceedToCheckout() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_ProceedToCheckout);
}

export function trackPaymentsEnabledCheckoutCourierDelivery() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_CourierDelivery);
}

export function trackPaymentsEnabledCheckoutSelfPickup() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_SelfPickup);
}

export function trackPaymentsEnabledCheckoutAddNewProfile() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_AddNewProfile);
}

export function trackPaymentsEnabledCheckoutAddNewAddress() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_AddNewAddress);
}

export function trackPaymentsEnabledCheckoutFindAddress() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_FindAddress);
}

export function trackPaymentsEnabledCheckoutCancelFindAddress() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_CancelFindAddress);
}

export function trackPaymentsEnabledCheckoutConfirmFindAddress(options: ITrackAddress) {
  trackEvent(EEvent.PaymentsEnabled_Checkout_ConfirmFindAddress, options);
}

export function trackPaymentsEnabledCheckoutFailGotIt(options: ITrackErrorPopUp) {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Fail_Gotit, options);
}

export function trackPaymentsEnabledCheckoutFailTryAgain(options: ITrackErrorPopUp) {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Fail_TryAgain, options);
}

export function trackPaymentsEnabledCheckoutSuccessReturnToHomepage() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Success_ReturnToHomepage);
}

export function trackPaymentsEnabledCheckoutSuccessViewOrderDetail() {
  trackEvent(EEvent.PaymentsEnabled_Checkout_Success_ViewOrderDetail);
}

// Create a generator function to make execution cleaner
export function* trackPlaceOrder(orderId: string) {
  const store = yield select(sGetStore);
  const cart = yield select(sGetCart);
  const products = yield select(sGetProductsInCart);
  const buyer = yield select(sGetBuyer);
  const paymentInfo = yield select(sGetPaymentMethodInfo);
  const total = yield select(sGetFinalCartPrice);
  const totalShippingFee = yield select(sGetTotalShippingFee);
  const baseShippingFee = yield select(sGetBaseShippingFee);

  trackPaymentsEnabledCheckoutConfirmPlaceOrder({
    seller_id: store.id,
    seller_name: store.businessName,
    buyer_id: buyer.userId,
    order_id: orderId,
    cart_id: cart.id,
    order_subtotal_value: cart.subTotal,
    shipping_fees_value: totalShippingFee,
    free_shipping_discount_value: Math.abs(totalShippingFee - baseShippingFee) * -1,
    discount_value: Math.abs(cart.totalDiscountAmount) * -1,
    order_value: total,
    products: products.map(p => ({ product_id: p.id, product_price: p.unitPrice, quantity: p.quantity })),
    payment_method: paymentInfo.paymentMethod,
    bank_name: paymentInfo.paymentInfo?.bankName,
    delivery_option: cart.isSelfPickUp ? ExpressedPreferenceEnum.SELF_PICKUP : ExpressedPreferenceEnum.COURIER_DELIVERY,
  });
}
