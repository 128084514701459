/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
import produce from 'immer';
import { AppState, ConfirmModalTypeEnums } from '../../types';
import { AppStateActions, IAppStateGeneralAction } from './AppStateActions';

const DEFAULT_MODAL_WITH_BUTTON = {
  isOpen: false,
  headerTxt: '',
  subTitleTxt: '',
  styleHeader: {},
  styleSubTitle: {},
  isCanCancel: false,
  cancelTxt: '',
  acceptTxt: '',
};
const DEFAULT_PAYMENT_IMAGE_MODAL = {
  isOpen: false,
  paymentMethod: '',
  styleHeader: {},
  buttonText: '',
  imageSource: '',
};
export const DEFAULT_APP_STATE: AppState = {
  titleForm: '',
  showProductBottomSheet: false,
  isDraggingCategory: false,
  isDraggingProduct: false,
  errorModalData: {
    title: '',
    subtitle: '',
    dismissButtonTitle: '',
    display: false,
  },
  confirmModalData: {
    type: ConfirmModalTypeEnums.NONE,
    title: '',
    subtitle: '',
    confirmButtonTitle: '',
    cancelButtonTitle: '',
    display: false,
    state: null,
    metadata: null,
  },
  successModalData: {
    title: '',
    display: false,
  },
  stickyBar: {
    showStickyBar: false,
    textStickyBar: {
      message: '',
    },
  },
  postCode: {
    postCodeTxt: '',
    postCodeData: {
      text: '',
      value: 0,
    },
    isError: false,
    isHidePostCode: false,
    bannerHeight: 0,
  },
  showDialogLogin: false,
  modalWithButton: { ...DEFAULT_MODAL_WITH_BUTTON },
  paymentImageModal: { ...DEFAULT_PAYMENT_IMAGE_MODAL },
  isShowModelSuccess: false,
  locale: {
    code: '',
    country: '',
  },
  hasShippingFee: false,
  isHomeLoading: false,
  isCategoryPageLoading: true,
  isProductDetailPageLoading: false,
  isLoading: false,
  isProductsLoading: false,
  isCategoriesLoading: false,
  isSearchProductsLoading: false,
  screenHeaderStyle: 'STEPS',
  orderCheckoutStep: 1,
  paymentMethods: [],
  trueMoneyPayment: false,
  showPaymentModal: false,
  modalLoadingWithText: {
    isOpen: false,
    text: '',
  },
  isBuyerFillAllAddress: false,
  isPollingCartPaymentStatus: false,
  locationToastState: {
    isOpen: false,
  },
  isLocationOnMapOutOfRange: false,
  isPendingTransaction: false,
  canLoadMoreStores: true,
  disabledAddressFields: [],
};
/* eslint-disable max-lines, max-lines-per-function */
const AppStateReducer = (state = DEFAULT_APP_STATE, action: IAppStateGeneralAction<any>): AppState => {
  switch (action.type) {
    case AppStateActions.SET_PRODUCT_BOTTOM_SHEET_SHOWING:
      return {
        ...state,
        showProductBottomSheet: action.data,
      };
    case AppStateActions.TOGGLE_APP_STATE_FLAG:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case AppStateActions.UPDATE_ERROR_MODAL_DATA:
      return {
        ...state,
        errorModalData: {
          ...state.errorModalData,
          ...action.data,
        },
      };
    case AppStateActions.UPDATE_CONFIRM_MODAL_DATA:
      return {
        ...state,
        confirmModalData: {
          ...state.confirmModalData,
          ...action.data,
        },
      };

    case AppStateActions.UPDATE_SUCCESS_MODAL_DATA:
      return {
        ...state,
        successModalData: {
          ...state.successModalData,
          ...action.data,
        },
      };
    case AppStateActions.UPDATE_STICKY_BAR_DATA:
      return {
        ...state,
        stickyBar: {
          ...state.stickyBar,
          ...action.data,
        },
      };
    case AppStateActions.UPDATE_POSTCODE_DATA:
      return {
        ...state,
        postCode: {
          ...state.postCode,
          postCodeData: {
            ...action.data,
          },
        },
      };
    case AppStateActions.UPDATE_POSTCODE_TXT:
      return {
        ...state,
        postCode: {
          ...state.postCode,
          postCodeTxt: action.data,
          isError: false,
        },
      };
    case AppStateActions.TOGGLE_POSTCODE_ERROR:
      return {
        ...state,
        postCode: {
          ...state.postCode,
          isError: action.data,
        },
      };
    case AppStateActions.TOGGLE_HIDE_POSTCODE:
      return {
        ...state,
        postCode: {
          ...state.postCode,
          isHidePostCode: action.data,
          bannerHeight: 0,
        },
      };
    case AppStateActions.TOGGLE_DIALOG_LOGIN:
      return {
        ...state,
        showDialogLogin: action.data,
      };
    case AppStateActions.SHOW_MODAL_WITH_BUTTON:
      return {
        ...state,
        modalWithButton: {
          ...DEFAULT_MODAL_WITH_BUTTON,
          isOpen: true,
          ...action.data,
        },
      };
    case AppStateActions.HIDE_MODAL_WITH_BUTTON:
      return {
        ...state,
        modalWithButton: {
          ...state.modalWithButton,
          isOpen: false,
        },
      };

    case AppStateActions.SHOW_PAYMENT_IMAGE_MODAL:
      return {
        ...state,
        paymentImageModal: {
          ...DEFAULT_PAYMENT_IMAGE_MODAL,
          isOpen: true,
          ...action.data,
        },
      };
    case AppStateActions.HIDE_PAYMENT_IMAGE_MODAL:
      return {
        ...state,
        paymentImageModal: {
          ...state.paymentImageModal,
          isOpen: false,
        },
      };

    case AppStateActions.TOGGLE_MODEL_SUCCESS:
      return {
        ...state,
        isShowModelSuccess: action.data,
      };

    case AppStateActions.SET_TITlE_FORM:
      return {
        ...state,
        titleForm: action.data,
      };
    case AppStateActions.SET_LOCALE:
      return {
        ...state,
        locale: action.data,
      };
    case AppStateActions.TOGGLE_HAS_SHIPPING_FEE:
      return { ...state, hasShippingFee: action.data };
    case AppStateActions.SCREEN_HEADER_STYLE:
      return {
        ...state,
        screenHeaderStyle: action.data,
      };
    case AppStateActions.ORDER_CHECKOUT_STEP:
      return {
        ...state,
        orderCheckoutStep: action.data,
      };
    case AppStateActions.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.data,
      };
    case AppStateActions.TOGGLE_TRUE_MONEY_PAYMENT:
      return {
        ...state,
        trueMoneyPayment: action.data,
      };
    case AppStateActions.TOGGLE_SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.data,
      };
    case AppStateActions.TOGGLE_IS_LOADING: {
      return {
        ...state,
        isLoading: action.data,
      };
    }
    case AppStateActions.TOGGLE_SHOW_LOADING_MODAL_WITH_TEXT:
      return {
        ...state,
        modalLoadingWithText: {
          isOpen: true,
          text: action.data,
        },
      };
    case AppStateActions.TOGGLE_HIDE_LOADING_MODAL_WITH_TEXT:
      return {
        ...state,
        modalLoadingWithText: {
          isOpen: false,
          text: '',
        },
      };
    case AppStateActions.SET_IS_BUYER_FILL_ADDRESS:
      return {
        ...state,
        isBuyerFillAllAddress: action.data,
      };
    case AppStateActions.SET_IS_POLLING_CART_PAYMENT_STATUS:
      return {
        ...state,
        isPollingCartPaymentStatus: action.data,
      };
    case AppStateActions.SET_STICKY_POSTCODE_BANNER_HEIGHT:
      return produce(state, draft => {
        draft.postCode.bannerHeight = action.data;
      });
    case AppStateActions.SET_LOCATION_TOAST_STATE:
      return {
        ...state,
        locationToastState: action.data,
      };
    case AppStateActions.TOGGLE_IS_LOCATION_ON_MAP_OUT_OF_RANGE:
      return {
        ...state,
        isLocationOnMapOutOfRange: action.data,
      };
    case AppStateActions.TOGGLE_IS_PENDING_TRANSACTION:
      return {
        ...state,
        isPendingTransaction: action.data,
      };
    case AppStateActions.SET_DISABLED_ADDRESS_FIELDS:
      return { ...state, disabledAddressFields: action.data };
    default:
      return state;
  }
};

export default AppStateReducer;
