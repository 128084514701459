import { EEvent, ITrackHamburgerCategories, ITrackSellerInformation } from 'types/analytics';
import { trackEvent } from '..';

export function trackHamburger() {
  trackEvent(EEvent.Hamburger);
}

export function trackHamburgerHome(options: ITrackSellerInformation) {
  trackEvent(EEvent.Hamburger_Home, options);
}

export function trackHamburgerCategories(options: ITrackHamburgerCategories) {
  trackEvent(EEvent.Hamburger_Categories, options);
}

export function trackHamburgerStoreInformation() {
  trackEvent(EEvent.Hamburger_StoreInformation);
}

export function trackHamburgerLoginRegister() {
  trackEvent(EEvent.Hamburger_LoginRegister);
}
