/* eslint-disable no-console */

import { BuyerInfo } from 'types/auth';

let userEmail = '';

export const mailchimpEventHandler = async eventName => {
  await fetch('/api/mailchimpEvent', {
    method: 'POST',
    body: JSON.stringify({ email: userEmail, eventName: eventName }),
  })
    .then(res => {
      console.log(res);
    })
    .catch();
};

export const updateListMemberInfo = async (email, info) => {
  await fetch('/api/mailchimp', {
    method: 'POST',
    body: JSON.stringify({ email: email, type: 'UPDATE', info: info }),
  }).then(res => {
    console.log(res);
  });
};

const checkForValueChangeHandler = (info, buyer: BuyerInfo) => {
  if (
    (info && buyer && info?.email !== buyer?.email) ||
    info?.fullName !== buyer?.fullName ||
    info?.phoneNumber !== buyer?.phoneNumber ||
    info?.shippingAddress?.detailedAddress !== buyer?.address?.detailedAddress ||
    info?.shippingAddress?.country !== buyer?.address?.country
  ) {
    return true;
  } else {
    return false;
  }
};

export const updateUserEmailHandler = async (email, info?, buyer?) => {
  if (email && email !== '') {
    userEmail = email;
    if (checkForValueChangeHandler(info, buyer)) {
      updateListMemberInfo(email, info);
    }
  }
};
