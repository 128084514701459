import concat from 'lodash/concat';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import template from 'lodash/template';
import replace from 'lodash/replace';
import uniqBy from 'lodash/uniqBy';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { BuyerFormInfo, ELocationToastType, PlaceSelectionDetail } from 'types';
import { AddressDto } from 'types/auth';
import { logError } from './error';
import countries from './countries';
import i18n from 'i18next';
import { generateFormToGetFullAddress } from './convert';

export const geocodeByLocation = location => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve: (value: google.maps.GeocoderResult[]) => void, reject) => {
    geocoder.geocode({ location }, (results: google.maps.GeocoderResult[], status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const getCurrentLocation = (fn: (e: PlaceSelectionDetail) => void, errorFn?: (e: boolean) => void) => {
  // prettier-ignore
  navigator.geolocation.getCurrentPosition( // NOSONAR
    position => {
      const { latitude, longitude } = position.coords;
      geocodeByLocation({ lat: latitude, lng: longitude })
        .then((results: google.maps.GeocoderResult[]) => {
          const addressInfo = head(results);
          fn({
            description: addressInfo?.formatted_address || '',
            lat: latitude,
            lng: longitude,
            addressComponents: addressInfo?.address_components,
          });
          if (errorFn) errorFn(true);
        })
        .catch(error => logError(error));
    },
    () => {
      if (errorFn) errorFn(false);
    },
  );
};

export const getGeocodeByAddress = (address: string, fn: (e: PlaceSelectionDetail) => void) => {
  geocodeByAddress(address)
    .then(results => {
      const firstResult = results[0];
      getLatLng(firstResult)
        .then(({ lat, lng }) => {
          fn({ description: address, addressComponents: firstResult.address_components, lat, lng });
        })
        .catch(error => logError(error));
    })
    .catch(error => logError(error));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAddressComponentTextByType = (addressComponents: any[], placeType: string, getShortName = false) => {
  const component = find(addressComponents, o => includes(get(o, ['types']), placeType));
  return getShortName ? component?.short_name || '' : component?.long_name || '';
};

export const getValueInArray = (description: string, index: number) => {
  const areaTexts = (description || '')?.split(',')?.reverse();
  return areaTexts[index]?.trim() || '';
};

export const getAddressFromPlaceDetail = (placeDetail?: {
  description: string;
  addressComponents?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  lat?: number;
  lng?: number;
}) => {
  if (placeDetail?.addressComponents) {
    const postal_code = getAddressComponentTextByType(placeDetail?.addressComponents, 'postal_code');
    const street_number = getAddressComponentTextByType(placeDetail?.addressComponents, 'street_number');
    const _route = getAddressComponentTextByType(placeDetail?.addressComponents, 'route');
    const countryCode = getAddressComponentTextByType(placeDetail?.addressComponents, 'country', true);
    const country = find(countries, { code: countryCode })?.name;
    const administrative_area_level_1 = getAddressComponentTextByType(
      placeDetail?.addressComponents,
      'administrative_area_level_1',
    );

    const sublocality_level_2 = getAddressComponentTextByType(placeDetail?.addressComponents, 'sublocality_level_2');
    const sublocality_level_1 = getAddressComponentTextByType(placeDetail?.addressComponents, 'sublocality_level_1');
    return {
      postalCode: postal_code,
      detailedAddress2: `${street_number ? `${street_number},` : ''} ${_route}`.trim(),
      country,
      province: administrative_area_level_1 || getValueInArray(placeDetail.description, 1) || '',
      district: sublocality_level_1 || getValueInArray(placeDetail.description, 2) || '',
      subDistrict: sublocality_level_2 || getValueInArray(placeDetail.description, 3) || '',
      region: '',
      barangay: '',
      lat: placeDetail?.lat?.toString(),
      lng: placeDetail?.lng?.toString(),
    };
  } else return {};
};

/*  eslint-disable @typescript-eslint/no-unused-vars */
export const isUnsupportedCountry = (
  sellerCountry: string,
  buyerCountry?: string,
  placeDetail?: { description: string; addressComponents?: any[] }, // eslint-disable-line @typescript-eslint/no-explicit-any
): boolean => {
  // uncomment in case this is used again

  // if (buyerCountry) return !isEqual(sellerCountry, buyerCountry);
  // const country = find(countries, {
  //   code: getAddressComponentTextByType(placeDetail?.addressComponents, 'country', true),
  // });
  // return !isEqual(sellerCountry, country?.name);
  return false;
};
/*  eslint-enable @typescript-eslint/no-unused-vars */

export const getTextAddressBold = (placeDetail: string, text: string) => {
  const stringTemplate = template('<b><%- value %></b>');
  return replace(text, placeDetail, stringTemplate({ value: placeDetail }));
};

export const getBuyerProfiles = (addresses: AddressDto[], isSelfPickUp = false) => {
  if (isSelfPickUp) {
    const allAddresses = concat(
      filter(addresses, o => o.isDefault),
      filter(addresses, o => !o.isDefault),
    );
    return uniqBy(allAddresses, o => o.fullName + o.phoneNumber);
  } else {
    return addresses;
  }
};
export const getLocationToastForm = (isOpen: boolean, type?: ELocationToastType, distance = 0) => {
  switch (type) {
    case ELocationToastType.SELECTED_GUIDELINE:
      return {
        isOpen,
        type,
        titleTxt: i18n.t('Search an address or find it on the map.'),
      };
    case ELocationToastType.ERROR_LOCATED:
      return {
        isOpen,
        type,
        titleTxt: i18n.t("We're having trouble locating you.\n Check your connection and location access."),
      };
    case ELocationToastType.ERROR_OUT_OF_RANGE:
      return {
        isOpen,
        type,
        titleTxt: i18n.t('Address out of range. Please select an address within {{x}} km of our location.', {
          x: distance,
        }),
      };
    default:
      break;
  }
};
export const getCalculateDistancePayload = (buyerInfo: AddressDto, slug: string) => {
  const fullAddress = generateFormToGetFullAddress(buyerInfo as Partial<BuyerFormInfo>);
  return {
    slug,
    fullAddress,
    country: buyerInfo?.country,
    lat: buyerInfo?.lat || undefined,
    lng: buyerInfo?.lng || undefined,
  };
};
export const fetchLocationAutocompleteResults = (input: google.maps.places.AutocompletionRequest) => {
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const OK = window.google.maps.places.PlacesServiceStatus.OK;

  return new Promise((resolve: (value) => void, reject) => {
    autocompleteService.getPlacePredictions(input, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};
export const getMapSizeByBounds = (
  projection: google.maps.Projection,
  bounds: google.maps.LatLngBounds,
  zoomLevel: number,
) => {
  const scale = Math.pow(2, zoomLevel);
  const swPoint = projection?.fromLatLngToPoint(bounds?.getSouthWest());
  const nePoint = projection?.fromLatLngToPoint(bounds?.getNorthEast());
  const size_x = Math.abs(nePoint?.x - swPoint?.x) * scale;
  const size_y = Math.abs(nePoint?.y - swPoint?.y) * scale;
  return { size_x, size_y };
};
