import { AddressDto, BuyerInfo, IAuthLoginOptionParams } from 'types/auth';

export enum IAuthAction {
  LOGIN = 'LOGIN',
  LOGIN_ASYNC = 'LOGIN_ASYNC', // Input access token -> user
  LOGOUT = 'LOGOUT',
  LOGOUT_ASYNC = 'LOGOUT_ASYNC',
  UPDATE_ASYNC = 'UPDATE_ASYNC',
  UPDATE = 'UPDATE',
  SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS',
  SET_CHECKOUT_AS_GUEST = 'SET_CHECKOUT_AS_GUEST',
}

export interface IAuthGeneralAction<T> {
  type: IAuthAction;
  data: T;
}
export const loginAsyncAction = (access_token: string, storeSlug?: string, options?: IAuthLoginOptionParams) => {
  return {
    type: IAuthAction.LOGIN_ASYNC,
    data: {
      access_token,
      storeSlug,
      options,
    },
  };
};

export const logoutAction = (slug?: string) => {
  return {
    type: IAuthAction.LOGOUT_ASYNC,
    data: {
      storeSlug: slug,
    },
  };
};

export const logout = () => {
  return {
    type: IAuthAction.LOGOUT,
  };
};

export const updateAsyncAction = (data: BuyerInfo) => {
  return {
    type: IAuthAction.UPDATE_ASYNC,
    data,
  };
};
export const setSelectedAddress = (data: AddressDto) => {
  return {
    type: IAuthAction.SET_SELECTED_ADDRESS,
    data,
  };
};
export const updateBuyerData = (data: BuyerInfo) => {
  return { type: IAuthAction.UPDATE, data };
};
export const setCheckoutAsGuestAction = (data: boolean) => {
  return { type: IAuthAction.SET_CHECKOUT_AS_GUEST, data };
};
