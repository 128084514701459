import { groupOrders } from 'components/Accounts/MyOrders/MyOrderUtils';
import { RootState } from 'redux/reduxStore';

export const sGetCurrentTab = (state: RootState) => state?.orders?.currentTab;

export const sGetOrder = (state: RootState) => state.orders.order;

export const sGetOrders = (state: RootState) => state.orders.orders;
export const sGetGroupOrders = (state: RootState) => groupOrders(state?.orders?.orders);
export const sGetPaymentStatusResponse = (state: RootState) => state.orders.paymentStatusResponse;

export const sGetOrderId = (state: RootState) => state?.orders?.order?.id;

export const sGetOrderBuyerDetails = (state: RootState) => state?.orders?.order.buyerDetails;
export const sGetOrderIsPhoneNumberRegistered = (state: RootState) => state.orders?.order?.isPhoneNumberRegistered;

export const sGetOrderItems = (state: RootState) => state?.orders?.order?.items;
export const sGetOrderCustomerDetails = (state: RootState) => state?.orders?.order?.customerDetails;
export const sGetOrderCustomerId = (state: RootState) => state?.orders?.order?.customerId;
export const sGetOrderCustomDiscount = (state: RootState) => state?.orders?.order?.customDiscount;
export const sGetOrderBuyerTab = (state: RootState) => state?.orders?.order?.buyerTab;
export const sGetOrderShouldShowPaymentInput = (state: RootState) => state.orders.order.shouldShowPaymentInput;
export const sGetOrderPaymentData = (state: RootState) => state.orders?.order?.paymentData;
