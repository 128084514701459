import { RootState } from 'redux/reduxStore';

export const sGetPaymentImageModal = (state: RootState) => state?.appState?.paymentImageModal;
export const sGetTitleForm = (state: RootState) => state?.appState.titleForm;

export const sGetIsShowModalSuccess = (state: RootState) => state.appState?.isShowModelSuccess;
export const sGetShowDialogLogin = (state: RootState) => state.appState?.showDialogLogin;

export const sGetLocale = (state: RootState) => state.appState?.locale;
export const sGetLocaleCountry = (state: RootState) => state.appState?.locale?.country;
export const sGetLocaleCode = (state: RootState) => state.appState?.locale?.code;

export const sGetPostCode = (state: RootState) => state.appState?.postCode;
export const sGetPostCodeData = (state: RootState) => state.appState.postCode.postCodeData;
export const sGetPostCodeDataText = (state: RootState) => state.appState.postCode.postCodeData?.text;
export const sGetIsErrorPostCode = (state: RootState) => state.appState.postCode.isError;
export const sGetIsHidePostCode = (state: RootState) => state.appState?.postCode.isHidePostCode;
export const sGetPostCodeBannerHeight = (state: RootState) => state.appState?.postCode?.bannerHeight;

export const sGetIsBuyerFillAllAddress = (state: RootState) => state.appState?.isBuyerFillAllAddress;

export const sGetHasShippingFee = (state: RootState) => state.appState?.hasShippingFee;

export const sGetShowPaymentModal = (state: RootState) => state.appState?.showPaymentModal;
export const sGetModalLoadingWithText = (state: RootState) => state.appState?.modalLoadingWithText;
export const sGetModalWithButton = (state: RootState) => state.appState.modalWithButton;
export const sGetConfirmModalData = (state: RootState) => state.appState?.confirmModalData;
export const sGetShowStickyBar = (state: RootState) => state.appState?.stickyBar.showStickyBar;
export const sGetStickyBar = (state: RootState) => state.appState?.stickyBar;

export const sGetAppState = (state: RootState) => state.appState;

export const sGetPaymentMethods = (state: RootState) => state.appState?.paymentMethods;

export const sGetTrueMoneyPayment = (state: RootState) => state.appState.trueMoneyPayment;

export const sGetIsLoading = (state: RootState) => state.appState.isLoading;

export const sGetIsSearchProductsLoading = (state: RootState) => state.appState.isSearchProductsLoading;
export const sGetIsHomeLoading = (state: RootState) => state.appState.isHomeLoading;
export const sGetIsProductDetailPageLoading = (state: RootState) => state.appState.isProductDetailPageLoading;
export const sGetIsProductsLoading = (state: RootState) => state.appState.isProductsLoading;
export const sGetIsCategoriesLoading = (state: RootState) => state.appState.isCategoriesLoading;
export const sGetIsPollingCartPaymentStatus = (state: RootState) => state.appState.isPollingCartPaymentStatus;
export const sGetLocationToastState = (state: RootState) => state.appState.locationToastState;
export const sGetIsLocationOnMapOutOfRange = (state: RootState) => state.appState.isLocationOnMapOutOfRange;
export const sGetIsPendingTransaction = (state: RootState) => state.appState.isPendingTransaction;
export const sGetCanLoadMoreStore = (state: RootState) => state?.appState?.canLoadMoreStores;

export const sGetOrderCheckoutStep = (state: RootState) => state.appState.orderCheckoutStep;
export const sGetDisabledAddressFields = (state: RootState) => state?.appState?.disabledAddressFields;
