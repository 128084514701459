const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

let isSentryInitialized = false;

export const initSentry = async () => {
  const Sentry = await import('@sentry/nextjs');

  if (!isSentryInitialized) {
    Sentry.init({
      dsn: SENTRY_DSN || 'https://05715d4dbc0a4c0397e40707ad8ded0e@o699755.ingest.sentry.io/5892048',
      tracesSampleRate: 1.0,
    });

    isSentryInitialized = true;
  }

  return Sentry;
};
