import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import { Store } from 'types';
import { IStoreCategory, IStoreSearchFilter } from './Common';

export enum CommonActions {
  FETCH_STORE_CATEGORIES_ASYNC = 'FETCH_STORE_CATEGORIES_ASYNC',
  FETCH_STORE_CATEGORIES = 'FETCH_STORE_CATEGORIES',
  FETCH_CITIES_ASYNC = 'FETCH_CITIES_ASYNC',
  FETCH_CITIES = 'FETCH_CITIES',
  SET_SELECTED_STORE_CATEGORY = 'SET_SELECTED_STORE_CATEGORY',
  SEARCH_STORES = 'SEARCH_STORES',
  SEARCH_STORES_ASYNC = 'SEARCH_STORES_ASYNC',
  SET_SELECTED_CITY = 'SET_SELECTED_CITY',
}

export interface ICommonGeneralAction<T> {
  type: CommonActions;
  data: T;
}

export const fetchStoreCategoriesActionAsync = (): ICommonGeneralAction<void> => {
  return { type: CommonActions.FETCH_STORE_CATEGORIES_ASYNC, data: null };
};

export const fetchStoreCategoriesAction = (
  storeCategories: IStoreCategory[],
): ICommonGeneralAction<IStoreCategory[]> => {
  return { type: CommonActions.FETCH_STORE_CATEGORIES, data: storeCategories };
};

export const fetchCitiesActionAsync = (): ICommonGeneralAction<void> => {
  return { type: CommonActions.FETCH_CITIES_ASYNC, data: null };
};

export const fetchCitiesAction = (cities: ICity[]): ICommonGeneralAction<ICity[]> => {
  return { type: CommonActions.FETCH_CITIES, data: cities };
};

export const setSelectedStoreCategory = (storeCategory: IStoreCategory): ICommonGeneralAction<IStoreCategory> => {
  return { type: CommonActions.SET_SELECTED_STORE_CATEGORY, data: storeCategory };
};

export const searchStoresActionAsync = (searchFilter: IStoreSearchFilter): ICommonGeneralAction<IStoreSearchFilter> => {
  return { type: CommonActions.SEARCH_STORES_ASYNC, data: searchFilter };
};

export const searchStoresAction = (stores: Store[]): ICommonGeneralAction<Store[]> => {
  return { type: CommonActions.SEARCH_STORES, data: stores };
};

export const setSelectedCityAction = (city: ICity): ICommonGeneralAction<ICity> => {
  return { type: CommonActions.SET_SELECTED_CITY, data: city };
};
