import { IStoreGeneralAction, StoreActions } from './StoreActions';
import { requestGetShippingDistance, requestGetStoreInfo } from './StoreRepository';
import { IGetStoreRequestPayload, IPostCode, IUpdateDeliveryOptionParams, Store } from '../../types';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { AppStateActions, IAppStateGeneralAction } from 'redux/appState/AppStateActions';
import { IMerchantAction } from 'redux/merchant/MerchantAction';
import { logError } from 'utils/error';
import isEmpty from 'lodash/isEmpty';
import router from 'next/router';
import { isServer } from 'utils/constants';
import { trackError404 } from 'utils/analytics';

function* fetchStoreInfoAsync() {
  const store: Store = yield call(requestGetStoreInfo, 'test-store');
  yield put({ type: StoreActions.UPDATE_STORE_DATA, data: store });
}

function* watchFetchStoreInfoAsync() {
  yield takeEvery(StoreActions.FETCH_STORE_INFO_ASYNC, fetchStoreInfoAsync);
}

function* fetchStoreInfoBySlugAsync(action: IStoreGeneralAction<IGetStoreRequestPayload>) {
  const store: Store = yield call(requestGetStoreInfo, action.data.slug, action.data?.params);
  if (isEmpty(store)) {
    if (!isServer) {
      trackError404({ from: 'storeInfo' });
      router.replace('/404');
    }
  } else {
    yield put({ type: StoreActions.UPDATE_STORE_DATA, data: store });
    if (store.merchantId && store.merchantId !== '') {
      yield put({
        type: IMerchantAction.FETCH_MERCHANT_DATA_ASYNC,
        data: store.merchantId,
      });
    }
  }
}

function* watchFetchStoreInfoBySlugAsync() {
  yield takeEvery(StoreActions.FETCH_STORE_INFO_BY_SLUG_ASYNC, fetchStoreInfoBySlugAsync);
}

function* getPostCodeAsync(action: IAppStateGeneralAction<IUpdateDeliveryOptionParams>) {
  try {
    const postCodeData: IPostCode = yield call(requestGetShippingDistance, action.data);
    yield put({ type: AppStateActions.TOGGLE_POSTCODE_ERROR, data: false });
    yield put({ type: AppStateActions.UPDATE_POSTCODE_DATA, data: postCodeData?.shippingDistance });
    yield put({ type: AppStateActions.TOGGLE_HIDE_POSTCODE, data: true });
  } catch (e) {
    logError(e);
    yield put({ type: AppStateActions.TOGGLE_POSTCODE_ERROR, data: true });
    yield put({ type: AppStateActions.TOGGLE_HIDE_POSTCODE, data: false });
  }
}

function* watchGetPostCodeAsync() {
  yield takeEvery(AppStateActions.GET_POSTCODE_DATA_ASYNC, getPostCodeAsync);
}

function* storeSaga() {
  yield all([watchFetchStoreInfoAsync(), watchFetchStoreInfoBySlugAsync(), watchGetPostCodeAsync()]);
}

export default storeSaga;
