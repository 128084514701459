import { EEvent, ITrackCartDiscount } from 'types/analytics';
import { trackEvent } from '..';

export function trackCartDiscount(options: ITrackCartDiscount) {
  trackEvent(EEvent.Cart_Discount, options);
}

export function trackCartDeleteItem() {
  trackEvent(EEvent.Cart_DeleteItem);
}

export function trackCartDeleteItem_Cancel() {
  trackEvent(EEvent.Cart_DeleteItem_Cancel);
}

export function trackCartDeleteItem_Confirm() {
  trackEvent(EEvent.Cart_DeleteItem_Confirm);
}

export function trackCartDeliveryAddress() {
  trackEvent(EEvent.Cart_DeliveryAddress);
}
