export default {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  F5F5F5: '#F5F5F5',
  C5C5C5: '#C5C5C5',
  ZAAPI2: '#42a391',
  ZAAPI2B: '#12857C',
  ZAAPI3: '#D6D6D6',
  E5E5E5: '#E5E5E5',
  ZAAPIBLACK: '#000000',
  HEAD: 'linear-gradient(91.92deg, #54B56F -31.48%, #2B90AB 100%)',
  RED: '#E51D35',
  GREY: '#999999',
  ZAAPI4: '#4B4A4B',
  GREEN: '#4DCD7F',
  MID_EMPHASIS: 'rgba(21, 25, 32, 0.5)',
  B6B6B6: '#B6B6B6',
  CBC7C1: '#1CBC7C',
  E51D35: '#E51D35',
  EFEFEF: '#EFEFEF',
  YELLOW_GRADIENT: 'linear-gradient(89.62deg, #F8B90A -13.19%, #FF9300 99.35%)',
};
