/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Country } from 'utils/countries';
import { District, Province, SubDistrict } from './adminDivision';
import { Merchant } from './merchant';
import { IPaymentData } from './order';
/* eslint-disable max-lines */
// General
export enum EReadStoreBy {
  ID = 'id',
  SLUG = 'slug',
}
//
export interface Product {
  id?: string;
  name: string;
  type: string;
  description: string;
  unitPrice: number;
  numberInStock: number;
  unitType: string;
  unitDetail: string;
  displayProductEnabled: boolean;
  deliveryProfileId: string;
  categoryIds: string[];
  storeId?: string;
  createdAt?: string;
  updatedAt?: string;
  slug?: string;
  photoUrls: string[];
  isInStock: boolean;
  variants?: Variants[];
  variantConfig?: VariantConfig[];
  finalPrice?: number;
  quantity?: number;
  remark?: string;
  finalVariantPrice?: {
    min: number;
    max: number;
  };
  discountValue?: {
    totalFixedAmountOff?: number;
    totalPercentageOff?: number;
  };
  media?: MediaDto[];
}
export enum MediaType {
  VIDEO = 'video',
  IMAGE = 'image',
}
export interface MediaDto {
  type: MediaType;
  url: string;
  thumbnailUrl?: string;
}
export interface DailyWorkingPeriod {
  start: number; // in minutes
  end: number; // in minutes
}

export enum BusinessHoursKeyEnums {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export interface BusinessHoursSetting {
  MON: DailyWorkingPeriod[];
  TUE: DailyWorkingPeriod[];
  WED: DailyWorkingPeriod[];
  THU: DailyWorkingPeriod[];
  FRI: DailyWorkingPeriod[];
  SAT: DailyWorkingPeriod[];
  SUN: DailyWorkingPeriod[];
}

export interface Store {
  businessName: string;
  businessType: string;
  productSetType: EProductSetType;
  productSetName: string;
  email: string;
  anytimeOrderEnabled: boolean;
  deliveryType: DeliveryType;
  address: Address;
  userId: string;
  createdAt: string;
  updatedAt: string;
  paymentDetail?: PaymentDetail;
  slug?: string;
  logoUrl?: string;
  logoId?: string;
  id: string;
  hasSharedStoreOnSocialMedia?: boolean;
  allowPickupEnabled?: boolean;
  displayLocationOnlineEnabled?: boolean;
  businessHours: BusinessHoursSetting;
  onHolidayEnabled?: boolean;
  qrCodeUrl?: string;
  storeContactDetails?: storeContactDetails;
  isBannerRemoved?: boolean;
  currency?: string;
  storeLink?: string;
  languageCode?: string;
  merchantId?: string;
  merchant?: Merchant;
  storeDirectoryLogoUrl?: string;
  facebookAccount?: FacebookResponseDto;
  displayFbLikesAndFollowers?: boolean;
}

interface FacebookResponseDto {
  metadata: {
    likeCount: number;
    followerCount: number;
  };
}
export enum EProductSetType {
  FNB = 'F&B',
  NON_FNB = 'NON F&B',
}
export interface storeContactDetails {
  displayOnStoreEnabled: boolean;
  phoneNumber?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  lineAccount?: string;
}
export interface DeliveryType {
  type: string;
  radius?: number;
}

export interface Address {
  addressDetails: string;
  longitude: string;
  latitude: string;
  address1?: string;
  address2?: string;
  district?: string;
  province?: string;
  subDistrict?: string;
  postalCode?: string;
  country?: string;
}

export interface PaymentDetail {
  promptPay?: PromptPay;
  bankAccount?: BankAccount;
}

export interface PromptPay {
  promptPayNumber: string;
  enabled: boolean;
}

export interface BankAccount {
  bankName: string;
  accountNumber: string;
  accountName: string;
  enabled: boolean;
}

export interface UpdateLogo {
  logoId: string;
  logoUrl: string;
  status: string;
}

export enum CategoryFilterEnums {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  ALL = 'ALL',
}

export interface ActionResponse {
  errorCode: string;
  statusCode?: number;
  message?: string;
}

export interface DeliveryProfile {
  name: string;
  feeType?: string;
  flatFee?: number;
  distanceBasedFees?: (DistanceBasedFees | null)[];
  id?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface DistanceBasedFees {
  minDist?: number;
  fee?: number;
}

export interface Category {
  name: string;
  photoUrl?: string;
  id?: string;
  displayCategoryEnabled?: boolean;
  productCount?: number;
  order?: number;
  buyerWebDisplayImgUrl?: string;
  slug?: string;
}

export interface CategoryFilterInputParams {
  searchText?: string;
  filter?: CategoryFilterEnums;
}

export interface Variant {
  name: string;
  storeId?: string;
  categoryName: string;
  options: VariantOption[];
  createdAt?: string;
  updatedAt?: string;
  id?: string;
}

export interface VariantOption {
  name: string;
  available: boolean;
}

export interface VariantOptionProps {
  title?: string;
  checked?: boolean;
  // isAddBtn?: boolean;
  // isEmptyBtn?: boolean; // typing box
  isNullBtn?: boolean; // for fullfill
  // ref?: React.RefObject<TextInput>;
}
export interface UploadPhotoResponse {
  photoUrl: string;
  status: string;
}

export interface ErrorModalData {
  title: string;
  subtitle: string;
  dismissButtonTitle: string;
  display: boolean;
}

export enum ConfirmModalTypeEnums {
  PRODUCT_REORDER_UNSAVED = 'PRODUCT_REORDER_UNSAVED',
  PRODUCT_DISABLE_TOGGLE = 'PRODUCT_DISABLE_TOGGLE',
  NONE = '',
  DISCOUNT_DELETE = 'DISCOUNT_DELETE',
  CANCEL_ORDER_IN_ORDER_DETAIL = 'CANCEL_ORDER',
  ASK_REFUND_ORDER_ORDER_DETAIL = 'ASK_REFUND_ORDER_ORDER_DETAIL',
  ACCOUNT_SETTING_EXIT = 'ACCOUNT_SETTING_EXIT',
}

export enum ConfirmModalStateEnums {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export interface ConfirmModalData {
  title: string;
  subtitle: string;
  confirmButtonTitle: string;
  cancelButtonTitle: string;
  type: ConfirmModalTypeEnums;
  display: boolean;
  state?: ConfirmModalStateEnums;
  metadata?: any;
  styleHeader?: CSSProperties;
  styleSubTitle?: CSSProperties;
}

export interface SuccessModalData {
  title: string;
  display: boolean;
  metadata?: any;
}

export interface AppState {
  showProductBottomSheet: boolean;
  isDraggingCategory: boolean;
  errorModalData: ErrorModalData;
  isDraggingProduct: boolean;
  confirmModalData: ConfirmModalData;
  successModalData: SuccessModalData;
  stickyBar: StickyBarData;
  postCode: PostCodeData;
  showDialogLogin: boolean;
  modalWithButton: ModalWithButton;
  isShowModelSuccess: boolean;
  titleForm: string;
  locale: {
    code: string;
    country: string;
  };
  hasShippingFee?: boolean;
  isHomeLoading?: boolean;
  isCategoryPageLoading?: boolean;
  isProductDetailPageLoading?: boolean;
  isLoading: boolean;
  isProductsLoading?: boolean;
  isCategoriesLoading?: boolean;
  isSearchProductsLoading?: boolean;
  screenHeaderStyle?: string;
  orderCheckoutStep?: number;
  paymentImageModal?: PaymentImageModal;
  paymentMethods?: unknown[];
  trueMoneyPayment?: boolean;
  showPaymentModal?: boolean;
  modalLoadingWithText?: {
    isOpen: boolean;
    text?: string;
  };
  isBuyerFillAllAddress?: boolean;
  isPollingCartPaymentStatus: boolean;
  locationToastState?: ILocationToastState;
  isLocationOnMapOutOfRange?: boolean;
  isPendingTransaction?: boolean;
  canLoadMoreStores?: boolean;
  shouldDisableEditShippingAddress?: boolean;
  disabledAddressFields?: string[];
}
export interface PlaceSelectionDetail {
  description: string;
  lat: number;
  lng: number;
  addressComponents?: AddressComponents[];
}
export interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}
export interface ModalWithButton {
  isOpen?: boolean;
  headerTxt?: string;
  subTitleTxt?: string;
  styleHeader?: CSSProperties;
  styleSubTitle?: CSSProperties;
  isCanCancel?: boolean;
  cancelTxt?: string;
  acceptTxt?: string;
  onClickCancel?: () => void;
  onClickAccept?: () => void;
  isReceiptUploadSuccessModal?: boolean;
  imageSrc?: string;
  maxWidth?: string;
}
export interface PaymentImageModal {
  isOpen: boolean;
  onClose?: () => void;
  paymentMethod?: string;
  styleHeader?: CSSProperties;
  buttonText?: string;
  imageSource?: string;
  isIframe?: boolean;
  rawContent?: string;
}
export interface PostCodeData {
  postCodeTxt: string;
  postCodeData: {
    text: string;
    value: number;
  };
  isError: boolean;
  isHidePostCode: boolean;
  bannerHeight: number;
}

export interface StickyBarData {
  showStickyBar: boolean;
  textStickyBar: {
    message: string;
    day?: string;
    time?: string;
    amOrPm?: string;
  };
}
export interface Transaction {
  response?: any;
  errorResponse?: ActionResponse;
}

export interface ChunkValue {
  start: number;
  end: number;
  text?: string;
}

export interface IPostCode {
  shippingDistance: {
    text: string;
    value: number;
  };
}
export interface VariantConfig {
  name: string;
  minChoice?: number;
  maxChoice?: number;
}
export interface AppliedVariants {
  name?: string;
  option?: string;
}
export interface Variants {
  addedPrice?: number;
  appliedVariants?: AppliedVariants[];
  numberInStock?: number;
  isAvailable?: boolean;
  finalPrice?: number;
}

export enum EReadEntityBy {
  ID = 'id',
  SLUG = 'slug',
}

export interface IGeoLocation {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: string;
  lat: number;
  lon: number;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;
}
export interface IProductCart {
  id?: string;
  items?: ISelectedProductGetFromCart[];
  remarks?: string;
}
export interface ISelectedVariantGetFromCart {
  appliedVariants?: AppliedVariants[];
  addedPrice?: number;
  isAvailable?: boolean;
  numberInStock?: number;
}
export interface ISelectedProductGetFromCart {
  price: number;
  productId: string;
  selectedVariants?: ISelectedVariantGetFromCart[];
  quantity?: number;
  remarks?: string;
  originalPrice?: number;
  errorCode?: string;
  productPhotoUrls?: string[];
  productName?: string;
}
export interface BuyerFormInfo {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email: string;
  note?: string;
  phoneNumber?: string;
  address?: {
    detailedAddress?: string;
    postalCode: string;
    country?: Country;
    province?: Province;
    district?: District;
    subDistrict?: SubDistrict;
    subDistrictTxt?: string;
    provinceTxt?: string;
    districtTxt?: string;
    barangay?: string;
    region?: string;
    lat?: string;
    lng?: string;
    detailedAddress2?: string;
    provinceDistrictPostalCode?: string;
  };
  isReceiveLineOrderNotification?: boolean;
}
export interface ErrorFormClone {
  fullName: undefined;
  phoneNumber: undefined;
  email: undefined;
  address: {
    detailedAddress: undefined;
    postalCode: undefined;
    country: undefined;
    province: undefined;
    district: undefined;
    provinceTxt: undefined;
    districtTxt: undefined;
    barangay: undefined;
    region: undefined;
  };
}
export interface BuyerAddressField {
  form: BuyerFormInfo;
  handleSetForm: (e: string, value: any) => void;
  validateForm: any;
  regionList?: any[];
  provinceList?: any[];
  districtList?: any[];
  barangayList?: any[];
  shipping?: boolean;
  isSelfPickUp?: boolean;
}
export interface BuyerAddressFieldTypeMob {
  form: BuyerFormInfo;
  setForm: (e: BuyerFormInfo) => void;
  validateForm: any;
  regionList: any[];
  provinceList: any[];
  districtList: any[];
  barangayList: any[];
  removeErrorField?: (e: string) => void;
  isSelfPickUp?: boolean;
  postalList?: string[];
  isAddressBookForm?: boolean;
}

export enum DeliveryOptionType {
  SELF_PICKUP = 'Self Pick-Up',
  COURIER_DELIVERY = 'Courier Delivery',
}

export enum ScreenHeaderStyle {
  STEPS = 'STEPS',
  DEBIT_CARD = 'Add new Debit/Credit Card',
  PROPMT_PAY = 'PromptPay QR',
  QR_CREDIT = 'QR Credit',
  MOBILE_BANKING = 'Mobile Banking',
  TRUE_MONEY_WALLET = 'TrueMoney Wallet',
  RABBIT_LINE_PAY = 'Rabbit LINE Pay',
  WE_CHAT_PAY = 'WeChat Pay',
  BILL_PAYMENT = 'Bill Payment',
}

export interface DebitCard {
  cardNumber: string;
  expDate: string;
  cvv: string;
}

export interface PaymentMethodInfo {
  cardNumber?: string;
  expDate?: string;
  cvv?: string;
  phoneNumber?: string;
  bankName?: string;
  type?: string;
  cardHolderName?: string;
  image?: string;
  title: string;
}
export enum FreeShipType {
  NONE = 'none',
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export enum OpenMenuEnum {
  MAIN_MENU = 'mainMenu',
  ACCOUNT_SETTINGS = 'accountSettings',
  CATEGORIES = 'categories',
  LANGUAGE_SUB_MENU = 'LANGUAGE_SUB_MENU',
}

export interface PaymentStatusResponseDto {
  cartId?: string;
  orderId?: string;
  paymentData?: IPaymentData;
}
export enum SessionStorageKeys {
  ADDRESS_BANNER_INFO = 'addressBannerInfo',
  ORDER_CHECKOUT_STEP = 'ORDER_CHECKOUT_STEP',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
}
export enum DataStorageKeys {
  currentBuyerInfo = 'currentBuyerInfo',
  id_token = 'id_token',
  access_token = 'access_token',
  refresh_token = 'refresh_token',
  lineUserProfile = 'lineUserProfile',
  user_info = 'user_info',
  auth_provider = 'auth_provider',
  isAfterAddToCart = 'isAfterAddToCart',
  orderId = 'orderId',
}
export interface DropdownSelectProps {
  name?: string;
  value: string;
  image?: string;
}
export enum ExpressedPreferenceEnum {
  SELF_PICKUP = 'self-pickup',
  COURIER_DELIVERY = 'courier-delivery',
}
export interface IUpdateDeliveryOptionParams {
  isSelfPickedUp?: boolean;
  finalTotalPrice?: number;
  fullAddress?: string;
  country?: string;
  lat?: string;
  lng?: string;
  slug?: string;
}
export interface IUpdateDiscountParams {
  discountCode: string;
  fullAddress?: string;
  lat?: string;
  lng?: string;
}
export enum ELocationToastType {
  SELECTED_GUIDELINE = 'selected_guideline',
  ERROR_LOCATED = 'error_located',
  ERROR_OUT_OF_RANGE = 'error_out_of_range',
}
export interface ILocationToastState {
  type?: ELocationToastType;
  isOpen?: boolean;
  titleTxt?: string;
}
export enum EAppStateFlagKeys {
  canLoadMoreStores = 'canLoadMoreStores',
}
export enum EAddressKeyIndex {
  PROVINCE = 'PROVINCE',
  DISTRICT = 'DISTRICT',
  SUBDISTRICT = 'SUBDISTRICT',
  POSTALCODE = 'POSTALCODE',
}

export enum EStepCheckout {
  AuthorizationStep,
  AddressStep,
  PaymentMethodStep,
  ConfirmationStep,
}

export interface IFetchCartDetailByIdParams {
  cartId: string;
  slug: string;
}

export enum EKeyCode {
  Enter = 'Enter',
}

export enum AddressCountryCode {
  TH = 'TH',
}
export interface IGetStoreParams {
  includeFBConnectionData?: boolean;
}
export enum ELanguageCode {
  TH = 'th',
  EN = 'en',
}

export interface IGetStoreRequestPayload {
  slug: string;
  params?: IGetStoreParams;
}
