import { IGetStoreRequestPayload, Store } from '../../types';

export enum StoreActions {
  FETCH_STORE_INFO_ASYNC = 'FETCH_STORE_INFO_ASYNC',
  UPDATE_STORE_DATA = 'UPDATE_STORE_DATA',
  GET_STORE_DATA = 'GET_STORE_DATA',
  CLEAR_STORE_DATA = 'CLEAR_STORE_DATA',
  FETCH_STORE_INFO_BY_SLUG_ASYNC = 'FETCH_STORE_INFO_BY_SLUG_ASYNC',
}

export interface IStoreGeneralAction<T> {
  type: StoreActions;
  data: T;
}
export const updateStoreDataAction = (store: Partial<Store>): IStoreGeneralAction<Partial<Store>> => {
  return { type: StoreActions.UPDATE_STORE_DATA, data: store };
};

export const fetchStoreInfoBySlugActionAsync = (
  slug: string,
  params = { includeFBConnectionData: true },
): IStoreGeneralAction<IGetStoreRequestPayload> => {
  return { type: StoreActions.FETCH_STORE_INFO_BY_SLUG_ASYNC, data: { slug, params } };
};

export const fetchStoreInfoActionAsync = (): IStoreGeneralAction<void> => {
  return { type: StoreActions.FETCH_STORE_INFO_ASYNC, data: null };
};

export const clearStoreDataAction = (): IStoreGeneralAction<void> => {
  return { type: StoreActions.CLEAR_STORE_DATA, data: null };
};
