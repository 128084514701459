import { PaymentStatusResponseDto } from 'types';
import { EOrderTab, IOrder } from '../../types/order';
import { IUpdateOrderAction, OrderActions } from './OrderActions';

export interface OrderReduxState {
  orders: IOrder[];
  order: IOrder;
  currentTab?: EOrderTab;
  paymentStatusResponse: PaymentStatusResponseDto;
}

export const DEFAULT_ORDER_STATE: OrderReduxState = {
  orders: [],
  order: {},
  paymentStatusResponse: {},
};

const OrderReducer = (state = DEFAULT_ORDER_STATE, action: IUpdateOrderAction): OrderReduxState => {
  switch (action.type) {
    case OrderActions.FETCH_ALL_ORDERS:
      return {
        ...state,
        orders: action.data?.orders as IOrder[],
        currentTab: action.data?.tab,
      };
    case OrderActions.STORE_ORDER:
      return {
        ...state,
        order: action.data as IOrder,
      };
    case OrderActions.SET_PAYMENT_STATUS_RESPONSE:
      return {
        ...state,
        paymentStatusResponse: action.data,
      };
    default:
      return state;
  }
};

export default OrderReducer;
