/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable max-lines */
import { showPaymentImageModal, toggleIsPendingTransaction } from './../appState/AppStateActions';
import i18n from 'i18next';
import produce from 'immer';
import get from 'lodash/get';
import router from 'next/router';
import { all, call, put, select, takeEvery, delay } from 'redux-saga/effects';
import { AppStateActions, IAppStateGeneralAction, toggleIsLoading } from 'redux/appState/AppStateActions';
import { CartAction } from 'redux/cart/CartAction';
import { requestCartCheckout } from 'redux/cart/CartRepository';
import { fetchOrderById, OrderActions, setPaymentStatusResponse } from 'redux/orders/OrderActions';
import { RootState } from 'redux/reduxStore';
import {
  CardInfoDto,
  CartCheckoutDto,
  ChargeResultDto,
  ICheckoutPayload,
  IGBPayGetQR,
  Merchant,
  PaymentMethod,
  PaymentMethodResponseType,
  SavedMethod,
} from 'types/merchant';
import { TransactionDataDto } from 'types/order';
import { maskFullPan } from 'utils/card';
import { convertToUrlEncode } from 'utils/convert';
import { getModalDataFromError, logError } from 'utils/error';
import {
  generatePayloadForSavedDebit,
  getApiGbPayType,
  transformPayloadDebit,
  transformPaymentMethodLink,
  transformPaymentPayload,
} from 'utils/merchant';
import { DataStorage } from 'utils/storage';
import {
  requestGetBillPaymentBarcode,
  requestGetCheckoutByTrueMoneyWallet,
  requestGetDebitCardSecure,
  requestGetDebitCharge,
  requestGetQrByMethod,
  requestGetTokenForDebitCard,
} from './GBPrimePayRepository';
import { IMerchantAction, IMerchantGeneralAction } from './MerchantAction';
import {
  requestCreateSavedMethods,
  requestCreateTransaction,
  requestDeleteSavedMethod,
  requestGetMerchantData,
  requestGetSavedMethods,
} from './MerchantRepository';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import { sGetDefaultAddress, sGetSelectedAddress } from 'redux/auth/AuthSelectors';
import { trackPlaceOrder } from 'utils/analytics';
import { removeDataStorageProducts } from 'utils/products';
import { sGetStoreSlug } from 'redux/store/StoreSelectors';
import { EStepCheckout, SessionStorageKeys } from 'types';
import { sGetCartId } from 'redux/cart/CartSelectors';
import { sGetIsPollingCartPaymentStatus } from 'redux/appState/AppStateSelectors';
import { sGetOrderId } from 'redux/orders/OrderSelectors';

const getMerchant = (state: RootState) => state.merchant.merchant;
const getPaymentInfo = (state: RootState) => state?.cart.paymentMethodInfo;
const getOrderId = (state: RootState) => state.orders.order.id;
const getOrder = (state: RootState) => state.orders.order;

const API_GB_PAY = process.env.NEXT_PUBLIC_GBPAY_URL;

function* fetchMerchantDataAsync(action) {
  try {
    const res: Merchant = yield call(requestGetMerchantData, action.data);
    yield put({
      type: IMerchantAction.FETCH_MERCHANT_DATA,
      data: res,
    });
  } catch (error) {
    logError(error);
  }
}

function* watchFetchMerchantDataAsyncAction() {
  yield takeEvery(IMerchantAction.FETCH_MERCHANT_DATA_ASYNC, fetchMerchantDataAsync);
}

function* getBuyerCheckoutForPayment(
  action: IMerchantGeneralAction<{ data: ICheckoutPayload; cartId?: string; isPayNow: boolean }>,
) {
  try {
    yield put(toggleIsLoading(true));
    yield put(setPaymentStatusResponse({}));
    yield put(toggleIsPendingTransaction(true));
    const { data, cartId, isPayNow } = action.data;
    const selectedAddress = yield select(sGetSelectedAddress);
    const defaultAddress = yield select(sGetDefaultAddress);
    const newData = produce(data, draft => {
      if (draft.shippingInformation.shippingAddress) {
        draft.shippingInformation.shippingAddress.buyerAddrId = selectedAddress?.id || defaultAddress?.id;
      }
    });

    const paymentMethod = data.paymentInformation.paymentMethod;
    const res: CartCheckoutDto = yield call(requestCartCheckout, cartId, newData);
    if (!isPayNow || res?.shouldSkipPaymentRequest) {
      const { slug } = router.query;
      removeDataStorageProducts();
      yield put(toggleIsLoading(false));
      yield call(trackPlaceOrder, res.id);
      router.push(`/${slug}/order/${res.id}`);
    } else {
      if ([PaymentMethod.line_pay, PaymentMethod.mobile_banking].includes(paymentMethod as PaymentMethod)) {
        DataStorage.save({ key: 'orderId', data: res.id });
      }
      yield put({
        type: OrderActions.FETCH_ORDER_ASYNC,
        data: res.id,
      });
      yield put({ type: AppStateActions.SET_IS_POLLING_CART_PAYMENT_STATUS, data: true });
      yield put({ type: OrderActions.SET_PAYMENT_STATUS_RESPONSE, data: {} });
      yield put(fetchOrderById(res.id));
      yield call(classifyPaymentMethodApi, data.paymentInformation, res);
    }
  } catch (error) {
    logError(error);
    yield put({
      type: AppStateActions.SHOW_MODAL_WITH_BUTTON,
      data: getModalDataFromError(error.response?.data),
    });
    yield put(toggleIsPendingTransaction(false));
  } finally {
    yield put(toggleIsLoading(false));
  }
}
function* getCallGBPrimePay(
  action: IMerchantGeneralAction<{
    responseMethod: PaymentMethodResponseType;
    payload: IGBPayGetQR;
    paymentMethod: PaymentMethod;
  }>,
) {
  const { responseMethod, payload, paymentMethod } = action.data;
  if (responseMethod === PaymentMethodResponseType.GET_QR_IMAGE) {
    yield put({
      type: IMerchantAction.GET_QR_IMAGE_BY_PAYMENT_ASYNC,
      data: {
        data: payload,
        payment: paymentMethod,
      },
    });
  }
  if (responseMethod === PaymentMethodResponseType.GET_TRUE_WALLET) {
    yield put({
      type: IMerchantAction.GET_TRUE_WALLET_ASYNC,
      data: payload,
    });
  }
  if (responseMethod === PaymentMethodResponseType.GET_MOBILE_BANKING) {
    yield put({
      type: IMerchantAction.GET_MOBILE_BANKING_ASYNC,
      data: payload,
    });
  }
  if (responseMethod === PaymentMethodResponseType.GET_BARCODE) {
    yield put({
      type: IMerchantAction.GET_BARCODE_ASYNC,
      data: payload,
    });
  }
}
function* watchGetCallApiGBAsync() {
  yield takeEvery(IMerchantAction.GET_CALL_API_GB_PAY_ASYNC, getCallGBPrimePay);
}
function* watchGetBuyerCheckoutForPayment() {
  yield takeEvery(IMerchantAction.GET_CHECKOUT_FOR_PAYMENT_ASYNC, getBuyerCheckoutForPayment);
}
// for payment method that have QR code: PromptPay, QR Credit, WeChat. For LINE -> still error
function* getQrImageByPayment(action: IMerchantGeneralAction<{ data: IGBPayGetQR; payment: PaymentMethod }>) {
  try {
    yield call(toggleShowLoadingModalWithText);
    const { data, payment } = action.data;
    if (payment === PaymentMethod.line_pay) {
      yield call(getLinePayAsync, data);
      return;
    }
    const res = yield call(requestGetQrByMethod, transformPaymentMethodLink(payment), convertToUrlEncode(data));
    const imageBase64 = `data:image/png;charset=utf-8;base64,${res}`;
    const modalData = { paymentMethod: payment, imageSource: imageBase64 };

    if ([PaymentMethod.promptpay_qr, PaymentMethod.qr_credit].includes(payment)) {
      yield put(showPaymentImageModal({ ...modalData, isOpen: false }));
      yield call(redirectToPaymentPage, payment);
    } else yield put(showPaymentImageModal(modalData));
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield call(toggleShowLoadingModalWithText, false);
  }
}
function* watchGetQrImageAsync() {
  yield takeEvery(IMerchantAction.GET_QR_IMAGE_BY_PAYMENT_ASYNC, getQrImageByPayment);
}
function* getLinePayAsync(data: IGBPayGetQR) {
  try {
    yield call(openPaymentInNewTab, data, `${API_GB_PAY}/v2/linepay`);
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  }
}
function* getBarcodeFromBillPayment(action: IMerchantGeneralAction<IGBPayGetQR>) {
  try {
    yield call(toggleShowLoadingModalWithText);
    const res = yield call(requestGetBillPaymentBarcode, convertToUrlEncode(action.data));
    const modalData = { paymentMethod: PaymentMethod.bill_payment, imageSource: res };
    yield put(showPaymentImageModal({ ...modalData, isOpen: false }));
    yield call(redirectToPaymentPage, PaymentMethod.bill_payment);
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield call(toggleShowLoadingModalWithText, false);
  }
}
function* watchGeBarcodeAsync() {
  yield takeEvery(IMerchantAction.GET_BARCODE_ASYNC, getBarcodeFromBillPayment);
}
function* getFormOtpTrueWallet(action: IMerchantGeneralAction<IGBPayGetQR>) {
  try {
    yield call(toggleShowLoadingModalWithText);
    const cardInfo: SavedMethod = yield select(getPaymentInfo);
    const res = yield call(requestGetCheckoutByTrueMoneyWallet, convertToUrlEncode(action.data));
    if (cardInfo?.rememberCard) {
      yield put({
        type: AppStateActions.CREATE_SAVED_METHODS_ASYNC,
        data: {
          paymentInfo: {
            paymentMethod: PaymentMethod.true_wallet,
            phoneNumber: cardInfo?.paymentInfo?.phoneNumber,
          },
        },
      });
    }
    window.open(URL.createObjectURL(new Blob([res], { type: 'text/html' })), '_self');
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield call(toggleShowLoadingModalWithText, false);
  }
}
function* watchGetTrueWalletAsync() {
  yield takeEvery(IMerchantAction.GET_TRUE_WALLET_ASYNC, getFormOtpTrueWallet);
}
function* openPaymentInNewTab(data: IGBPayGetQR, url: string) {
  try {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);

    const fields = keys(omitBy(data, isNil));

    fields.forEach(item => {
      const inputField = document.createElement('input');
      Object.assign(inputField, {
        type: 'hidden',
        name: item,
        value: get(data, [item]),
      });
      form.appendChild(inputField);
    });
    document.body.appendChild(form);
    const orderId = yield select(getOrderId);
    const { slug } = router.query;
    DataStorage.save({ key: 'WebsiteBeforeRedirect', data: { slug, orderId } });
    form.submit();
  } catch (e) {
    logError(e);
  }
}
// payment page is for mobile with prompt pay, qr credit and bill payment only
function* redirectToPaymentPage(paymentMethod: PaymentMethod) {
  try {
    const slug = yield select(sGetStoreSlug);
    const cartId = yield select(sGetCartId);
    const orderId = yield select(sGetOrderId);
    const isPollingCartPaymentStatus = yield select(sGetIsPollingCartPaymentStatus);
    if (isPollingCartPaymentStatus) {
      DataStorage.saveItemSession({
        key: SessionStorageKeys.ORDER_CHECKOUT_STEP,
        data: EStepCheckout.ConfirmationStep,
      });
      DataStorage.saveItemSession({ key: SessionStorageKeys.PAYMENT_METHOD, data: paymentMethod });
    }
    if (router.pathname !== '/[slug]/complete-payment') {
      const pollingId = isPollingCartPaymentStatus ? { cartId: cartId, orderId: orderId } : { orderId: orderId };
      router.push(
        { pathname: `/${slug}/complete-payment`, query: { backUrl: router.asPath, ...pollingId } },
        undefined,
        {
          shallow: true,
        },
      );
    }
  } catch (e) {
    logError(e);
  }
}

function* getMobileBankingAsync(action: IMerchantGeneralAction<IGBPayGetQR>) {
  try {
    yield call(toggleShowLoadingModalWithText);
    yield call(openPaymentInNewTab, action.data, `${API_GB_PAY}/v2/mobileBanking`);
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield call(toggleShowLoadingModalWithText, false);
  }
}
function* watchGetMobileBankingAsync() {
  yield takeEvery(IMerchantAction.GET_MOBILE_BANKING_ASYNC, getMobileBankingAsync);
}
function* showErrorModalWithButton() {
  yield put({
    type: AppStateActions.TOGGLE_SHOW_PAYMENT_MODAL,
    data: true,
  });
}

function* createSavedMethodsAsync(action: IAppStateGeneralAction<SavedMethod>) {
  try {
    const merchant: Merchant = yield select(getMerchant);
    const data = produce(action.data, draft => {
      // Mask card number if necessary
      const cardNumber = draft.paymentInfo?.cardNumber;
      if (!isNil(cardNumber)) {
        draft['paymentInfo'].cardNumber = maskFullPan(cardNumber);
      }
    });
    const res = yield call(requestCreateSavedMethods, data, merchant.id);
    yield put({
      type: CartAction.SET_PAYMENT_METHOD,
      data: res,
    });
    if (res) {
      yield call(fetchSavedMethodAsync);
    }
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  }
}

function* watchCreateSavedMethodsAsync() {
  yield takeEvery(AppStateActions.CREATE_SAVED_METHODS_ASYNC, createSavedMethodsAsync);
}

function* deleteSavedMethodAsync(action: IAppStateGeneralAction<SavedMethod>) {
  try {
    const res = yield call(requestDeleteSavedMethod, action.data.id);
    if (res) {
      yield call(fetchSavedMethodAsync);
    }
  } catch (error) {
    logError(error);
  }
}
function* watchDeleteSavedMethodAsync() {
  yield takeEvery(AppStateActions.DELETE_SAVED_METHODS_ASYNC, deleteSavedMethodAsync);
}
function* fetchSavedMethodAsync() {
  try {
    const merchant: Merchant = yield select(getMerchant);
    const res: SavedMethod[] = yield call(requestGetSavedMethods, merchant.id);
    yield put({
      type: AppStateActions.SET_PAYMENT_METHODS,
      data: res,
    });
  } catch (error) {
    logError(error);
  }
}
function* watchFetchSavedMethodAsync() {
  yield takeEvery(AppStateActions.GET_SAVED_METHODS_ASYNC, fetchSavedMethodAsync);
}
function* getDebitCardAsync(action: IMerchantGeneralAction<{ order: CartCheckoutDto; merchantKey?: string }>) {
  try {
    yield call(toggleShowLoadingModalWithText);
    const { order, merchantKey } = action.data;
    const cardInfo: SavedMethod = yield select(getPaymentInfo);
    let token = cardInfo?.paymentInfo?.token || '';
    if (!token) {
      const payloadGetToken = {
        rememberCard: cardInfo?.rememberCard,
        card: {
          name: cardInfo?.paymentInfo.name,
          number: cardInfo?.paymentInfo.cardNumber,
          expirationMonth: cardInfo?.paymentInfo.expirationDate.slice(0, 2),
          expirationYear: cardInfo?.paymentInfo.expirationDate.slice(3, 5),
          securityCode: cardInfo?.paymentInfo.securityCode,
        },
      };
      if (payloadGetToken?.card?.number) {
        token = yield call(requestGetTokenForDebitCard, payloadGetToken, merchantKey);
      }
      if (cardInfo?.rememberCard) {
        yield put({
          type: AppStateActions.CREATE_SAVED_METHODS_ASYNC,
          data: { paymentInfo: generatePayloadForSavedDebit(cardInfo, token) },
        });
      }
    }
    yield delay(1000);
    const payload: IGBPayGetQR = transformPayloadDebit(order, token);
    const res: ChargeResultDto = yield call(requestGetDebitCharge, payload);

    const gbPrimePayHTMLForm = yield call(
      requestGetDebitCardSecure,
      convertToUrlEncode({
        publicKey: merchantKey,
        gbpReferenceNo: res.gbpReferenceNo,
      }),
    );

    // avoid using new Blob() since WebViews don't support them
    const newWindow = window.open('', '_self');
    newWindow.document.write(gbPrimePayHTMLForm); 
    newWindow.document.close();

  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield call(toggleShowLoadingModalWithText, false);
  }
}
function* watchGetDebitPayAsync() {
  yield takeEvery(IMerchantAction.GET_DEBIT_CARD_PAYMENT_ASYNC, getDebitCardAsync);
}
function* createPaymentFromOrder(action: IMerchantGeneralAction<ICheckoutPayload>) {
  try {
    yield put(toggleIsLoading(true));

    const order = yield select(getOrder);
    const res: TransactionDataDto = yield call(requestCreateTransaction, order.id, action.data);
    const newOrder: CartCheckoutDto = {
      ...order,
      transaction: res,
    };
    if (res.shouldSkipPaymentRequest) {
      location.reload();
      return;
    }
    yield put({ type: OrderActions.SET_PAYMENT_STATUS_RESPONSE, data: {} });
    yield call(classifyPaymentMethodApi, action.data.paymentInformation, newOrder);
  } catch (error) {
    logError(error);
    yield call(showErrorModalWithButton);
  } finally {
    yield put(toggleIsLoading(false));
  }
}
function* watchGetPaymentFromOrder() {
  yield takeEvery(IMerchantAction.CREATE_PAYMENT_FROM_ORDER, createPaymentFromOrder);
}
function* classifyPaymentMethodApi(paymentInfo: CardInfoDto, order: CartCheckoutDto) {
  try {
    const paymentMethod = paymentInfo.paymentMethod;

    if (getApiGbPayType(paymentMethod) === PaymentMethodResponseType.GET_DEBIT_CARD_RESPONSE) {
      yield put({
        type: IMerchantAction.GET_DEBIT_CARD_PAYMENT_ASYNC,
        data: {
          order: order,
          merchantKey: order.transaction.merchantPublicKey,
        },
      });
      return;
    } else {
      const { payload, responseMethod } = transformPaymentPayload(paymentInfo, order);
      yield put({
        type: IMerchantAction.GET_CALL_API_GB_PAY_ASYNC,
        data: {
          responseMethod,
          payload,
          paymentMethod,
        },
      });
    }
  } catch (error) {
    logError(error);
  }
}
function* toggleShowLoadingModalWithText(isShow = true) {
  if (isShow) {
    yield put({
      type: AppStateActions.TOGGLE_SHOW_LOADING_MODAL_WITH_TEXT,
      data: i18n.t('Please wait...\nYour payment is being processed.'),
    });
  } else {
    yield put({
      type: AppStateActions.TOGGLE_HIDE_LOADING_MODAL_WITH_TEXT,
    });
  }
}
function* merchantSaga() {
  yield all([
    watchFetchMerchantDataAsyncAction(),
    watchGetQrImageAsync(),
    watchGetBuyerCheckoutForPayment(),
    watchGeBarcodeAsync(),
    watchCreateSavedMethodsAsync(),
    watchFetchSavedMethodAsync(),
    watchDeleteSavedMethodAsync(),
    watchGetTrueWalletAsync(),
    watchGetMobileBankingAsync(),
    watchGetCallApiGBAsync(),
    watchGetDebitPayAsync(),
    watchGetPaymentFromOrder(),
  ]);
}

export default merchantSaga;
