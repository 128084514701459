import { RootState } from 'redux/reduxStore';
import find from 'lodash/find';
import { AddressDto, BuyerAddressType } from 'types/auth';
import { createSelector } from 'reselect';
import reverse from 'lodash/reverse';

export const sGetIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const sGetDefaultAddress = (state: RootState) =>
  find(state.auth.buyer.addresses, (o: AddressDto) => o.isDefault);

export const sGetSelectedAddress = (state: RootState) => state.auth.selectedAddress;

export const sGetBuyer = (state: RootState) => state.auth.buyer;
export const sGetBuyerAddresses = (state: RootState) => state.auth.buyer.addresses;

export const sGetAuth = (state: RootState) => state.auth;

export const sGetBuyerIdentifierType = (state: RootState) => state.auth.buyer.identifierType;

export const sGetLatestPurchaseOrCreatedAddress = createSelector(sGetBuyerAddresses, addresses => {
  return find(addresses, (o: AddressDto) => o.latestPurchaseOrCreated);
});
export const sGetLatestCreatedAddress = createSelector(sGetBuyerAddresses, addresses => {
  return find(reverse(addresses), (o: AddressDto) => o?.type !== BuyerAddressType.PROFILE);
});

export const sGetIsCheckoutAsGuest = (state: RootState) => state.auth.isCheckoutAsGuest;
