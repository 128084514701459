export enum EEvent {
  PageView = 'PageView',
  ViewContent = 'ViewContent',
  ViewContent_ClickedProduct = 'ViewContent_ClickedProduct',
  ViewContent_ClickedProductImage = 'ViewContent_ClickedProductImage',
  ViewContent_ProductImpression = 'ViewContent_ProductImpression',
  ViewContent_UniqueProductImpression = 'ViewContent_UniqueProductImpression',
  ViewContent_ClickedCategoryImage = 'ViewContent_ClickedCategoryImage',
  ViewContent_CategoryImpression = 'ViewContent_CategoryImpression',
  ViewContent_Scroll25 = 'ViewContent_Scroll25',
  ViewContent_Scroll50 = 'ViewContent_Scroll50',
  ViewContent_Scroll75 = 'ViewContent_Scroll75',
  ViewContent_Scroll100 = 'ViewContent_Scroll100',
  PDP_AddToCart = 'PDP_AddToCart',
  PDP_BuyNow = 'PDP_BuyNow',
  Cart_Discount = 'Cart_Discount',
  Cart_DeleteItem = 'Cart_DeleteItem',
  Cart_DeleteItem_Cancel = 'Cart_DeleteItem_Cancel',
  Cart_DeleteItem_Confirm = 'CartDeleteItem_Confirm',
  Cart_DeliveryAddress = 'Cart_DeliveryAddress',
  CV_CheckOutLine = 'CV_CheckOutLine',
  CV_CheckOutPhone = 'CV_CheckOutPhone',
  OrderFlow_OrderPlaced = 'OrderFlow_OrderPlaced',
  Hamburger = 'Hamburger',
  Hamburger_Home = 'Hamburger_Home',
  Hamburger_Categories = 'Hamburger_Categories',
  Hamburger_StoreInformation = 'Hamburger_StoreInformation',
  Hamburger_LoginRegister = 'Hamburger_LoginRegister',
  OrderPlaced_EnableNotification = 'OrderPlaced_EnableNotification',
  OrderPlaced_ViewOrderDetails = 'OrderPlaced_ViewOrderDetails',
  OrderDetails_Call = 'OrderDetails_Call',
  OrderDetails_Message = 'OrderDetails_Message',
  OrderDetails_VisitStoreWebsite = 'OrderDetails_VisitStoreWebsite',
  OrderDetails_VisitCourierWebsite = 'OrderDetails_VisitCourierWebsite',
  OrderDetails_SubmitProofOfPayment = 'OrderDetails_SubmitProofOfPayment',
  OrderDetails_PaymentConfirmed = 'OrderDetails_PaymentConfirmed',
  OrderDetails_OrderConfirmed = 'OrderDetails_OrderConfirmed',
  Entered_ecom_mode = 'Entered_ecom_mode',
  Entered_food_mode = 'Entered_food_mode',
  Active_user = 'Active_user',
  PaymentsEnabled_Checkout_Shipping_Continue = 'PaymentsEnabled_Checkout_Shipping_Continue',
  PaymentsEnabled_Checkout_Payments_Continue = 'PaymentsEnabled_Checkout_Payments_Continue',
  PaymentsEnabled_Checkout_Confirm_PlaceOrder = 'PaymentsEnabled_Checkout_Confirm_PlaceOrder',
  PaymentsEnabled_Checkout_ProceedToCheckout = 'PaymentsEnabled_Checkout_ProceedToCheckout',
  PaymentsEnabled_Checkout_CourierDelivery = 'PaymentsEnabled_Checkout_CourierDelivery',
  PaymentsEnabled_Checkout_SelfPickup = 'PaymentsEnabled_Checkout_SelfPickup',
  PaymentsEnabled_Checkout_AddNewProfile = 'PaymentsEnabled_Checkout_AddNewProfile',
  PaymentsEnabled_Checkout_AddNewAddress = 'PaymentsEnabled_Checkout_AddNewAddress',
  PaymentsEnabled_Checkout_FindAddress = 'PaymentsEnabled_Checkout_FindAddress',
  PaymentsEnabled_Checkout_CancelFindAddress = 'PaymentsEnabled_Checkout_CancelFindAddress',
  PaymentsEnabled_Checkout_ConfirmFindAddress = 'PaymentsEnabled_Checkout_ConfirmFindAddress',
  PaymentsEnabled_Checkout_Fail_Gotit = 'PaymentsEnabled_Checkout_Fail_Gotit',
  PaymentsEnabled_Checkout_Fail_TryAgain = 'PaymentsEnabled_Checkout_Fail_TryAgain',
  PaymentsEnabled_Checkout_Success_ReturnToHomepage = 'PaymentsEnabled_Checkout_Success_ReturnToHomepage',
  PaymentsEnabled_Checkout_Success_ViewOrderDetail = 'PaymentsEnabled_Checkout_Success_ViewOrderDetail',
  Error_404 = 'Error_404',
  OrderDetails_RegisterNow = 'OrderDetails_RegisterNow',
  Checkout_Checkout_as_guest = 'Checkout_Checkout_as_guest',
  Checkout_SendOTP = 'Checkout_SendOTP',
  Checkout_Login_With_Line = 'Checkout_Login_With_Line',
  BuyerSession = 'BuyerSession',
}

export enum EPageType {
  Home = 'Home',
  Product = 'Product',
  Category = 'Category',
  Store_Information = 'Store Information',
  Checkout = 'Checkout',
  Profile = 'Profile',
  Promotions = 'Promotions',
  Order_Details = 'Order Details',
}

export enum EStoreMode {
  FNB = 'Food & Beverage',
  ECOM = 'E-commerce',
}

export interface ITrackSellerInformation {
  seller_id: string;
  seller_name: string;
}

export interface ITrackProductInformation extends ITrackSellerInformation {
  product_id: string;
  product_name: string;
}

export interface ITrackProductCartInformation extends ITrackProductInformation {
  quantity: number;
  value: number;
}

export interface ITrackProductSaleInformation {
  product_id: string;
  product_price: number;
  quantity: number;
}

export interface ITrackCategoryInformation {
  category_id: string;
  category_name: string;
}

export interface ITrackPageView {
  page_type: EPageType;
  page_url: string;
  store_mode: EStoreMode;
}

export interface ITrackViewContent extends ITrackSellerInformation, ITrackCategoryInformation {
  product_id?: string;
  product_name?: string;
}

export interface ITrackCartDiscount {
  discount_id: string;
}
export interface ITrackActiveUser {
  store_id: string;
  store_slug: string;
}

export interface ITrackOrderFlowOrderPlaced extends ITrackSellerInformation {
  buyer_id: string;
  order_id: string;
  order_subtotal_value: number;
  shipping_fees_value: number;
  free_shipping_discount_value: number;
  discount_value: number;
  order_value: number;
  products: ITrackProductSaleInformation[];
}

export interface ITrackHamburgerCategories extends ITrackSellerInformation {
  categories: string[];
}

export interface ITrackEnableNotification {
  enable_notification: 'Yes' | 'No';
  notification_channel: string;
}

export interface ITrackPayment {
  payment_method?: string;
  bank_name?: string;
}

export interface ITrackPlaceOrder extends ITrackPayment, ITrackOrderFlowOrderPlaced {
  cart_id: string;
  delivery_option: string;
}

export interface ITrackAddress {
  postal_code?: string;
  detailed_address?: string;
  country?: string;
  province?: string;
  district?: string;
  sub_district?: string;
  region?: string;
  barangay?: string;
  lat?: string;
  lng?: string;
}

export interface ITrackErrorPopUp {
  error_message?: string;
  error_log?: string;
}

export enum EAnalyticsEventType {
  CLEVERTAP = 'clevertap',
}
export interface IAnalyticsEvent {
  event: string;
  type: EAnalyticsEventType;
  userId?: string;
  options?: Record<string, unknown>;
}
