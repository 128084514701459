import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getGeoLocation } from 'redux/repository';
import { requestGetShippingDistance } from 'redux/store/StoreRepository';
import { sGetStoreDeliveryRadius } from 'redux/store/StoreSelectors';
import { ELocationToastType, IUpdateDeliveryOptionParams } from 'types';
import { getLocationToastForm } from 'utils/map';
import {
  AppStateActions,
  IAppStateGeneralAction,
  setLocationToastState,
  toggleIsLocationOnMapOutOfRange,
} from './AppStateActions';

function* setLocaleAction() {
  const {
    data: { countryCode: code, country },
  } = yield call(getGeoLocation);
  yield put({
    type: AppStateActions.SET_LOCALE,
    data: {
      code,
      country,
    },
  });
}

function* watchSetLocale() {
  yield takeEvery(AppStateActions.SET_LOCALE_ASYNC, setLocaleAction);
}

function* getLocationOnMapDistanceAsync(action: IAppStateGeneralAction<IUpdateDeliveryOptionParams>) {
  try {
    yield call(requestGetShippingDistance, action.data);
    yield put(toggleIsLocationOnMapOutOfRange(false));
    yield put(setLocationToastState({ isOpen: false }));
  } catch (error) {
    const storeDeliveryRadius = yield select(sGetStoreDeliveryRadius);
    yield put(toggleIsLocationOnMapOutOfRange(true));
    yield put(
      setLocationToastState(getLocationToastForm(true, ELocationToastType.ERROR_OUT_OF_RANGE, storeDeliveryRadius)),
    );
  }
}

function* watchGetLocationOnMapDistanceAsync() {
  yield takeEvery(AppStateActions.GET_LOCATION_ON_MAP_DISTANCE_ASYNC, getLocationOnMapDistanceAsync);
}
function* appStateSaga() {
  yield all([watchSetLocale(), watchGetLocationOnMapDistanceAsync()]);
}

export default appStateSaga;
