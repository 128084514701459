/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    ybug_settings: any;
  }
}
export function initYbugIO() {
  if (typeof window === 'undefined' && !process.env.NEXT_PUBLIC_YBUG_API_KEY) return;
  window.ybug_settings = { id: process.env.NEXT_PUBLIC_YBUG_API_KEY };
  const ybug = document.createElement('script');
  ybug.type = 'text/javascript';
  ybug.async = true;
  ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(ybug, s);
}
