import { Category, Product } from 'types';

export enum DiscountType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum DiscountRuleType {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
  FREE_SHIP = 'free_ship',
  BUY_X_GET_Y = 'buy_x_get_y',
}

export interface DiscountApplicability {
  allProductEnabled: boolean;
  categoryIds: string[];
  productIds: string[];
  categories?: Category[];
  products?: Product[];
}

export interface DiscountUsageLimit {
  oneUsePerCustomerEnabled: boolean;
  totalUseLimit?: number;
}

export interface Discount {
  id: string;
  storeId: string;
  code: string;
  details: string;
  slug?: string;
  enabled: boolean;
  type: DiscountType;
  ruleType: DiscountRuleType;
  useCount: number;
  validFrom: Date;
  validThru: Date;
  applicability: DiscountApplicability;
  eligibility: DiscountEligibility | null;
  minQuantity: number;
  minPurchaseAmt: number;
  usageLimit?: DiscountUsageLimit;
  rule: DiscountRule;
  createdAt: string;
}

export interface BuyXGetYDiscountRule {
  xMinQuantity: number;
  xMinPurchase: number;
  xProductIds: string[];
  yProductIds: string[];
  yQuantity: number;
  yPercentageOff: number;
  type: DiscountRuleType;
  xCategoryIds: string[];
  yCategoryIds: string[];
}

export interface FixedAmountDiscountRule {
  amount: number;
  type: DiscountRuleType;
  xProductIds: string[];
  yProductIds: string[];
  xCategoryIds: string[];
  yCategoryIds: string[];
}

export interface PercentageDiscountRule {
  percentage: number;
  type: DiscountRuleType;
  xProductIds: string[];
  yProductIds: string[];
  xCategoryIds: string[];
  yCategoryIds: string[];
}

export type DiscountRule = BuyXGetYDiscountRule | FixedAmountDiscountRule | PercentageDiscountRule;

export interface DiscountEligibility {
  everyOneEnabled: boolean;
  customerIds: string[];
}

export interface DiscountFilterInputParams {
  searchText?: string;
  type?: DiscountType;
}
export interface DiscountAppliedResult {
  id?: string;
  code: string;
  type: DiscountType;
  ruleType: DiscountRuleType;
  discountedAmount: number;
  applyCount?: number;
}
