import axios from 'axios';
import { logError } from 'utils/error';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

export const requestUpdateVisitorCount = async (storeId: string) => {
  if (storeId) {
    return axios.put(`${API_STORE}/api/store/buyer/store/${storeId}/visitor`).catch(err => logError(err));
  }
};
