/* eslint-disable no-console */
import { AmplitudeClient } from 'amplitude-js';
import { AnalyticsInstance } from 'analytics';
import { isNil } from 'lodash';
import { EEvent } from 'types/analytics';
import { logError } from 'utils/error';
import { DEFER_LOADING_ANALYTICS_TIME_MILLIS } from '../constants';

let amplitude: AmplitudeClient;
let analytics: AnalyticsInstance;
let ReactPixel;
let hotjar;
const isProd = process.env.NODE_ENV === 'production';
// Importing packages like this because it relies on the "window" object and fails on server side
export const initAnalytics = async () => {
  if (typeof window === 'undefined') return;

  // Init amplitude
  if (isNil(amplitude)) {
    await tryAndLogIfFail(async () => {
      // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
      const amplitudeJs = (await import('amplitude-js')).default;
      amplitude = amplitudeJs.getInstance();
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
    });
  }

  if (isNil(analytics) && process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID) {
    await tryAndLogIfFail(async () => {
      // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
      analytics = (await import('analytics')).default({
        app: 'zaapi-buyer-web',
        plugins: [
          // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
          (await import('@analytics/google-analytics')).default({
            trackingId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
          }),
        ],
      }) as AnalyticsInstance;
    });
  }

  if (isNil(ReactPixel)) {
    await tryAndLogIfFail(async () => {
      // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
      ReactPixel = (await import('react-facebook-pixel')).default;
      ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, null, { options: true });
    });
  }

  if (isNil(hotjar)) {
    await tryAndLogIfFail(async () => {
      // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
      hotjar = (await import('react-hotjar')).hotjar;
      hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
    });
  }
};

const initAndRunAction = async (action: () => Promise<void>) => {
  if (isNil(analytics) || isNil(amplitude) || isNil(ReactPixel) || isNil(hotjar)) {
    setTimeout(async () => {
      await initAnalytics();
      await action();
    }, DEFER_LOADING_ANALYTICS_TIME_MILLIS);
  } else {
    await action();
  }
};

// Base tracking function
export const trackEvent = async <T>(event: EEvent, options?: T) => {
  await initAndRunAction(async () => {
    tryAndLogIfFailSync(() => amplitude?.logEvent?.(event, options));
    if (isProd) {
      await tryAndLogIfFail(() => analytics?.track?.(event, options));
      await tryAndLogIfFail(() => ReactPixel?.trackCustom?.(event, options));
    }
  });
};

export const identifyUser = async (userId: string) => {
  await initAndRunAction(async () => {
    tryAndLogIfFailSync(() => amplitude.setUserId(userId));
    if (isProd) {
      await tryAndLogIfFail(() => analytics?.identify?.(userId));
      await tryAndLogIfFail(() => hotjar?.identify?.(userId));
    }
  });
};

const tryAndLogIfFail = async (action: () => Promise<void>) => {
  try {
    await action();
  } catch (error) {
    logError(error);
  }
};

const tryAndLogIfFailSync = (action: () => void) => {
  try {
    action();
  } catch (error) {
    logError(error);
  }
};

export * from './mailchimp';

// Event functions
export * from './events/cart.events';
export * from './events/menu.events';
export * from './events/order.events';
export * from './events/product.events';
export * from './events/view.events';
export * from './events/payment.events';
export * from './events/misc.events';
