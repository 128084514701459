/* eslint-disable @typescript-eslint/no-explicit-any */
import { Discount, DiscountFilterInputParams } from '../../types/discount';
import cloneDeep from 'lodash/cloneDeep';
import { DiscountActions, IDiscountGeneralAction } from './DiscountActions';

export interface DiscountReduxState {
  discounts: Discount[];
  displayedDiscounts: Discount[];
  selectedDiscount?: Discount;
  discountToCreate: Partial<Discount>;
  displayFilter: DiscountFilterInputParams;
  SelectedDiscountCode?: '';
  selectedDiscountId?: [];
  discountErrorMessage: string;
}

export const DEFAULT_DISCOUNT_STATE: DiscountReduxState = {
  discounts: [],
  displayedDiscounts: [],
  discountToCreate: {},
  displayFilter: {},
  SelectedDiscountCode: '',
  selectedDiscountId: [],
  discountErrorMessage: '',
};

/* eslint-disable max-lines, max-lines-per-function */
const DiscountReducer = (state = DEFAULT_DISCOUNT_STATE, action: IDiscountGeneralAction<any>): DiscountReduxState => {
  switch (action.type) {
    case DiscountActions.SET_DISCOUNTS_DATA:
      return {
        ...state,
        discounts: action.data as Discount[],
      };

    case DiscountActions.SET_DISPLAYED_DISCOUNTS_DATA:
      return {
        ...state,
        displayedDiscounts: action.data as Discount[],
      };

    case DiscountActions.CLEAR_DISCOUNT_DATA:
      return DEFAULT_DISCOUNT_STATE;

    case DiscountActions.DELETE_DISCOUNT:
      return {
        ...state,
        displayedDiscounts: state.displayedDiscounts.filter(discount => discount.id !== action.data.id),
      };

    case DiscountActions.CREATE_DISCOUNT:
      return {
        ...state,
        displayedDiscounts: [action.data, ...state.displayedDiscounts],
      };

    case DiscountActions.SET_SELECTED_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.data,
      };

    case DiscountActions.UPDATE_DISCOUNT: {
      const index = state.displayedDiscounts.findIndex(discount => discount.id === action.data.id);

      if (index > -1) {
        const displayedDiscounts = cloneDeep(state.displayedDiscounts);
        displayedDiscounts[index] = {
          ...state.displayedDiscounts[index],
          ...action.data,
        };

        return {
          ...state,
          displayedDiscounts,
        };
      }
      return state;
    }

    case DiscountActions.SET_DISCOUNT_DISPLAY_FILTER:
      return {
        ...state,
        displayFilter: action.data,
      };

    case DiscountActions.UPDATE_DISCOUNT_TO_CREATE:
      return {
        ...state,
        discountToCreate: {
          ...state.discountToCreate,
          ...action.data,
        },
      };

    case DiscountActions.SET_DISCOUNT_TO_CREATE:
      return {
        ...state,
        discountToCreate: action.data,
      };

    case DiscountActions.SET_DISCOUNT_FROM_SELLER:
      return {
        ...state,
        SelectedDiscountCode: action.data,
      };

    case DiscountActions.SET_DISCOUNT_FROM_CARTID:
      return {
        ...state,
        selectedDiscountId: action.data,
      };

    case DiscountActions.SET_DISCOUNT_ERROR_MESSAGE:
      return {
        ...state,
        discountErrorMessage: action.data,
      };

    default:
      return state;
  }
};

export default DiscountReducer;
