import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { DataStorageKeys } from 'types';
import { District, Province, SubDistrict } from 'types/adminDivision';
import { logError } from '../utils/error';
import { DataStorage } from '../utils/storage';
import { requestRefreshToken } from './auth/AuthRepository';

let bearerTokenInterceptorId = 0;

export const tokenTemporaryStorage = {
  accessToken: '',
  authProvider: '',
};

export const getInterceptorAccessToken = () => tokenTemporaryStorage.accessToken;

export const getInterceptorAuthProvider = () => tokenTemporaryStorage.authProvider;

const setupBearerTokenInterceptor = async () => {
  bearerTokenInterceptorId = axios.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${getInterceptorAccessToken()}`;
      config.headers['x-zp-auth-provider'] = getInterceptorAuthProvider();
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

const refreshAuthLogic = async failedRequest => {
  try {
    const { data } = await requestRefreshToken();
    await DataStorage.save({ key: 'access_token', data: data.access_token });
    await DataStorage.save({ key: 'refresh_token', data: data.refresh_token });
    tokenTemporaryStorage.accessToken = data.access_token;
    await setupBearerTokenInterceptor();
    failedRequest.response.config.headers['Authorization'] = `Bearer ${data.access_token}`;
    return;
  } catch (error) {
    logError(error);
    await DataStorage.remove({ key: DataStorageKeys.auth_provider });
    await DataStorage.remove({ key: 'access_token' });
    await DataStorage.remove({ key: 'refresh_token' });
  }
};

export const setUpRequestInterceptor = async () => {
  const access_token = await DataStorage.load({ key: 'access_token' });
  const auth_provider = await DataStorage.load({ key: DataStorageKeys.auth_provider });
  try {
    tokenTemporaryStorage.accessToken = JSON.parse(access_token);
    if (auth_provider) tokenTemporaryStorage.authProvider = auth_provider;
  } catch (error) {
    tokenTemporaryStorage.accessToken = access_token;
  }

  await setupBearerTokenInterceptor();
  createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    statusCodes: [401],
  });
};

export const clearRequestInterceptor = async () => {
  if (bearerTokenInterceptorId !== 0) {
    axios.interceptors.request.eject(bearerTokenInterceptorId);
    bearerTokenInterceptorId = 0;
  }
};

const API_AUTHORIZATION = process.env.NEXT_PUBLIC_API_STORE;

export const getGeoLocation = () => {
  return axios.get(`${API_AUTHORIZATION}/api/store/buyer/geo-location`);
};

export const getProvince = (country: string, region?: string) => {
  return axios
    .get<Province[]>(`${API_AUTHORIZATION}/api/store/buyer/administrative-division/province?country=${country}`, {
      params: { regionId: region },
    })
    .then(res => res.data);
};

export const getDistrict = (provinceId?: string) => {
  return axios
    .get<District[]>(`${API_AUTHORIZATION}/api/store/buyer/administrative-division/district?provinceId=${provinceId}`)
    .then(res => res.data);
};
export const getSubDistrict = (districtId?: string) => {
  return axios
    .get<SubDistrict[]>(
      `${API_AUTHORIZATION}/api/store/buyer/administrative-division/subdistrict?districtId=${districtId}`,
    )
    .then(res => res.data);
};
export const getRegionOfPhilippines = () => {
  return axios
    .get(`${API_AUTHORIZATION}/api/store/buyer/administrative-division/region?country=Philippines`)
    .then(res => res.data);
};
export const getTownByDistrict = (districtId?: string) => {
  return axios
    .get(`${API_AUTHORIZATION}/api/store/buyer/administrative-division/town?districtId=${districtId}`)
    .then(res => res.data);
};
export const getPostalCodeByCountry = (country: string, districtId: string) => {
  return axios
    .get(
      `${API_AUTHORIZATION}/api/store/buyer/administrative-division/postal-code?country=${country}&districtId=${districtId}`,
    )
    .then(res => res.data);
};
