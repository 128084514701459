import { useSelector } from 'react-redux';
import { sGetProductSetType } from 'redux/store/StoreSelectors';
import { EProductSetType } from 'types';

const useIsFnB = (): boolean => {
  const productSetType = useSelector(sGetProductSetType);

  return productSetType === EProductSetType.FNB;
};

export default useIsFnB;
