export interface BuyerInfo {
  address?: BuyerAddress | undefined;
  allowLineOrderNotification?: boolean;
  noteForDriver?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  fullName?: string | null;
  lineUserId?: string | null;
  userId?: string;
  identifier?: string;
  identifierType?: IdentifierType;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
  expressedPreference?: string;
  name?: string;
  addresses?: AddressDto[];
}
export interface AddressDto {
  fullName?: string;
  phoneNumber?: string | null;
  isDefault?: boolean;
  detailedAddress?: string;
  country?: string;
  region?: string;
  province?: string;
  district?: string;
  barangay?: string;
  postalCode?: string;
  email?: string;
  noteForDriver?: string;
  allowLineOrderNotification?: boolean;
  id?: string;
  subDistrict?: string;
  detailedAddress2?: string;
  lat?: string;
  lng?: string;
  type?: BuyerAddressType;
  latestPurchaseOrCreated?: boolean;
}
export enum IdentifierType {
  PHONE_NUMBER = 'phone_number',
  LINE = 'line',
}

export interface BuyerAddress {
  detailedAddress?: string;
  country?: string;
  region?: string;
  province?: string;
  district?: string;
  subDistrict?: string;
  barangay?: string;
  postalCode?: string;
  buyerAddrId?: string;
  detailedAddress2?: string;
  lat?: string;
  lng?: string;
}
export enum AddressBookScreenHeader {
  ADD_NEW_ADDRESS = 'Add New Address',
  EDIT_ADDRESS = 'Edit Address',
  ADDRESS_BOOK = 'Address Book',
  ADD_NEW_PROFILE = 'Add details',
}
export enum BuyerAddressType {
  ADDRESS = 'address',
  PROFILE = 'profile',
}
export enum EAuthProvider {
  line = 'line',
  auth0 = 'auth0',
}

export interface IAuthLoginOptionParams {
  isDisableRedirect?: boolean;
  redirectUrl?: string;
}
