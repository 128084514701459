/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaymentStatusResponseDto } from 'types';
import { EOrderTab, IOrder, IUpdateManualOrderCustomerDetails } from '../../types/order';

export enum OrderActions {
  FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS',
  FETCH_ALL_ORDERS_ASYNC = 'FETCH_ALL_ORDERS_ASYNC',
  STORE_ORDER = 'STORE_ORDER',
  FETCH_ORDER = 'FETCH_ORDER',
  FETCH_ORDER_ASYNC = 'FETCH_ORDER_ASYNC',
  FETCH_MANUAL_ORDER_ASYNC = 'FETCH_MANUAL_ORDER_ASYNC',
  UPDATE_MANUAL_ORDER_CUSTOMER_SHIPPING_ASYNC = 'UPDATE_MANUAL_ORDER_CUSTOMER_SHIPPING_ASYNC',

  GET_PAYMENT_STATUS_RESPONSE_ASYNC = 'GET_PAYMENT_STATUS_RESPONSE_ASYNC',
  SET_PAYMENT_STATUS_RESPONSE = 'SET_PAYMENT_STATUS_RESPONSE',
  UPDATE_SUMMARY_CART_FROM_ORDER_ACTION = 'UPDATE_SUMMARY_CART_FROM_ORDER_ACTION',

  COMPLETE_ORDER_PAYMENT_FLOW_ASYNC_ACTION = 'COMPLETE_ORDER_PAYMENT_FLOW_ASYNC_ACTION',
}
export interface IUpdateOrderAction {
  type: OrderActions;
  data: any;
}
export interface IOrderGeneralAction<T> {
  type: OrderActions;
  data: T;
}
export const fetchAllOrdersByUser = (orderTab?: EOrderTab) => {
  return {
    type: OrderActions.FETCH_ALL_ORDERS_ASYNC,
    data: orderTab,
  };
};
export const fetchOrderById = orderId => {
  return {
    type: OrderActions.FETCH_ORDER_ASYNC,
    data: orderId,
  };
};
export const storeOrderById = (order: IOrder) => {
  return {
    type: OrderActions.STORE_ORDER,
    data: order,
  };
};
export const getPaymentStatusResponseAsync = (id: string, isCartPaymentStatus = false) => {
  return {
    type: OrderActions.GET_PAYMENT_STATUS_RESPONSE_ASYNC,
    data: { id, isCartPaymentStatus },
  };
};
export const setPaymentStatusResponse = (data: PaymentStatusResponseDto) => {
  return { type: OrderActions.SET_PAYMENT_STATUS_RESPONSE, data };
};

export const fetchManualOrderByIdAsync = (orderId: string) => {
  return { type: OrderActions.FETCH_MANUAL_ORDER_ASYNC, data: orderId };
};
export const updateManualOrderCustomerDetailAsync = (data: IUpdateManualOrderCustomerDetails) => {
  return { type: OrderActions.UPDATE_MANUAL_ORDER_CUSTOMER_SHIPPING_ASYNC, data };
};
export const updateSummaryCartFromOrder = (data: IOrder) => {
  return { type: OrderActions.UPDATE_SUMMARY_CART_FROM_ORDER_ACTION, data };
};
export const completeOrderPaymentFlowAsyncAction = (order: IOrder) => {
  return { type: OrderActions.COMPLETE_ORDER_PAYMENT_FLOW_ASYNC_ACTION, data: order };
};
