/* eslint-disable @typescript-eslint/consistent-type-assertions */
import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { DataStorage } from 'utils/storage';
import { BuyerInfo } from 'types/auth';
import { DataStorageKeys } from 'types';
import { getRequestCode } from '@zaapi/zapkey';
import { browserName } from 'react-device-detect';
const API_AUTHORIZATION = process.env.NEXT_PUBLIC_API_STORE;

const authAxios = axios.create();

const getConfigWithRandomToken = (phoneNumber: string) => {
  const screen = `${window.screen.width}x${window.screen.height}`;
  const reqCode = getRequestCode(screen, browserName, phoneNumber);
  return { headers: { Authorization: 'Bearer ' + reqCode } } as AxiosAuthRefreshRequestConfig;
};
export const requestSendOTP = async (phoneNumber: string) => {
  const axiosConfig = getConfigWithRandomToken(phoneNumber);
  return authAxios.post(
    `${API_AUTHORIZATION}/api/store/buyer/otp/request`,
    {
      phoneNumber,
      namespace: 'zaapi-buyers',
    },
    axiosConfig,
  );
};

export const requestVerifyOTP = async (phoneNumber: string, OTPCode: string, storeId: string) => {
  const axiosConfig = getConfigWithRandomToken(phoneNumber);
  const cartId = (await DataStorage.load({ key: 'cartId' })) || null;
  return authAxios.post<{
    access_token: string;
    refresh_token: string;
  }>(
    `${API_AUTHORIZATION}/api/store/auth/otp/verify`,
    {
      phoneNumber,
      otp: OTPCode,
      namespace: 'zaapi-buyers',
      storeId: storeId,
      cartId: cartId || null,
    },
    axiosConfig,
  );
};

export const requestRefreshToken = async () => {
  const refreshToken = await DataStorage.load({ key: 'refresh_token' });
  const accessToken = await DataStorage.load({ key: 'access_token' });
  const authProvider = await DataStorage.load({ key: DataStorageKeys.auth_provider });
  if (!refreshToken || !accessToken) throw new Error('Refresh token or access token not found!');
  const requestConfig: AxiosAuthRefreshRequestConfig = {
    skipAuthRefresh: true,
    headers: {
      Authorization: `Bearer ${JSON.parse(accessToken)}`,
      'x-zp-auth-provider': authProvider,
    },
  };
  return authAxios.put(
    `${API_AUTHORIZATION}/api/store/auth/refresh`,
    {
      refreshToken: JSON.parse(refreshToken),
    },
    requestConfig,
  );
};

export const requestLogout = async () => {
  const KEYS_TO_REMOVE_AFTER_LOGOUT = [
    DataStorageKeys.refresh_token,
    DataStorageKeys.access_token,
    DataStorageKeys.user_info,
    DataStorageKeys.lineUserProfile,
    DataStorageKeys.id_token,
    DataStorageKeys.auth_provider,
  ];
  const refreshToken = await DataStorage.load({ key: 'refreshToken' });

  await Promise.all(KEYS_TO_REMOVE_AFTER_LOGOUT.map(key => DataStorage.remove({ key })));

  if (refreshToken) {
    axios.post(`${API_AUTHORIZATION}/api/store/auth/logout`, {
      refreshToken,
    });
  }
  return;
};

export const requestGetMe = (storeId?: string) => {
  return axios.get(`${API_AUTHORIZATION}/api/store/buyer-info`, { params: { storeId: storeId } }).then(res => res.data);
};

export const requestPutMe = (data: BuyerInfo, storeId: string) => {
  return axios
    .put(`${API_AUTHORIZATION}/api/store/buyer-info`, { ...data }, { params: { storeId: storeId } })
    .then(res => res.data);
};

export const requestLineLogin = async (storeId: string, buyerLineUserName?: string) => {
  const cartId = (await DataStorage.load({ key: 'cartId' })) || null;
  return axios
    .post(`${API_AUTHORIZATION}/api/store/auth/line/login`, {
      storeId: storeId,
      namespace: process.env.NEXT_PUBLIC_AUTH0_BUYER_AUDIENCE,
      cartId: cartId || null,
      buyerLineUserName,
    })
    .then(res => res.data);
};
