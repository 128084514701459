import { COUNTRY_FLAG_URI } from './constants';

export const getCurrentDomainAndProtocol = () => {
  return window.location.href.split('/').slice(0, 3).join('/');
};

export const getCountryFlagUrl = (countryCode: string) => `${COUNTRY_FLAG_URI}/${countryCode.toLowerCase()}.png`;

export const getAuthCallbackUrl = () => {
  return `${getCurrentDomainAndProtocol()}/auth/callback`;
};
