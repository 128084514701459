import {
  CartCheckoutDto,
  ICheckoutPayload,
  IGBPayGetQR,
  PaymentMethod,
  PaymentMethodResponseType,
} from 'types/merchant';

export enum IMerchantAction {
  FETCH_MERCHANT_DATA = 'FETCH_MERCHANT_DATA',
  FETCH_MERCHANT_DATA_ASYNC = 'FETCH_MERCHANT_DATA_ASYNC',
  GET_QR_IMAGE_BY_PAYMENT_ASYNC = 'GET_QR_IMAGE_BY_PAYMENT_ASYNC',
  GET_CHECKOUT_FOR_PAYMENT_ASYNC = 'GET_CHECKOUT_FOR_PAYMENT_ASYNC',
  GET_BARCODE_ASYNC = 'GET_BARCODE_ASYNC',
  GET_DEBIT_CARD_PAYMENT_ASYNC = 'GET_DEBIT_CARD_PAYMENT_ASYNC',
  GET_TRUE_WALLET_ASYNC = 'GET_TRUE_WALLET_ASYNC',
  GET_MOBILE_BANKING_ASYNC = 'GET_MOBILE_BANKING_ASYNC',
  GET_CALL_API_GB_PAY_ASYNC = 'GET_CALL_API_GB_PAY_ASYNC',
  CREATE_PAYMENT_FROM_ORDER = 'CREATE_PAYMENT_FROM_ORDER',
}

export interface IMerchantGeneralAction<T> {
  type: IMerchantAction;
  data: T;
}

export const fetchMerchantDataAsyncAction = (merchantId: string) => {
  return {
    type: IMerchantAction.FETCH_MERCHANT_DATA_ASYNC,
    data: merchantId,
  };
};
export const getQrImageForPaymentAsync = (data: IGBPayGetQR, payment: string) => {
  return {
    type: IMerchantAction.GET_QR_IMAGE_BY_PAYMENT_ASYNC,
    data: { data, payment },
  };
};
export const getCartCheckoutForPayment = (data: ICheckoutPayload, cartId: string, isPayNow = true) => {
  return {
    type: IMerchantAction.GET_CHECKOUT_FOR_PAYMENT_ASYNC,
    data: { data, cartId, isPayNow },
  };
};
export const getBarcodeFromBillAsync = (data: IGBPayGetQR) => {
  return {
    type: IMerchantAction.GET_BARCODE_ASYNC,
    data: data,
  };
};
export const getDebitCardPaymentAsync = (order: CartCheckoutDto, merchantKey?: string) => {
  return {
    type: IMerchantAction.GET_DEBIT_CARD_PAYMENT_ASYNC,
    data: { order, merchantKey },
  };
};
export const getTrueWalletAsync = (data: IGBPayGetQR) => {
  return {
    type: IMerchantAction.GET_TRUE_WALLET_ASYNC,
    data: data,
  };
};
export const getMobileBankingAsync = (data: IGBPayGetQR) => {
  return {
    type: IMerchantAction.GET_TRUE_WALLET_ASYNC,
    data,
  };
};
export const getCallAPIFromGB = (
  responseMethod: PaymentMethodResponseType,
  payload: IGBPayGetQR,
  paymentMethod: PaymentMethod,
) => {
  return {
    type: IMerchantAction.GET_CALL_API_GB_PAY_ASYNC,
    data: { responseMethod, payload, paymentMethod },
  };
};
export const createPaymentFromOrder = (data: ICheckoutPayload) => {
  return {
    type: IMerchantAction.CREATE_PAYMENT_FROM_ORDER,
    data,
  };
};
