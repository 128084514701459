import { RootState } from 'redux/reduxStore';

export const sGetStore = (state: RootState) => state.store;

export const sGetStoreSlug = (state: RootState) => state.store.slug;

export const sGetStoreId = (state: RootState) => state.store.id;

export const sGetStoreCurrency = (state: RootState) => state.store.currency;

export const sGetLanguageCode = (state: RootState) => state.store.languageCode;

export const sGetProductSetType = (state: RootState) => state.store.productSetType;

export const sGetStoreDeliveryType = (state: RootState) => state.store.deliveryType?.type;

export const sGetAllowPickupEnabled = (state: RootState) => state.store.allowPickupEnabled;

export const sGetIsBannerRemoved = (state: RootState) => state.store.isBannerRemoved;

export const sGetOnHolidayEnabled = (state: RootState) => state.store.onHolidayEnabled;

export const sGetStoreMerchantId = (state: RootState) => state.store.merchantId;

export const sGetStoreBankAccount = (state: RootState) => state.store?.paymentDetail?.bankAccount;

export const sGetSellerPhoneNumber = (state: RootState) => state.store?.storeContactDetails?.phoneNumber;

export const sGetStoreAddressCountry = (state: RootState) => state.store?.address?.country;

export const sGetStoreAddress = (state: RootState) => state.store?.address;

export const sGetStoreDeliveryRadius = (state: RootState) => state.store.deliveryType?.radius;

export const sGetStoreFacebookLikeCount = (state: RootState) => state?.store?.facebookAccount?.metadata?.likeCount;
export const sGetStoreFacebookFollowerCount = (state: RootState) =>
  state?.store?.facebookAccount?.metadata?.followerCount;
