import { EEvent, ITrackProductCartInformation } from 'types/analytics';
import { mailchimpEventHandler, trackEvent } from '..';

export async function trackPDPAddToCart(options: ITrackProductCartInformation) {
  await mailchimpEventHandler(EEvent.PDP_AddToCart);
  trackEvent(EEvent.PDP_AddToCart, options);
}

export async function trackPDPBuyNow(options: ITrackProductCartInformation) {
  await mailchimpEventHandler(EEvent.PDP_BuyNow);
  trackEvent(EEvent.PDP_BuyNow, options);
}
