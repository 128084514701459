import { EEvent, ITrackEnableNotification, ITrackOrderFlowOrderPlaced } from 'types/analytics';
import { mailchimpEventHandler, trackEvent } from '..';

export function trackCVCheckoutLine() {
  trackEvent(EEvent.CV_CheckOutLine);
}

export function trackCVCheckoutPhone() {
  trackEvent(EEvent.CV_CheckOutPhone);
}

export async function trackOrderFlowOrderPlaced(options: ITrackOrderFlowOrderPlaced) {
  await mailchimpEventHandler(EEvent.OrderFlow_OrderPlaced);
  trackEvent(EEvent.OrderFlow_OrderPlaced, options);
}

export async function trackOrderPlacedEnableNotification(options: ITrackEnableNotification) {
  await mailchimpEventHandler(EEvent.OrderPlaced_EnableNotification);
  trackEvent(EEvent.OrderPlaced_EnableNotification, options);
}

export async function trackOrderPlacedViewOrderDetails() {
  await mailchimpEventHandler(EEvent.OrderPlaced_ViewOrderDetails);
  trackEvent(EEvent.OrderPlaced_ViewOrderDetails);
}

export function trackOrderDetailsCall() {
  trackEvent(EEvent.OrderDetails_Call);
}

export function trackOrderCheckoutAsGuest() {
  trackEvent(EEvent.Checkout_Checkout_as_guest);
}

export function trackOrderCheckoutSendOtp() {
  trackEvent(EEvent.Checkout_SendOTP);
}

export function trackOrderCheckoutLoginWithLine() {
  trackEvent(EEvent.Checkout_Login_With_Line);
}

export function trackOrderDetailsRegisterNow() {
  trackEvent(EEvent.OrderDetails_RegisterNow);
}

export function trackOrderDetailsMessage() {
  trackEvent(EEvent.OrderDetails_Message);
}

export function trackOrderDetailsVisitStoreWebsite() {
  trackEvent(EEvent.OrderDetails_VisitStoreWebsite);
}

export function trackOrderDetailsVisitCourierWebsite() {
  trackEvent(EEvent.OrderDetails_VisitCourierWebsite);
}

export async function trackOrderDetailsSubmitProofOfPayment() {
  await mailchimpEventHandler(EEvent.OrderDetails_SubmitProofOfPayment);
  trackEvent(EEvent.OrderDetails_SubmitProofOfPayment);
}

export async function trackOrderDetailsPaymentConfirmed() {
  await mailchimpEventHandler(EEvent.OrderDetails_PaymentConfirmed);
  trackEvent(EEvent.OrderDetails_PaymentConfirmed);
}

export async function trackOrderDetailsOrderConfirmed() {
  await mailchimpEventHandler(EEvent.OrderDetails_OrderConfirmed);
  trackEvent(EEvent.OrderDetails_OrderConfirmed);
}
