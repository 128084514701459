import { BuyerFormInfo } from 'types';

export enum ErrorCode {
  PRODUCT_INSUFFICIENT_STOCK = 'ZPSTORE0037',
}
export interface IGetFormErrorParams {
  form: BuyerFormInfo;
  checkbox?: boolean;
  postalError?: boolean;
  isDomestic?: boolean;
}
