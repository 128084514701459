import { IUpdateManualOrderCustomerDetails } from './../../types/order';
import axios from 'axios';
import { PaymentStatusResponseDto } from 'types';
import { EOrderTab, IOrder } from '../../types/order';
const API_STORE = process.env.NEXT_PUBLIC_API_STORE;
// const API_STORE = process.env.NEXT_PUBLIC_REACT_APP_API_STORE;

export const requestGetOrders = async (orderTab?: EOrderTab, storeId?: string) => {
  return axios
    .get<IOrder[]>(`${API_STORE}/api/store/buyer-order`, { params: { orderTab, storeId } })
    .then(res => res.data);
};

export const requestGetOrderById = async (orderId: string) => {
  return axios.get<IOrder>(`${API_STORE}/api/store/buyer-order/${orderId}`).then(res => {
    return res.data;
  });
};
export const requestGetOrderPaymentStatus = async (orderId: string) => {
  return axios
    .get<PaymentStatusResponseDto>(`${API_STORE}/api/store/buyer-order/${orderId}/payment-status`)
    .then(res => {
      return res.data;
    });
};
export const requestUpdateManualCustomerShippingAddress = async (
  storeId: string,
  orderId: string,
  data: IUpdateManualOrderCustomerDetails,
) => {
  return axios
    .put<IOrder>(`${API_STORE}/api/store/buyer/${storeId}/manual-order/${orderId}/shipping-address`, data)
    .then(res => res.data);
};
