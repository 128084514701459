// import { BuyerInfo } from 'types/auth';

import { IFetchCartDetailByIdParams, IUpdateDeliveryOptionParams, IUpdateDiscountParams } from 'types';
import { cardValidateForm, SavedMethod } from 'types/merchant';
import { CartReduxState } from './CartReducer';

export enum CartAction {
  CREATE = 'CREATE',
  UPDATE_CART = 'UPDATE_CART',
  UPDATE_CART_ASYNC = 'UPDATE_CART_ASYNC',
  GET_ALL_PRODUCTS_IN_CART = 'GET_ALL_PRODUCTS_IN_CART',
  GET_ALL_PRODUCTS_IN_CART_ASYNC = 'GET_ALL_PRODUCTS_IN_CART_ASYNC',
  CART_CHECKOUT = 'CART_CHECKOUT',
  CART_CHECKOUT_ASYNC = 'CART_CHECKOUT_ASYNC',
  GET_SHIPPING_FEE = 'GET_SHIPPING_FEE',
  GET_SHIPPING_FEE_ASYNC = 'GET_SHIPPING_FEE_ASYNC',
  SET_ERROR_POSTAL_CODE = 'SET_ERROR_POSTAL_CODE',
  APPLY_DISCOUNT = 'APPLY_DISCOUNT',
  APPLY_DISCOUNT_ASYNC = 'APPLY_DISCOUNT_ASYNC',
  SET_IS_CHECKOUT_SUCCESS = 'SET_IS_CHECKOUT_SUCCESS',
  REMOVE_DISCOUNT_ASYNC = 'REMOVE_DISCOUNT_ASYNC',
  GET_CART_FROM_LOGIN = 'GET_CART_FROM_LOGIN',
  GET_CART_DETAIL = 'GET_CART_DETAIL',
  SET_IS_SELF_PICKUP = 'SET_IS_SELF_PICKUP',
  GET_FINAL_TOTAL_PRICE = 'GET_FINAL_TOTAL_PRICE',
  GET_TOTAL_PRICE_SELF_PICKUP_ASYNC = 'GET_TOTAL_PRICE_SELF_PICKUP_ASYNC',
  RESET_CART_ACTION = 'RESET_CART_ACTION',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',

  SET_CARD_VALIDATE_FORM = 'SET_CARD_VALIDATE_FORM',

  SET_ERROR_REMARKS = 'SET_ERROR_REMARKS',
  REFRESH_CART_DETAIL_ASYNC = 'REFRESH_CART_DETAIL_ASYNC',
  UPDATE_CART_DELIVERY_OPTION_ASYNC = 'UPDATE_CART_DELIVERY_OPTION_ASYNC',
  FETCH_CART_BY_ID_ASYNC_ACTION = 'FETCH_CART_BY_ID_ASYNC_ACTION',
}

export interface CartGeneralAction<T> {
  type: CartAction;
  data: T;
}

export const buyerCreateOrUpdateCart = data => {
  return {
    type: CartAction.UPDATE_CART_ASYNC,
    data,
  };
};
export const getAllCartWhenLogIn = data => {
  return {
    type: CartAction.GET_ALL_PRODUCTS_IN_CART,
    data,
  };
};
export const cartCheckout = data => {
  return {
    type: CartAction.CART_CHECKOUT_ASYNC,
    data,
  };
};
export const getShippingFeeAsync = (data: IUpdateDeliveryOptionParams) => {
  return {
    type: CartAction.GET_SHIPPING_FEE_ASYNC,
    data,
  };
};
export const setErrorPostalCode = data => {
  return {
    type: CartAction.SET_ERROR_POSTAL_CODE,
    data,
  };
};

export const applyDiscount = (data: IUpdateDiscountParams) => {
  return {
    type: CartAction.APPLY_DISCOUNT_ASYNC,
    data,
  };
};

export const removeDiscount = (data: IUpdateDiscountParams) => {
  return {
    type: CartAction.REMOVE_DISCOUNT_ASYNC,
    data,
  };
};
export const setCheckoutSuccess = data => {
  return {
    type: CartAction.SET_IS_CHECKOUT_SUCCESS,
    data,
  };
};
export const getCartIdAfterLogin = () => {
  return {
    type: CartAction.GET_CART_FROM_LOGIN,
  };
};
export const getCartDetail = () => {
  return {
    type: CartAction.GET_CART_DETAIL,
  };
};
export const setIsSelfPickUp = (data: boolean) => {
  return {
    type: CartAction.SET_IS_SELF_PICKUP,
    data,
  };
};
export const getTotalPriceWhenSelfPickUp = (data: boolean) => {
  return {
    type: CartAction.GET_TOTAL_PRICE_SELF_PICKUP_ASYNC,
    data,
  };
};
export const resetCartAction = () => {
  return {
    type: CartAction.RESET_CART_ACTION,
  };
};
export const setPaymentMethod = (data: SavedMethod) => {
  return {
    type: CartAction.SET_PAYMENT_METHOD,
    data,
  };
};

export const setCardValidateForm = (data: cardValidateForm) => {
  return {
    type: CartAction.SET_CARD_VALIDATE_FORM,
    data,
  };
};

export const setErrorRemarks = (data: boolean) => {
  return {
    type: CartAction.SET_ERROR_REMARKS,
    data,
  };
};
export const refreshCartDetailAsync = () => {
  return {
    type: CartAction.REFRESH_CART_DETAIL_ASYNC,
  };
};
export const setFinalTotalPriceOfCart = (data: number) => {
  return { type: CartAction.GET_FINAL_TOTAL_PRICE, data };
};
export const updateCartDeliveryOptionAsync = (data: IUpdateDeliveryOptionParams) => {
  return { type: CartAction.UPDATE_CART_DELIVERY_OPTION_ASYNC, data };
};
export const setShippingFeeAction = (data: number) => {
  return { type: CartAction.GET_SHIPPING_FEE, data };
};
export const updateCartAction = (data: Partial<CartReduxState>) => {
  return { type: CartAction.UPDATE_CART, data };
};
export const fetchCartByIdAsyncAction = (
  cartId: string,
  slug: string,
): CartGeneralAction<IFetchCartDetailByIdParams> => {
  return { type: CartAction.FETCH_CART_BY_ID_ASYNC_ACTION, data: { cartId, slug } };
};
