/* eslint-disable @typescript-eslint/no-explicit-any */
import { Merchant } from 'types/merchant';
import { IMerchantAction, IMerchantGeneralAction } from './MerchantAction';

export interface MerchantReduxState {
  merchant: Merchant;
}

export const DEFAULT_MERCHANT_STATE: MerchantReduxState = {
  merchant: {
    id: '',
    referenceId: '',
    kycStatus: '',
    owner: {
      name: '',
      surName: '',
    },
    type: '',
    accType: '',
    email: '',
    name: '',
    address: '',
    phoneNumber: '',
    bankCode: '',
    bankAccNum: '',
    bankAccName: '',
    logoUrl: '',
    idCardImgUrl: '',
    companyRegistrationDocUrl: '',
    additionalDocUrl: '',
    feeConfig: {},
    merchantPublicKey: '',
    balance: 0,
    nonKycFeeConfig: {},
  },
};

const MerchantReducer = (state = DEFAULT_MERCHANT_STATE, action: IMerchantGeneralAction<any>): MerchantReduxState => {
  switch (action.type) {
    case IMerchantAction.FETCH_MERCHANT_DATA:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          ...action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default MerchantReducer;
