import { requestUpdateVisitorCount } from 'redux/visitor/VisitorRepository';
import { Store } from 'types';
import {
  EAnalyticsEventType,
  EEvent,
  EPageType,
  EStoreMode,
  ITrackActiveUser,
  ITrackPageView,
  ITrackViewContent,
} from 'types/analytics';
import { getHasVisitedCookie, setHasVisitedCookie } from 'utils/cookie';
import { DataStorage } from 'utils/storage';
import { trackEvent } from '..';
import { trackAnalyticsEvent } from '../analytics-service';

export async function trackBuyerSession(store: Partial<Store>) {
  const { id, userId, businessName } = store;
  if (!getHasVisitedCookie(id)) {
    await requestUpdateVisitorCount(id);
    await trackEvent(EEvent.BuyerSession, { store_id: id, seller_name: businessName });
    await trackAnalyticsEvent({ event: EEvent.BuyerSession, type: EAnalyticsEventType.CLEVERTAP, userId });
    setHasVisitedCookie(id);
  }
}

export function trackPageView(options: ITrackPageView) {
  trackEvent(EEvent.PageView, options);
}

export function trackViewContent(options: ITrackViewContent) {
  trackEvent(EEvent.ViewContent, options);
}

export function trackViewContentClickedProduct() {
  trackEvent(EEvent.ViewContent_ClickedProduct);
}
export function trackViewContentClickedProductImage() {
  trackEvent(EEvent.ViewContent_ClickedProductImage);
}

export function trackViewContentProductImpression() {
  trackEvent(EEvent.ViewContent_ProductImpression);
}

export function trackViewContentUniqueProductImpression(productId: string) {
  const uniqueProdImpression = JSON.parse(DataStorage.loadSession({ key: 'uniqueProductList' }));
  const checkProduct = uniqueProdImpression?.find((e: string) => e === productId);
  if (!uniqueProdImpression) {
    DataStorage.saveItemSession({ key: `uniqueProductList`, data: [productId] });
    trackEvent(EEvent.ViewContent_UniqueProductImpression);
  } else {
    if (!checkProduct) {
      DataStorage.saveItemSession({ key: `uniqueProductList`, data: [...uniqueProdImpression, productId] });
      trackEvent(EEvent.ViewContent_UniqueProductImpression);
    }
  }
}

export function trackViewContentClickedCategoryImage() {
  trackEvent(EEvent.ViewContent_ClickedCategoryImage);
}

export function trackViewContentCategoryImpression() {
  trackEvent(EEvent.ViewContent_CategoryImpression);
}

export function trackViewContentScroll25() {
  trackEvent(EEvent.ViewContent_Scroll25);
}

export function trackViewContentScroll50() {
  trackEvent(EEvent.ViewContent_Scroll50);
}

export function trackViewContentScroll75() {
  trackEvent(EEvent.ViewContent_Scroll75);
}

export function trackViewContentScroll100() {
  trackEvent(EEvent.ViewContent_Scroll100);
}

// Additional handlers
export function handlePageView(path: string, url: string, isFnB: boolean) {
  let pageType: EPageType;
  const storeMode = isFnB ? EStoreMode.FNB : EStoreMode.ECOM;
  switch (path) {
    case '/[slug]':
      pageType = EPageType.Home;
      break;
    case '/[slug]/products/[productSlug]':
      pageType = EPageType.Product;
      break;
    case '/[slug]/categories/[categorySlug]':
      pageType = EPageType.Category;
      break;
    case '/[slug]/store-information':
      pageType = EPageType.Store_Information;
      break;
    case '/[slug]/checkout':
      pageType = EPageType.Checkout;
      break;
    case '/[slug]/account-details':
      pageType = EPageType.Profile;
      break;
    case '/[slug]/promotion':
      pageType = EPageType.Promotions;
      break;
    case '/[slug]/order/[orderId]':
      pageType = EPageType.Order_Details;
      break;
  }

  if (pageType !== undefined) {
    trackPageView({ page_type: pageType, page_url: url, store_mode: storeMode });
  }
}

export function trackEnteredEComMode() {
  trackEvent(EEvent.Entered_ecom_mode);
}

export function trackEnteredFoodMode() {
  trackEvent(EEvent.Entered_food_mode);
}

export function trackActiveUser(options: ITrackActiveUser) {
  trackEvent(EEvent.Active_user, options);
}
