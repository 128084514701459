// store.ts
import { createStore, Store as ReduxStore, combineReducers, applyMiddleware } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import AppStateReducer from './appState/AppStateReducer';
import ProductReducer, { ProductReduxState } from './product/ProductReducer';
import { AppState, Store } from '../types';
import StoreReducer from './store/StoreReducer';
import CategoryReducer, { CategoryReduxState } from './category/CategoryReducer';
import createSagaMiddleware, { Task } from 'redux-saga';
import rootSaga from './saga';
import OrderReducer, { OrderReduxState } from './orders/OrderReducer';
import DiscountReducer, { DiscountReduxState } from './discount/DiscountReducer';
import AuthReducer, { AuthReduxState } from './auth/AuthReducer';
import CartReducer, { CartReduxState } from './cart/CartReducer';
import MerchantReducer, { MerchantReduxState } from './merchant/MerchantReducer';
import get from 'lodash/get';
import CommonReducer, { CommonReduxState } from './common/CommonReducer';

export interface RootState {
  appState: AppState;
  product: ProductReduxState;
  store: Partial<Store>;
  orders: OrderReduxState;
  category: CategoryReduxState;
  discount: DiscountReduxState;
  auth: AuthReduxState;
  cart: CartReduxState;
  merchant: MerchantReduxState;
  common: CommonReduxState;
}

export interface SagaStore extends ReduxStore {
  sagaTask?: Task;
}

const combinedReducer = combineReducers<RootState>({
  appState: AppStateReducer,
  product: ProductReducer,
  store: StoreReducer,
  orders: OrderReducer,
  category: CategoryReducer,
  discount: DiscountReducer,
  auth: AuthReducer,
  cart: CartReducer,
  merchant: MerchantReducer,
  common: CommonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    if (!get(action, 'payload.merchant.merchant.id')) {
      nextState.merchant = state.merchant;
    }
    if (!get(action, 'payload.auth.buyer.id')) {
      nextState.auth = state.auth;
    }
    if (!get(action, 'payload.store.id')) {
      nextState.store = state.store;
    }
    if (!get(action, 'payload.product.products')?.length) {
      nextState.product = state.product;
    }
    if (!get(action, 'payload.category.categories')?.length) {
      nextState.category = state.category;
    }
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const reduxStore = createStore(rootReducer, applyMiddleware(sagaMiddleware));

  (reduxStore as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);
  return reduxStore;
};

export const wrapper = createWrapper<ReduxStore<RootState>>(makeStore, {
  debug: process.env.NODE_ENV !== 'production',
});
