import axios from 'axios';
import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import { Store } from 'types';
import { IStoreCategory, IStoreSearchFilter } from './Common';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

const Axios = axios.create({
  baseURL: API_STORE,
});

export const requestGetStoreCategories = async () => {
  const data = await Axios.get<IStoreCategory[]>(`/api/store/buyer/store-categories`).then(res => res.data);
  return data;
};

export const requestGetCities = async () => {
  const data = await Axios.get<ICity[]>(`/api/store/buyer/administrative-division/province`).then(res => res.data);
  return data;
};

export const requestSearchStores = async (searchFilter: IStoreSearchFilter) => {
  const data = await Axios.get<Store[]>(`/api/store/buyer-store`, {
    params: searchFilter,
  }).then(res => res.data);
  return data;
};
