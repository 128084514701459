/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CartAction, CartGeneralAction } from './CartAction';
import { ExpressedPreferenceEnum, ISelectedProductGetFromCart } from 'types';
import { DataStorage } from 'utils/storage';
import { SavedMethod, cardValidateForm } from 'types/merchant';
import { DiscountAppliedResult } from 'types/discount';
export interface CartReduxState {
  subTotal: number;
  id: '';
  items: ISelectedProductGetFromCart[];
  remarks: '';
  shippingFee: number;
  errorPostalCode: '';
  isCheckoutSuccess: boolean;
  totalPrice: number;
  appliedDiscountIds: [];
  totalDiscountAmount: number;
  isFreeShip: boolean;
  flatShippingFee: number;
  isSelfPickUp: boolean;
  finalTotalPrice: number;
  manualDiscountAmount: number;
  storeId: string;
  paymentMethodInfo: SavedMethod;
  paymentMethodValidateForm: cardValidateForm;
  freeShipType: string;
  errorRemarks: boolean;
  discountRes: DiscountAppliedResult[];
  deliveryOption?: ExpressedPreferenceEnum;
}

export const DEFAULT_CART_STATE: CartReduxState = {
  subTotal: 0,
  id: '',
  items: [],
  remarks: '',
  shippingFee: 0,
  errorPostalCode: '',
  isCheckoutSuccess: false,
  totalPrice: 0,
  appliedDiscountIds: [],
  totalDiscountAmount: 0,
  isFreeShip: false,
  flatShippingFee: 0,
  isSelfPickUp: false,
  finalTotalPrice: 0,
  manualDiscountAmount: 0,
  storeId: '',
  paymentMethodInfo: null,
  paymentMethodValidateForm: { cardNumber: null, securityCode: null, expirationDate: null, name: null },

  freeShipType: 'none',
  errorRemarks: false,
  discountRes: [],
};

const ISSERVER = typeof window === 'undefined';

const CartReducer = (state = DEFAULT_CART_STATE, action: CartGeneralAction<any>): CartReduxState => {
  switch (action.type) {
    case CartAction.UPDATE_CART:
      if (!ISSERVER && action.data.id) DataStorage.saveItem({ key: 'cartId', data: action.data.id });
      return {
        ...state,
        ...action.data,
      };

    case CartAction.GET_ALL_PRODUCTS_IN_CART:
      return {
        ...state,
        ...action.data,
      };
    case CartAction.GET_SHIPPING_FEE:
      return {
        ...state,
        shippingFee: action.data,
      };
    case CartAction.SET_ERROR_POSTAL_CODE:
      return {
        ...state,
        errorPostalCode: action.data,
      };
    case CartAction.SET_IS_CHECKOUT_SUCCESS:
      return {
        ...state,
        isCheckoutSuccess: action.data,
      };
    case CartAction.SET_IS_SELF_PICKUP:
      return {
        ...state,
        isSelfPickUp: action.data,
      };
    case CartAction.GET_FINAL_TOTAL_PRICE:
      return {
        ...state,
        finalTotalPrice: action.data,
      };
    case CartAction.SET_ERROR_REMARKS:
      return {
        ...state,
        errorRemarks: action.data,
      };
    case CartAction.RESET_CART_ACTION:
      return DEFAULT_CART_STATE;
    case CartAction.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodInfo: action.data,
      };
    case CartAction.SET_CARD_VALIDATE_FORM:
      return {
        ...state,
        paymentMethodValidateForm: action.data,
      };

    default:
      return state;
  }
};

export default CartReducer;
