import { Discount, DiscountFilterInputParams, DiscountType } from '../../types/discount';

export enum DiscountActions {
  FETCH_DISCOUNT_INFO_ASYNC = 'FETCH_DISCOUNT_INFO_ASYNC',
  SET_DISCOUNTS_DATA = 'SET_DISCOUNTS_DATA',
  CLEAR_DISCOUNT_DATA = 'CLEAR_DISCOUNT_DATA',
  SET_DISPLAYED_DISCOUNTS_DATA = 'SET_DISPLAYED_DISCOUNTS_DATA',
  SET_DISPLAYED_DISCOUNTS_DATA_ASYNC = 'SET_DISPLAYED_DISCOUNTS_DATA_ASYNC',
  DELETE_DISCOUNT = 'DELETE_DISCOUNT',
  DELETE_DISCOUNT_ASYNC = 'DELETE_DISCOUNT_ASYNC',
  SET_SELECTED_DISCOUNT = 'SET_SELECTED_DISCOUNT',
  UPDATE_DISCOUNT = 'UPDATE_DISCOUNT',
  UPDATE_DISCOUNT_ASYNC = 'UPDATE_DISCOUNT_ASYNC',
  SET_DISCOUNT_DISPLAY_FILTER = 'SET_DISCOUNT_DISPLAY_FILTER',
  SET_DISCOUNTS_DATA_ASYNC = 'SET_DISCOUNTS_DATA_ASYNC',
  REORDER_DISCOUNT_ASYNC = 'REORDER_DISCOUNT_ASYNC',
  REORDER_DISCOUNT = 'REORDER_DISCOUNT',
  UPDATE_DISCOUNT_TO_CREATE = 'UPDATE_DISCOUNT_TO_CREATE',
  CREATE_DISCOUNT = 'CREATE_DISCOUNT',
  CREATE_DISCOUNT_ASYNC = 'CREATE_DISCOUNT_ASYNC',
  SET_DISCOUNT_TO_CREATE = 'SET_DISCOUNT_TO_CREATE',
  SET_DISCOUNT_FROM_SELLER = 'SET_DISCOUNT_FROM_SELLER',
  SET_DISCOUNT_FROM_CARTID = 'SET_DISCOUNT_FROM_CARTID',
  SET_DISCOUNT_ERROR_MESSAGE = 'SET_DISCOUNT_ERROR_MESSAGE',
}

export interface IDiscountGeneralAction<T> {
  type: DiscountActions;
  data: T;
}

export const setDiscountDataAsyncAction = (): IDiscountGeneralAction<void> => {
  return { type: DiscountActions.SET_DISCOUNTS_DATA_ASYNC, data: null };
};

export const setDisplayedDiscountDataAction = (discounts: Discount[]): IDiscountGeneralAction<Discount[]> => {
  return { type: DiscountActions.SET_DISPLAYED_DISCOUNTS_DATA, data: discounts };
};

export const fetchDiscountInfoActionAsync = (): IDiscountGeneralAction<void> => {
  return { type: DiscountActions.FETCH_DISCOUNT_INFO_ASYNC, data: null };
};

export const clearDiscountDataAction = (): IDiscountGeneralAction<void> => {
  return { type: DiscountActions.CLEAR_DISCOUNT_DATA, data: null };
};

export const searchDiscountsAsyncAction = (
  type: DiscountType,
  searchText: string,
): IDiscountGeneralAction<DiscountFilterInputParams> => {
  return {
    type: DiscountActions.SET_DISPLAYED_DISCOUNTS_DATA_ASYNC,
    data: { type, searchText },
  };
};

export const deleteDiscountAsyncAction = (discountId: string): IDiscountGeneralAction<Partial<Discount>> => {
  return { type: DiscountActions.DELETE_DISCOUNT_ASYNC, data: { id: discountId } };
};

export const setSelectedDiscountAction = (discount: Discount): IDiscountGeneralAction<Discount> => {
  return { type: DiscountActions.SET_SELECTED_DISCOUNT, data: discount };
};

export const updateDiscountAsyncAction = (
  updatedDiscount: Partial<Discount>,
): IDiscountGeneralAction<Partial<Discount>> => {
  return { type: DiscountActions.UPDATE_DISCOUNT_ASYNC, data: updatedDiscount };
};

export const reorderDiscountAsyncAction = (
  updatedDiscounts: Partial<Discount>[],
): IDiscountGeneralAction<Partial<Discount>[]> => {
  return { type: DiscountActions.REORDER_DISCOUNT_ASYNC, data: updatedDiscounts };
};

export const setDiscountDisplayFilterAction = (
  type: DiscountType,
  searchText: string,
): IDiscountGeneralAction<DiscountFilterInputParams> => {
  return {
    type: DiscountActions.SET_DISCOUNT_DISPLAY_FILTER,
    data: { type, searchText },
  };
};

export const updateDiscountToCreateAction = (
  updatedDiscount: Partial<Discount>,
): IDiscountGeneralAction<Partial<Discount>> => {
  return { type: DiscountActions.UPDATE_DISCOUNT_TO_CREATE, data: updatedDiscount };
};

export const resetDiscountToCreateAction = (): IDiscountGeneralAction<Partial<Discount>> => {
  return { type: DiscountActions.SET_DISCOUNT_TO_CREATE, data: {} };
};

export const createDiscountAsyncAction = (discount: Partial<Discount>): IDiscountGeneralAction<Partial<Discount>> => {
  return { type: DiscountActions.CREATE_DISCOUNT_ASYNC, data: discount };
};

export const setDiscountFromSeller = (data: string) => {
  return {
    type: DiscountActions.SET_DISCOUNT_FROM_SELLER,
    data,
  };
};
export const setDiscountIdFromCart = data => {
  return {
    type: DiscountActions.SET_DISCOUNT_FROM_CARTID,
    data,
  };
};
export const setDiscountErrorMessage = (data: string) => {
  return {
    type: DiscountActions.SET_DISCOUNT_ERROR_MESSAGE,
    data,
  };
};
