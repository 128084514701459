/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddressDto, BuyerInfo, IdentifierType } from 'types/auth';
import { identifyUser, updateUserEmailHandler } from 'utils/analytics';
import { IAuthAction, IAuthGeneralAction } from './AuthActions';

export interface AuthReduxState {
  access_token: string;
  buyer: BuyerInfo;
  isLoggedIn: boolean;
  selectedAddress: AddressDto;
  isCheckoutAsGuest: boolean;
}

const defaultBuyerUser = {
  address: {
    detailedAddress: '',
    country: '',
    region: '',
    province: '',
    district: '',
    subDistrict: '',
    barangay: '',
    postalCode: '',
  },
  allowLineOrderNotification: false,
  noteForDriver: '',
  email: '',
  phoneNumber: '',
  fullName: '',
  lineUserId: '',
  userId: '',
  identifier: '',
  identifierType: IdentifierType.PHONE_NUMBER,
  id: '',
  expressedPreference: '',
  addresses: [],
};
export const DEFAULT_AUTH_STATE: AuthReduxState = {
  isLoggedIn: null,
  access_token: '',
  buyer: defaultBuyerUser,
  selectedAddress: {},
  isCheckoutAsGuest: false,
};

const AuthReducer = (state = DEFAULT_AUTH_STATE, action: IAuthGeneralAction<any>): AuthReduxState => {
  switch (action.type) {
    case IAuthAction.LOGIN:
      updateUserEmailHandler(action?.data?.user?.email);
      identifyUser(action?.data?.user?.userId);
      return {
        ...state,
        isLoggedIn: true,
        access_token: action.data.access_token,
        buyer: action.data.user,
      };
    case IAuthAction.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        access_token: '',
        buyer: defaultBuyerUser,
        selectedAddress: state.isCheckoutAsGuest ? state.selectedAddress : {},
      };
    case IAuthAction.UPDATE:
      updateUserEmailHandler(action?.data?.email);
      return {
        ...state,
        buyer: action.data,
      };
    case IAuthAction.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.data,
      };
    case IAuthAction.SET_CHECKOUT_AS_GUEST:
      return {
        ...state,
        isCheckoutAsGuest: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export default AuthReducer;
