import axios from 'axios';
import { ICheckoutPayload, Merchant, SavedMethod, TransactionDataFromCart } from 'types/merchant';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

const Axios = axios.create();

export const requestGetMerchantData = async (merchantId: string) => {
  return Axios.get<Merchant>(`${API_STORE}/api/payment/buyer/merchants/${merchantId}`).then(res => res.data);
};
export const requestCreateSavedMethods = async (data: SavedMethod, merchantId: string) => {
  return axios
    .post(`${API_STORE}/api/payment/payment-method-history`, data, { params: { merchantId } })
    .then(res => res.data);
};
export const requestGetSavedMethods = async (merchantId: string) => {
  return axios.get(`${API_STORE}/api/payment/payment-method-history`, { params: { merchantId } }).then(res => res.data);
};
export const requestDeleteSavedMethod = async (methodId: string) => {
  return axios.delete(`${API_STORE}/api/payment/payment-method-history/${methodId}`).then(res => res.data);
};
export const requestCreateTransaction = async (orderId: string, data: ICheckoutPayload) => {
  return axios
    .post<TransactionDataFromCart>(`${API_STORE}/api/store/buyer-order/${orderId}/createTransaction`, data)
    .then(res => res.data);
};
