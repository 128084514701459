import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { logError } from 'utils/error';
import {
  CommonActions,
  fetchCitiesAction,
  fetchStoreCategoriesAction,
  ICommonGeneralAction,
  searchStoresAction,
} from './CommonActions';
import { requestGetCities, requestGetStoreCategories, requestSearchStores } from './CommonRepository';
import { IStoreCategory, IStoreSearchFilter } from './Common';
import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import { EAppStateFlagKeys, Store } from 'types';
import { sGetDisplayedStores } from './CommonSelectors';
import { toggleAppStateFlag, toggleIsLoading } from 'redux/appState/AppStateActions';
import { ALL_CATEGORIES_FILTER_OPTION } from 'utils/constants';

function* fetchStoreCategoriesAsync() {
  try {
    const storeCategories: IStoreCategory[] = yield call(requestGetStoreCategories);
    yield put(fetchStoreCategoriesAction(storeCategories));
  } catch (e) {
    logError(e);
  }
}

function* watchFetchStoreCategoriesAsync() {
  yield takeEvery(CommonActions.FETCH_STORE_CATEGORIES_ASYNC, fetchStoreCategoriesAsync);
}

function* fetchCitiesAsync() {
  try {
    const cities: ICity[] = yield call(requestGetCities);
    yield put(fetchCitiesAction(cities));
  } catch (e) {
    logError(e);
  }
}

function* watchFetchCitiesAsync() {
  yield takeEvery(CommonActions.FETCH_CITIES_ASYNC, fetchCitiesAsync);
}

function* searchStoresAsync(action: ICommonGeneralAction<IStoreSearchFilter>) {
  try {
    yield put(toggleIsLoading(true));
    const payload: IStoreSearchFilter = {
      ...action.data,
      categoryId: action.data?.categoryId === ALL_CATEGORIES_FILTER_OPTION.id ? '' : action.data?.categoryId,
    };
    const stores: Store[] = yield call(requestSearchStores, payload);
    if (action.data?.page > 1) {
      const displayedStores = yield select(sGetDisplayedStores);
      yield put(searchStoresAction([...displayedStores, ...stores]));
      yield put(toggleAppStateFlag(EAppStateFlagKeys.canLoadMoreStores, stores?.length >= action.data.pageSize));
    } else {
      yield put(searchStoresAction(stores));
    }
  } catch (e) {
    logError(e);
  } finally {
    yield put(toggleIsLoading(false));
  }
}

function* watchSearchStoresAsync() {
  yield takeEvery(CommonActions.SEARCH_STORES_ASYNC, searchStoresAsync);
}

function* commonSaga() {
  yield all([watchFetchStoreCategoriesAsync(), watchFetchCitiesAsync(), watchSearchStoresAsync()]);
}

export default commonSaga;
