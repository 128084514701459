import cookie from 'cookie-cutter';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { ECookie, ISetCookieOptions } from 'types/cookie';

export function getCookie<T>(key: string, def?: T): T | undefined {
  const value = cookie.get(key);

  if (!isNil(value)) {
    return value;
  }

  return def;
}

export function setCookie<T>(key: string, value: T, opts?: ISetCookieOptions): T {
  cookie.set(key, value, opts);
  return value;
}

const getHasVisitedCookieKey = (storeId: string) => `${ECookie.HAS_VISITED} - ${storeId}`;

export const getHasVisitedCookie = (storeId: string) => {
  return getCookie<boolean>(getHasVisitedCookieKey(storeId), false);
};

export const setHasVisitedCookie = (storeId: string) => {
  setCookie(getHasVisitedCookieKey(storeId), true, { expires: dayjs().endOf('hour').toDate() });
};
