import isObject from 'lodash/isObject';
/* eslint-disable @typescript-eslint/no-explicit-any */
const load = ({ key }: { key: string }) => {
  return localStorage.getItem(key);
};

const loadSession = ({ key }: { key: string }) => {
  return sessionStorage.getItem(key);
};

const save = ({ key, data, isParse = true }: { key: string; data: any; isParse?: boolean }) => {
  return localStorage.setItem(key, isParse ? JSON.stringify(data) : data);
};

const saveItem = ({ key, data }: { key: string; data: any }) => {
  return localStorage.setItem(key, isObject(data) ? JSON.stringify(data) : data);
};

const saveItemSession = ({ key, data }: { key: string; data: any }) => {
  return sessionStorage.setItem(key, isObject(data) ? JSON.stringify(data) : data);
};

const remove = ({ key }: { key: string }) => {
  return localStorage.removeItem(key);
};

const removeSession = ({ key }: { key: string }) => {
  return sessionStorage.removeItem(key);
};

export const getStoredProperty = async (key: string) => {
  let value;
  try {
    value = await DataStorage.load({ key });
  } catch {}
  return value;
};
export const DataStorage = {
  load,
  save,
  remove,
  saveItem,
  loadSession,
  saveItemSession,
  removeSession,
};
