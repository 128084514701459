/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category, CategoryFilterInputParams } from 'types';

export enum CategoryActions {
  FETCH_CATEGORY_INFO_ASYNC = 'FETCH_CATEGORY_INFO_ASYNC',
  SET_CATEGORY_DATA = 'SET_CATEGORY_DATA',
  CLEAR_CATEGORY_DATA = 'CLEAR_CATEGORY_DATA',
  SET_DISPLAYED_CATEGORY_DATA = 'SET_DISPLAYED_CATEGORY_DATA',
  SET_DISPLAYED_CATEGORY_DATA_ASYNC = 'SET_DISPLAYED_CATEGORY_DATA_ASYNC',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  DELETE_CATEGORY_ASYNC = 'DELETE_CATEGORY_ASYNC',
  SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  UPDATE_CATEGORY_ASYNC = 'UPDATE_CATEGORY_ASYNC',
  SET_CATEGORY_DISPLAY_FILTER = 'SET_CATEGORY_DISPLAY_FILTER',
  SET_CATEGORY_DATA_ASYNC = 'SET_CATEGORY_DATA_ASYNC',
  REORDER_CATEGORY_ASYNC = 'REORDER_CATEGORY_ASYNC',
  REORDER_CATEGORY = 'REORDER_CATEGORY',

  FETCH_DELIVERY_PROFILE_ASYNC = 'FETCH_DELIVERY_PROFILE_ASYNC',
  FETCH_DELIVERY_PROFILE = 'FETCH_DELIVERY_PROFILE',
  FETCH_CATEGORIES_BY_SLUG_ASYNC = 'FETCH_CATEGORIES_BY_SLUG_ASYNC',
  FETCH_SELECTED_CATEGORY_BY_SLUG_ASYNC = 'FETCH_SELECTED_CATEGORY_BY_SLUG_ASYNC',
}

export interface IUpdateCategoryAction {
  type: CategoryActions;
  data: Partial<Category>;
}

export interface IRequestGetCategoryInfoAction {
  type: CategoryActions;
}

export interface IClearCategoryDataAction {
  type: CategoryActions;
}

export interface ICategoryAction {
  type: CategoryActions;
  data: any;
}

export interface ISearchCategoryAction {
  type: CategoryActions;
  data: {
    storeId: string;
    filter: CategoryFilterInputParams;
  };
}

export interface IDeleteCategoryAction {
  type: CategoryActions;
  data: {
    categoryId: string;
  };
}

export interface ICategoryGeneralAction<T> {
  type: CategoryActions;
  data: T;
}

export const setCategoryDataAsyncAction = (): ICategoryGeneralAction<void> => {
  return { type: CategoryActions.SET_CATEGORY_DATA_ASYNC, data: null };
};

export const setDisplayedCategoriesDataAction = (categories: Category[]): ICategoryAction => {
  return { type: CategoryActions.SET_DISPLAYED_CATEGORY_DATA, data: categories };
};

export const fetchCategoryInfoActionAsync = (): IRequestGetCategoryInfoAction => {
  return { type: CategoryActions.FETCH_CATEGORY_INFO_ASYNC };
};

export const clearCategoryDataAction = (): IClearCategoryDataAction => {
  return { type: CategoryActions.CLEAR_CATEGORY_DATA };
};

export const searchCategoriesAsyncAction = (
  filterParams: CategoryFilterInputParams,
): ICategoryGeneralAction<CategoryFilterInputParams> => {
  return {
    type: CategoryActions.SET_DISPLAYED_CATEGORY_DATA_ASYNC,
    data: filterParams,
  };
};

export const deleteCategoryAsyncAction = (categoryId: string): IDeleteCategoryAction => {
  return { type: CategoryActions.DELETE_CATEGORY_ASYNC, data: { categoryId } };
};

export const setSelectedCategoryAction = (category: Category): ICategoryGeneralAction<Category> => {
  return { type: CategoryActions.SET_SELECTED_CATEGORY, data: category };
};

export const updateCategoryAsyncAction = (
  updatedCategory: Partial<Category>,
): ICategoryGeneralAction<Partial<Category>> => {
  return { type: CategoryActions.UPDATE_CATEGORY_ASYNC, data: updatedCategory };
};

export const reorderCategoryAsyncAction = (
  updatedCategories: Partial<Category>[],
): ICategoryGeneralAction<Partial<Category>[]> => {
  return { type: CategoryActions.REORDER_CATEGORY_ASYNC, data: updatedCategories };
};

export const setCategoryDisplayFilterAction = (
  filterParams: CategoryFilterInputParams,
): ICategoryGeneralAction<CategoryFilterInputParams> => {
  return {
    type: CategoryActions.SET_CATEGORY_DISPLAY_FILTER,
    data: filterParams,
  };
};

export interface IFetchDeliveryProfileAsync {
  type: CategoryActions;
}

export const fetchDeliveryProfile = (): IFetchDeliveryProfileAsync => {
  return {
    type: CategoryActions.FETCH_DELIVERY_PROFILE_ASYNC,
  };
};

export const fetchCategoriesBySlugAsyncAction = (storeSlug: string): ICategoryGeneralAction<{ storeSlug: string }> => {
  return { type: CategoryActions.FETCH_CATEGORIES_BY_SLUG_ASYNC, data: { storeSlug } };
};

export const fetchSelectedCategoryBySlugAsyncAction = (
  storeSlug: string,
  categorySlug: string,
): ICategoryGeneralAction<{ storeSlug: string; categorySlug: string }> => {
  return { type: CategoryActions.FETCH_SELECTED_CATEGORY_BY_SLUG_ASYNC, data: { storeSlug, categorySlug } };
};
