import { ICity } from 'components/BuyerDirectory/TopCityList/TopCityList.d';
import { Store } from 'types';
import { IStoreCategory } from './Common';
import { CommonActions, ICommonGeneralAction } from './CommonActions';

export interface CommonReduxState {
  storeCategories: IStoreCategory[];
  cities: ICity[];
  selectedStoreCategory?: Partial<IStoreCategory>;
  displayedStores: Store[];
  selectedCity?: Partial<ICity>;
}

export const DEFAULT_COMMON_REDUX_STATE: CommonReduxState = {
  storeCategories: [],
  cities: [],
  selectedStoreCategory: {},
  displayedStores: [],
  selectedCity: {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CommonReducer = (state = DEFAULT_COMMON_REDUX_STATE, action: ICommonGeneralAction<any>): CommonReduxState => {
  switch (action.type) {
    case CommonActions.FETCH_STORE_CATEGORIES:
      return {
        ...state,
        storeCategories: action.data,
      };
    case CommonActions.FETCH_CITIES:
      return {
        ...state,
        cities: action.data,
      };
    case CommonActions.SEARCH_STORES:
      return {
        ...state,
        displayedStores: action.data,
      };
    case CommonActions.SET_SELECTED_STORE_CATEGORY:
      return {
        ...state,
        selectedStoreCategory: action.data,
      };
    case CommonActions.SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.data,
      };

    default:
      return state;
  }
};

export default CommonReducer;
