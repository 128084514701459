import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { AddressDto, BuyerAddress } from 'types/auth';
import { IOrder } from 'types/order';
import { Discount, DiscountRuleType } from 'types/discount';
import { IGBPayGetQR, PaymentMethod } from 'types/merchant';
import { Address, BuyerFormInfo, Category, ModalWithButton, Product, Store, Variants } from 'types';
import i18n from 'i18next';
import { FORMAT_PRICE_REGEX, isServer, listOfImageSizeAscending, RULES_IN_APP_BROWSER_REGEX } from './constants';
import find from 'lodash/find';

export const getFullPhoneNumber = (countryPrefix: string, phoneNumber: string) => {
  if (!phoneNumber) return '';
  let formattedPhoneNumber = phoneNumber;
  if (phoneNumber.startsWith('0')) {
    formattedPhoneNumber = formattedPhoneNumber.substring(1);
  }
  return countryPrefix + formattedPhoneNumber;
};

export const transformSlug = (slug: string) => {
  const slugLowerCase = slug.toLowerCase();
  return encodeURIComponent(slugLowerCase);
};

export const getDisplayPrice = (price: number) => {
  const isIntegerNumber = Number.isInteger(price);
  if (!isNil(price)) return price.toFixed(isIntegerNumber ? 0 : 2).replace(FORMAT_PRICE_REGEX, '$1,');
  else return '';
};
export const getTitleHead = (addString?: string, storeName?: string) => {
  let title = 'Zaapi';
  if (storeName) {
    title = storeName;
    if (addString) {
      title = `${addString} | ${storeName}`;
    }
  }
  return title;
};

export const generateAddressToShow = (address: BuyerAddress) => {
  if (isNil(address)) return [];
  const fields = [
    'detailedAddress',
    'detailedAddress2',
    'district',
    'barangay',
    'province',
    'region',
    'postalCode',
    'country',
  ];
  const fieldsToShow = filter(fields, o => address[o]);
  const arr = map(fieldsToShow, (o: string) => address[o]);
  return arr;
};

export const contentOfTrackingOrderFlow = (order: IOrder, storeSlug: string, shippingFee: number) => {
  return {
    seller_id: order.storeId,
    seller_name: storeSlug,
    buyer_id: order.buyerDetails?.userId,
    order_id: order.id,
    order_subtotal_value: order.subTotal,
    shipping_fees_value: order.shippingFee,
    free_shipping_discount_value: Math.abs(order.shippingFee - shippingFee) * -1,
    discount_value: Math.abs(order.discountedAmount) * -1,
    order_value: order.total,
    products: order.items?.map(i => ({ product_id: i.productId, product_price: i.unitPrice, quantity: i.quantity })),
  };
};

export const isApplyForMoreCategory = (discount: Discount) => {
  if (discount?.applicability?.categories?.length > 1 || discount?.rule?.xCategoryIds?.length > 1) return true;
  else if (discount?.applicability?.categories?.length === 1 || discount?.rule?.xCategoryIds?.length === 1)
    return false;
};

export const isApplyForMoreProducts = (discount: Discount) => {
  if (discount?.applicability?.productIds?.length > 1) return true;
  else if (discount?.applicability?.productIds?.length === 1) return false;
};

export const getDiscountTagValue = (product: Product) => {
  if (product?.discountValue && product?.discountValue.totalPercentageOff > 0)
    return Math.round(product?.discountValue.totalPercentageOff);
  else return 0;
};

export const isBuyXGetY = (discount: Discount) => {
  if (discount.rule.type === DiscountRuleType.BUY_X_GET_Y) return true;
  else return false;
};

export const getStoreAddressText = (address: Address, showPostalCode = true) => {
  const fieldToShow = ['address1', 'address2', 'subDistrict', 'district', 'province'];
  const fields = filter(fieldToShow, o => get(address, o));
  const value = map(fields, (o: string) => get(address, o));
  if (isEmpty(fields)) {
    return address?.addressDetails || '';
  }
  return join(value, ', ') + ` ${showPostalCode ? get(address, 'postalCode') || '' : ''}`;
};

export const findProductById = (product, discount: Discount) => {
  const listProductBuy = filter(product, o => {
    if (discount.rule.xProductIds?.includes(o.id)) return o;
  });
  const listProductGet = filter(product, o => {
    if (discount.rule.yProductIds?.includes(o.id)) return o;
  });
  return [listProductBuy, listProductGet];
};

export const findCategoryById = (category, discount: Discount) => {
  const listCategoryBuy = filter(category, (o: Category) => {
    if (discount.rule.xCategoryIds?.includes(o.id)) return o;
  });
  const listCategoryGet = filter(category, (o: Category) => {
    if (discount.rule.yCategoryIds?.includes(o.id)) return o;
  });
  return [listCategoryBuy, listCategoryGet];
};

export const isAvailableVariant = (variant: Variants) => {
  return variant?.isAvailable;
};
export const convertToUrlEncode = (data: IGBPayGetQR) => {
  const params = new URLSearchParams();
  map(keys(data), (o: string) => params.append(o, get(data, o)));
  return params;
};

export const convertPaymentTypeToName = (data: PaymentMethod) => {
  switch (data) {
    case PaymentMethod.american_express:
      return i18n.t('American Express');
    case PaymentMethod.visa_domestic:
    case PaymentMethod.visa_overseas:
      return i18n.t('Visa');
    case PaymentMethod.jcb_domestic:
    case PaymentMethod.jcb_overseas:
      return i18n.t('JCB');
    case PaymentMethod.bill_payment:
      return i18n.t('Bill Payment');
    case PaymentMethod.line_pay:
      return i18n.t('LINE Pay');
    case PaymentMethod.mastercard_domestic:
    case PaymentMethod.mastercard_overseas:
      return i18n.t('Mastercard');
    case PaymentMethod.mobile_banking:
      return i18n.t('Mobile Banking');
    case PaymentMethod.promptpay_qr:
      return i18n.t('Prompt Pay');
    case PaymentMethod.qr_credit:
      return i18n.t('QR Credit');
    case PaymentMethod.true_wallet:
      return i18n.t('TrueMoney Wallet');
    case PaymentMethod.wechat_pay:
      return i18n.t('WeChat Pay');
    default:
      break;
  }
};
export const getGoogleMapsUrl = (address: Address, isEmbed = false) => {
  return `https://maps.google.com/maps?q=${encodeURI(getStoreAddressText(address, false))}&z=15${
    isEmbed ? '&output=embed' : ''
  }`;
};
export const generateFormToGetFullAddress = (address: Partial<BuyerFormInfo>) => {
  const fieldToShow = ['country', 'region', 'province', 'district', 'barangay', 'detailedAddress2', 'detailedAddress'];
  const fields = filter(fieldToShow, o => get(address, o));
  const value = map(fields, (o: string) => get(address, [o, 'name']) || get(address, o));
  if (isEmpty(fields)) return '';
  return join(value, ', ');
};
export const getErrorModalAddressData = (store: Partial<Store>, isDesktop = false, postalError = '') => {
  const isDomestic = store?.deliveryType?.type === 'DOMESTIC';
  let obj: ModalWithButton = {
    acceptTxt: i18n.t('Got it'),
    headerTxt: i18n.t('Address out of range'),
    subTitleTxt: i18n.t(
      isDomestic
        ? postalError
        : 'The address that you have entered is more than {{x}} km away from {{y}}. To check out, please change your address to one that is within {{x}} km of our location.',
      { x: store?.deliveryType?.radius, y: getStoreAddressText(store?.address) },
    ),
  };
  if (isDesktop) {
    obj = {
      ...obj,
      acceptTxt: i18n.t('GOT IT'),
      styleSubTitle: { maxWidth: 337, fontSize: 16, lineHeight: '25px' },
      styleHeader: { fontSize: 24, lineHeight: '32px' },
      maxWidth: 'auto',
    };
  }
  return obj;
};
export const getErrorUnsupportedCountryData = (isDesktop = false) => {
  return {
    headerTxt: i18n.t('We currently do not support cross-border e-commerce'),
    subTitleTxt: i18n.t('Please add an address in your country.'),
    acceptTxt: i18n.t('Got it'),
    styleHeader: isDesktop ? { padding: 0 } : undefined,
  };
};

export const getOptimizedImageSizeByUserDevice = (actualWidth: number) => {
  const devicePixelRatio = typeof window === 'undefined' ? 1 : window.devicePixelRatio;
  const imageSizeAscending = find(listOfImageSizeAscending, o => o.width > actualWidth * devicePixelRatio);
  return imageSizeAscending?.width;
};
export const shortenAddress = (address: string) => {
  if (!address) return '';
  return address
    ?.replace(/\n/g, '')
    ?.split(',')
    ?.map(i => String(i || '')?.trim())
    ?.join(', ');
};
export const getCalculateDistancePayload = (buyerInfo: AddressDto, slug: string) => {
  const fullAddress = generateFormToGetFullAddress(buyerInfo as Partial<BuyerFormInfo>);
  return {
    slug,
    fullAddress,
    country: buyerInfo?.country,
    lat: buyerInfo?.lat || undefined,
    lng: buyerInfo?.lng || undefined,
  };
};
export const useIsInAppBrowser = () => {
  const rules = RULES_IN_APP_BROWSER_REGEX;
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return !isServer ? regex.test(navigator.userAgent) : false;
};

export const getSearchFilterDirectoryUrl = (city?: string, categorySlug?: string) => {
  if (categorySlug) {
    return `/shop/${categorySlug}${city ? `/${city}` : ''}`;
  } else {
    return `/shop/${city ? city : ''}`;
  }
};
export const formatNumberCompact = (value = 0) => {
  return Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 }).format(value);
};
