/* eslint-disable max-lines-per-function */
import { isMobile } from 'react-device-detect';
import { AddressDto, BuyerInfo, IdentifierType } from 'types/auth';
import { EMAIL_TYPE_REGEX } from './constants';
import i18n from 'i18next';
import { ActionResponse, BuyerFormInfo } from 'types';
import get from 'lodash/get';
import concat from 'lodash/concat';
import { initSentry } from './sentry';
import { ErrorCode, IGetFormErrorParams } from '../types/errors';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import union from 'lodash/union';
import intersection from 'lodash/intersection';
/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = (error: any) => {
  if (error.response) {
    console.log('error', JSON.stringify(error.response, null, 2));
  } else {
    console.log(error);
  }

  (async () => {
    const Sentry = await initSentry();
    Sentry.captureException(error);
  })();
};
/* eslint-enable no-console */

export const generateErrorFormClone = () => {
  return {
    fullName: undefined,
    phoneNumber: undefined,
    email: undefined,
    address: {
      detailedAddress: undefined,
      postalCode: undefined,
      country: undefined,
      province: undefined,
      district: undefined,
      provinceTxt: undefined,
      districtTxt: undefined,
      barangay: undefined,
      region: undefined,
      provinceDistrictPostalCode: undefined,
    },
  };
};

export const getFormError = (data: IGetFormErrorParams) => {
  let isPass = true;
  const { form, checkbox, postalError, isDomestic } = data;
  const isPhil = form.address?.country?.code === 'PH';
  const isThai = form.address?.country?.code === 'TH';
  const errorFormClone = generateErrorFormClone();
  const generalField = ['fullName'];
  const addressFields = ['postalCode', 'country'];
  const textFieldsAddress = isPhil
    ? ['barangay', 'region', 'provinceTxt', 'districtTxt']
    : ['provinceTxt', 'districtTxt'];
  const selectFields = ['country', 'province', 'district'];

  const addressFieldsToCheck = !isThai ? addressFields.concat(textFieldsAddress) : addressFields;
  addressFieldsToCheck.forEach(element => {
    if (!form.address[element] || form.address[element]?.length === 0) {
      isPass = false;
      errorFormClone.address[element] = i18n.t('This field is required');
    }
  });
  if (isThai) {
    selectFields.forEach(element => {
      if (form.address[element] === undefined || !get(form.address, [element, 'name'])) {
        isPass = false;
        errorFormClone.address[element] = i18n.t('This field is required');
      }
    });
  }
  if (isThai && isDomestic) {
    if (!form.address.detailedAddress) {
      isPass = false;
      errorFormClone.address.detailedAddress = i18n.t('This field is required');
    }
    if (!form.address.province || !form.address.postalCode) {
      isPass = false;
      errorFormClone.address.provinceDistrictPostalCode = i18n.t('This field is required');
      if (form.address.province && !form.address.postalCode)
        errorFormClone.address.provinceDistrictPostalCode = i18n.t('Please complete remaining fields');
    }
  }

  if (isPass && postalError) {
    const errorAddressFields = !isThai
      ? concat(addressFieldsToCheck, ['country'])
      : concat(addressFieldsToCheck, selectFields);
    errorAddressFields.forEach(element => {
      errorFormClone.address[element] = '';
    });
  }
  generalField.forEach(element => {
    if (form[element]?.length === 0) {
      errorFormClone[element] = i18n.t('This field is required');
      isPass = false;
    }
  });
  if (form.email !== '' && !EMAIL_TYPE_REGEX.test(form.email)) {
    errorFormClone.email = i18n.t('Your email is invalid');
    isPass = false;
  }
  if (!checkbox) isPass = false;
  return {
    isPass,
    errorFormClone,
  };
};

export const isValidateAddress = (buyer: BuyerInfo) => {
  let isPass = true;
  const generalField = ['fullName'];
  const addressFields = ['postalCode', 'country', 'province', 'district'];
  generalField.forEach(element => {
    if (buyer[element] === null || buyer[element]?.length === 0) {
      isPass = false;
    }
  });
  addressFields.forEach(element => {
    if (buyer.address && (buyer.address[element] === undefined || buyer.address[element] === '')) {
      isPass = false;
    }
  });
  if (buyer.email && !EMAIL_TYPE_REGEX.test(buyer.email)) {
    isPass = false;
  }
  if (buyer.address && buyer.address.country === 'Philippines') {
    if (buyer.address?.barangay === undefined || buyer.address?.barangay === '') {
      isPass = false;
    }
    if (buyer.address?.region === undefined || buyer.address?.region === '') {
      isPass = false;
    }
  }
  return {
    isPass,
  };
};
export const getFormPickUpError = (form: BuyerFormInfo, checkBox?: boolean) => {
  let isPass = true;
  const errorFormClone = generateErrorFormClone();
  const generalField = ['fullName'];
  generalField.forEach(element => {
    if (form[element]?.length === 0) {
      errorFormClone[element] = i18n.t('This field is required');
      isPass = false;
    }
  });
  if (form.email !== '' && !EMAIL_TYPE_REGEX.test(form.email)) {
    errorFormClone.email = i18n.t('Your email is invalid');
    isPass = false;
  }
  if (!checkBox) isPass = false;
  return {
    isPass,
    errorFormClone,
  };
};
export const isValidateAddressForPickUp = (buyer: BuyerInfo) => {
  let isPass = true;
  const generalField = ['fullName'];
  generalField.forEach(element => {
    if (buyer[element] === null || buyer[element]?.length === 0) {
      isPass = false;
    }
  });
  if (buyer.identifierType === IdentifierType.LINE) {
    if (!buyer.phoneNumber) isPass = false;
  }
  if (buyer.email && !EMAIL_TYPE_REGEX.test(buyer.email)) {
    isPass = false;
  }
  return {
    isPass,
  };
};

export const getModalDataFromError = (error?: ActionResponse) => {
  switch (error?.errorCode) {
    case ErrorCode.PRODUCT_INSUFFICIENT_STOCK:
      return {
        headerTxt: i18n.t('Items no longer available'),
        subTitleTxt: i18n.t(
          'Some of the products in your cart are no longer available. Please refer to the cart summary for more details and edit your product selection to check out.',
        ),
        acceptTxt: i18n.t('Got It'),
        styleSubTitle: isMobile ? { margin: '10px 8px 0' } : {},
      };
    default:
      return {
        headerTxt: error?.message || i18n.t(`Sorry, it seems there was an error.\nPlease try again`),
        acceptTxt: i18n.t('Try Again'),
      };
  }
};

export const checkIsThaiOrPhil = (form: BuyerFormInfo): boolean => {
  return form.address?.country?.code === 'TH' || form.address?.country?.code === 'PH';
};

export const getMissingAddressField = (form: BuyerFormInfo, buyer: AddressDto, missingAddressFields: string[]) => {
  const isThai = form.address?.country?.code === 'TH';
  const selectFields = ['province', 'district'];

  const { errorFormClone } = getFormError({ form, checkbox: true });
  const missingFields = keys(omitBy(errorFormClone.address, isUndefined));
  const mismatchFieldsFromGoogle = isThai ? filter([...selectFields, 'postalCode'], o => !get(buyer, o)) : [];
  const oldField = filter(missingAddressFields, (o: string) =>
    isThai ? !o.includes('Txt') : !selectFields.includes(o),
  );
  const fields = filter(
    union(missingFields, oldField, mismatchFieldsFromGoogle),
    (o: string) => o === 'region' || o === 'barangay' || !get(buyer, o?.replace('Txt', '')),
  );
  const fieldInOrder = ['region', 'provinceTxt', 'province', 'districtTxt', 'district', 'barangay', 'postalCode'];

  return intersection(fieldInOrder, fields);
};
