/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryActions, ICategoryGeneralAction, IDeleteCategoryAction } from './CategoryActions';
import {
  requestDeleteCategory,
  requestGetDeliveryProfiles,
  requestGetCategory,
  requestReorderCategory,
  requestSearchCategory,
  requestUpdateCategory,
  requestGetCategoriesByStoreSlug,
  requestGetCategoryBySlug,
} from './CategoryRepository';
import { DeliveryProfile, CategoryFilterInputParams, Store, Category } from 'types';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';
import { RootState } from '../reduxStore';
import { logError } from '../../utils/error';
import { toggleIsCategoriesLoading } from 'redux/appState/AppStateActions';

const call: any = Effects.call;

export const getStore = (state: RootState) => state.store;

function* fetchDisplayedCategoryAsync(action: ICategoryGeneralAction<CategoryFilterInputParams>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    const categories: Category[] = yield call(
      requestSearchCategory,
      store.id,
      action.data.searchText,
      action.data.filter,
    );
    yield put({ type: CategoryActions.SET_DISPLAYED_CATEGORY_DATA, data: categories });
  } catch (error) {
    logError(error);
  }
}

function* watchFetchDisplayedCategoriesAsync() {
  yield takeEvery(CategoryActions.SET_DISPLAYED_CATEGORY_DATA_ASYNC, fetchDisplayedCategoryAsync);
}

export function* fetchCategoriesAsync() {
  try {
    yield put(toggleIsCategoriesLoading(true));
    const store: Partial<Store> = yield select(getStore);
    const categories: Category[] = yield call(requestGetCategory, store.id);
    yield put({ type: CategoryActions.SET_CATEGORY_DATA, data: categories });
  } finally {
    yield put(toggleIsCategoriesLoading(false));
  }
}

function* watchFetchCategoriesAsync() {
  yield takeEvery(CategoryActions.SET_CATEGORY_DATA_ASYNC, fetchCategoriesAsync);
}

function* deleteCategoryAsync(action: IDeleteCategoryAction) {
  const store: Partial<Store> = yield select(getStore);
  yield call(requestDeleteCategory, store?.id, action.data.categoryId);
  yield put({ type: CategoryActions.DELETE_CATEGORY, data: action.data });
}

function* watchDeleteCategoryAsync() {
  yield takeEvery(CategoryActions.DELETE_CATEGORY_ASYNC, deleteCategoryAsync);
}

function* updateCategoryAsync(action: ICategoryGeneralAction<Partial<Category>>) {
  const store: Partial<Store> = yield select(getStore);
  yield call(requestUpdateCategory, store?.id, action.data.id, action.data);
  yield put({ type: CategoryActions.UPDATE_CATEGORY, data: action.data });
}

function* watchUpdateCategoryAsync() {
  yield takeEvery(CategoryActions.UPDATE_CATEGORY_ASYNC, updateCategoryAsync);
}

function* reorderCategoryAsync(action: ICategoryGeneralAction<Partial<Category>[]>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    yield call(requestReorderCategory, store?.id, action.data);
    yield put({ type: CategoryActions.REORDER_CATEGORY, data: action.data });
  } catch (error) {
    logError(error);
  }
}

function* watchReorderCategoryAsync() {
  yield takeEvery(CategoryActions.REORDER_CATEGORY_ASYNC, reorderCategoryAsync);
}

function* fetchDeliveryProfile() {
  try {
    const deliveryProfile: DeliveryProfile[] = yield call(requestGetDeliveryProfiles);
    yield put({ type: CategoryActions.FETCH_DELIVERY_PROFILE, data: deliveryProfile });
  } catch (err) {
    logError(err);
  }
}

function* watchFetchDeliveryProfileAsync() {
  yield takeEvery(CategoryActions.FETCH_DELIVERY_PROFILE_ASYNC, fetchDeliveryProfile);
}

export function* fetchCategoriesBySlugAsync(action: ICategoryGeneralAction<{ storeSlug: string }>) {
  const categories: Category[] = yield call(requestGetCategoriesByStoreSlug, action.data.storeSlug);
  yield put({ type: CategoryActions.SET_CATEGORY_DATA, data: categories });
}

function* watchFetchCategoriesBySlugAsync() {
  yield takeEvery(CategoryActions.FETCH_CATEGORIES_BY_SLUG_ASYNC, fetchCategoriesBySlugAsync);
}

export function* fetchSelectedCategoryBySlugAsync(
  action: ICategoryGeneralAction<{ storeSlug: string; categorySlug: string }>,
) {
  const category: Category = yield call(requestGetCategoryBySlug, action.data.storeSlug, action.data.categorySlug);
  yield put({ type: CategoryActions.SET_SELECTED_CATEGORY, data: category });
}

function* watchFetchSelectedCategoryBySlugAsync() {
  yield takeEvery(CategoryActions.FETCH_SELECTED_CATEGORY_BY_SLUG_ASYNC, fetchSelectedCategoryBySlugAsync);
}

function* categorySaga() {
  yield all([
    watchFetchDisplayedCategoriesAsync(),
    watchDeleteCategoryAsync(),
    watchUpdateCategoryAsync(),
    watchFetchCategoriesAsync(),
    watchReorderCategoryAsync(),
    watchFetchDeliveryProfileAsync(),
    watchFetchCategoriesBySlugAsync(),
    watchFetchSelectedCategoryBySlugAsync(),
  ]);
}

export default categorySaga;
