import { Store } from 'types';
import { BuyerAddress, BuyerInfo } from './auth';

export interface FeeConfig {
  promptPayQr?: PromptPayQr;
  billPayment?: BillPayment;
  mobileBanking?: MobileBanking;
  openLoopCard?: OpenLoopCard;
  qrCredit?: QrCredit;
  trueWallet?: TrueWallet;
  linePay?: LinePay;
  weChatPay?: WeChatPay;
}

export interface PromptPayQr {
  enabled: boolean;
  percentage: number;
}

export interface BillPayment {
  enabled: boolean;
  percentage: number;
}

export interface MobileBanking {
  enabled: boolean;
  fixedAmt: number;
  currency: string;
  minimumAmt?: number;
}

export interface OpenLoopCard {
  visaMasterJCB: VisaMasterJcb;
  americanExpress: AmericanExpress;
}
export enum OpenLoopCardType {
  visaMasterJCB = 'visaMasterJCB',
  americanExpress = 'americanExpress',
}
export interface VisaMasterJcb {
  domestic: Domestic;
  overseas: Overseas;
}

export interface Domestic {
  enabled: boolean;
  percentage: number;
}

export interface Overseas {
  enabled: boolean;
  percentage: number;
}

export interface AmericanExpress {
  enabled: boolean;
  percentage: number;
}

export interface QrCredit {
  enabled: boolean;
  percentage: number;
}

export interface TrueWallet {
  enabled: boolean;
  percentage: number;
}

export interface LinePay {
  enabled: boolean;
  percentage: number;
}

export interface WeChatPay {
  enabled: boolean;
  percentage: number;
}
export enum KycStatus {
  UNVERIFIED = 'unverified',
  IN_PROGRESS = 'in_progress',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
}
export interface Merchant {
  id: string;
  referenceId?: string;
  kycStatus: string;
  owner?: {
    name: string;
    surName: string;
  };
  type?: string;
  accType?: string;
  email?: string;
  name?: string;
  address?: string;
  phoneNumber?: string;
  bankCode?: string;
  bankAccNum?: string;
  bankAccName?: string;
  logoUrl?: string;
  idCardImgUrl?: string;
  companyRegistrationDocUrl?: string;
  additionalDocUrl?: string;
  feeConfig?: FeeConfig;
  merchantPublicKey?: string;
  balance: number;
  merchantToken?: string;
  nonKycFeeConfig?: FeeConfig;
}

export enum FeeConfigMethod {
  promptPayQr = 'promptPayQr',
  billPayment = 'billPayment',
  mobileBanking = 'mobileBanking',
  openLoopCard = 'openLoopCard', // <-- debit cart
  qrCredit = 'qrCredit',
  trueWallet = 'trueWallet',
  linePay = 'linePay',
  weChatPay = 'weChatPay',
}

export const defaultPaymentMethods = [
  FeeConfigMethod.openLoopCard,
  FeeConfigMethod.promptPayQr,
  FeeConfigMethod.qrCredit,
  FeeConfigMethod.mobileBanking,
  FeeConfigMethod.trueWallet,
  FeeConfigMethod.linePay,
  FeeConfigMethod.weChatPay,
  FeeConfigMethod.billPayment,
];

export interface IGBPayGetQR {
  token?: string;
  amount?: string;
  referenceNo?: string;
  backgroundUrl?: string;
  detail?: string;
  customerName?: string;
  customerEmail?: string;
  publicKey?: string;
  checksum?: string;
  rememberWithToken?: boolean;
  responseUrl?: string;
  customerAddress?: string;
  customerTelephone?: string;
  merchantDefined1?: string;
  merchantName?: string;
  card?: {
    token?: string;
  };
  otp?: string;
  gbpReferenceNo?: string;
  bankCode?: string;
}

export interface ICheckoutPayload {
  shippingInformation?: IShippingInfoPayload;
  paymentInformation?: CardInfoDto;
}

export interface IShippingInfoPayload {
  phoneNumber: string;
  email: string;
  fullName: string;
  noteForDriver?: string;
  lineId?: string;
  allowLineOrderNotification?: boolean;
  isSelfPickedUp?: boolean;
  shippingAddress?: BuyerAddress;
  lat?: string;
  lng?: string;
}
export interface CardInfoDto {
  paymentMethod: string;
  cardNumber?: string;
  phoneNumber?: string;
  bankName?: string;
  token?: string;
  responseUrl?: string;
  merchantName?: string;
}
export interface SavedPaymentMethodsDto {
  paymentMethod?: string;
  expirationDate?: string;
  cardNumber?: string;
  name?: string;
  token?: string;
  phoneNumber?: string;
  bankName?: string;
  image?: string;
  securityCode?: string;
}
export interface CartCheckoutDto {
  id: string;
  orderNumber: number;
  total: number;
  currency: string;
  store?: Store;
  transaction?: TransactionDataFromCart;
  customerDetails?: BuyerInfo;
  cartId?: string;
  shouldSkipPaymentRequest?: boolean;
}
export interface TransactionDataFromCart {
  id: string;
  checksum?: string;
  backgroundUrl?: string;
  transactionId?: string;
  merchantToken?: string;
  merchantPublicKey?: string;
  responseUrl?: string;
}
export interface IDebitGetToken {
  rememberCard?: boolean;
  card: {
    number: string;
    expirationMonth: string;
    expirationYear: string;
    securityCode?: string;
    name?: string;
    token?: string;
    resultCode?: string;
    paymentMethod?: string;
  };
}
export interface CardComponentProps {
  title: string;
  images?: string[];
  onClick?: () => void;
  type: string;
  methodInfo?: { title?: string; paymentMethod?: string };
  selected?: boolean;
  isSelectable?: boolean;
  key: string;
}
export enum PaymentMethodResponseType {
  GET_QR_IMAGE = 'GET_QR_IMAGE',
  GET_BARCODE = 'GET_BARCODE',
  GET_TRUE_WALLET = 'GET_TRUE_WALLET',
  GET_MOBILE_BANKING = 'GET_MOBILE_BANKING',
  GET_DEBIT_CARD_RESPONSE = 'GET_DEBIT_CARD_RESPONSE',
}
export enum PaymentMethod {
  promptpay_qr = 'promptpay_qr',
  bill_payment = 'bill_payment',
  mobile_banking = 'mobile_banking',
  visa_domestic = 'open_loop_card.visa.domestic',
  visa_overseas = 'open_loop_card.visa.overseas',
  mastercard_domestic = 'open_loop_card.mastercard.domestic',
  mastercard_overseas = 'open_loop_card.mastercard.overseas',
  jcb_domestic = 'open_loop_cardjcb.domestic',
  jcb_overseas = 'open_loop_card.jcb.overseas',
  american_express = 'open_loop_card.american_express',
  qr_credit = 'qr_credit',
  true_wallet = 'true_wallet',
  line_pay = 'line_pay',
  wechat_pay = 'wechat_pay',
}
export interface SavedMethod {
  id?: string;
  userId?: string;
  paymentMethod?: string;
  paymentInfo?: SavedPaymentMethodsDto;
  rememberCard?: boolean;
}
export interface ChargeResultDto {
  resultCode: string;
  amount?: number;
  referenceNo?: string;
  gbpReferenceNo?: string;
  detail?: string;
  customerName?: string;
  customerEmail?: string;
  customerAddress?: string;
  customerTelephone?: string;
  resultMessage?: string;
  currencyCode?: string;
  cardNo?: string;
  date?: string;
  time?: string;
  thbAmount?: number;
}
export interface cardValidateForm {
  name?: string;
  cardNumber?: string;
  expirationDate?: string;
  securityCode?: string;
}
