import { logError } from 'utils/error';
import Router from 'next/router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { AppStateActions, toggleHidePostCode, toggleIsLoading } from 'redux/appState/AppStateActions';
import { BuyerInfo, IAuthLoginOptionParams } from 'types/auth';
import { IAuthAction, IAuthGeneralAction } from './AuthActions';
import { requestGetMe, requestLogout, requestPutMe } from './AuthRepository';
import i18n from 'i18next';
import { CartAction } from 'redux/cart/CartAction';
import { RootState } from 'redux/reduxStore';

const getStoreId = (state: RootState) => state.store.id;

function* loginAsyncAction(
  action: IAuthGeneralAction<{
    access_token: string;
    storeSlug?: string;
    options?: IAuthLoginOptionParams;
  }>,
) {
  try {
    const { access_token, storeSlug, options } = action.data;
    const isDisableRedirect = options?.isDisableRedirect;
    const storeId = yield select(getStoreId);
    const me = yield call(requestGetMe, storeId);
    yield put({
      type: IAuthAction.LOGIN,
      data: {
        access_token,
        user: me,
      },
    });
    yield put({
      type: CartAction.GET_CART_FROM_LOGIN,
    });
    yield put(toggleHidePostCode(false));
    if (options?.redirectUrl) {
      yield call(Router.replace, `${options?.redirectUrl}`);
    }
    if (storeSlug && (isDisableRedirect === undefined || !isDisableRedirect) && !options?.redirectUrl) {
      yield call(Router.replace, `/${storeSlug}`);
    }
  } catch (error) {
    logError(error);
    yield put({
      type: IAuthAction.LOGOUT,
    });
  }
}

function* watchLoginAsyncAction() {
  yield takeEvery(IAuthAction.LOGIN_ASYNC, loginAsyncAction);
}

function* logoutAsyncAction(action: IAuthGeneralAction<{ storeSlug?: string }>) {
  yield put(toggleIsLoading(true));
  yield call(requestLogout);
  yield put({
    type: IAuthAction.LOGOUT,
  });
  if (action?.data.storeSlug) {
    if (Router.pathname === '/[slug]') {
      Router.reload();
    } else {
      yield call(Router.push, `/${action?.data.storeSlug}`);
    }
  }
}

function* watchLogoutAsyncAction() {
  yield takeEvery(IAuthAction.LOGOUT_ASYNC, logoutAsyncAction);
}

// eslint-disable-next-line no-promise-executor-return
const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* updateAsyncAction(action: IAuthGeneralAction<BuyerInfo>) {
  try {
    yield put(toggleIsLoading(true));
    const storeId = yield select(getStoreId);
    yield call(requestPutMe, action.data, storeId);
    const newMe = yield call(requestGetMe, storeId);
    yield put({
      type: IAuthAction.UPDATE,
      data: newMe,
    });
    yield put(toggleIsLoading(false));
    yield put({
      type: AppStateActions.TOGGLE_MODEL_SUCCESS,
      data: true,
    });
    yield call(delay, 3000);
    yield put({
      type: AppStateActions.TOGGLE_MODEL_SUCCESS,
      data: false,
    });
  } catch (e) {
    logError(e);
    yield put({
      type: AppStateActions.SHOW_MODAL_WITH_BUTTON,
      data: {
        headerTxt: e?.response?.data?.message || i18n.t('Sorry, it seems there was an error.\nPlease try again'),
        acceptTxt: i18n.t('Try Again'),
      },
    });
  } finally {
    yield put(toggleIsLoading(false));
  }
}

function* watchUpdateAsyncAction() {
  yield takeEvery(IAuthAction.UPDATE_ASYNC, updateAsyncAction);
}

function* authSaga() {
  yield all([watchLoginAsyncAction(), watchLogoutAsyncAction(), watchUpdateAsyncAction()]);
}

export default authSaga;
