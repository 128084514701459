import axios from 'axios';
import { ChargeResultDto, IDebitGetToken, IGBPayGetQR } from 'types/merchant';

const API_GB_PAY = process.env.NEXT_PUBLIC_GBPAY_URL;
const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

const Axios = axios.create({
  baseURL: API_GB_PAY,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
// for payment method that have QR code: PromptPay, QR Credit, WeChat.
const getApiEndpoint = (payment: string) => {
  if (payment === 'qrcredit') {
    return `${API_GB_PAY}/gbp/gateway/${payment}`;
  } else {
    return `${API_GB_PAY}/v2/${payment}`;
  }
};
export const requestGetQrByMethod = async (payment: string, data: URLSearchParams) => {
  if (payment === 'qrcredit') {
    return Axios.post(getApiEndpoint(payment), data, { responseType: 'arraybuffer' }).then(res =>
      Buffer.from(res.data, 'binary').toString('base64'),
    );
  }
  return Axios.post(`${API_GB_PAY}/gbp/gateway/${payment}`, data, { responseType: 'arraybuffer' }).then(res =>
    Buffer.from(res.data, 'binary').toString('base64'),
  );
};
export const requestGetQrByRabbitLine = async (data: URLSearchParams) => {
  return Axios.post(`${API_GB_PAY}/v2/linepay`, data).then(res => res.data);
};
// to get OTP from TrueMoneyWallet
export const requestGetCheckoutByTrueMoneyWallet = async (data: URLSearchParams) => {
  return Axios.post(`${API_GB_PAY}/v2/trueWallet`, data).then(res => res.data);
};

// to get 3d secure from debit method
export const requestGetDebitCardSecure = async (data: URLSearchParams) => {
  return Axios.post(`${API_GB_PAY}/v2/tokens/3d_secured`, data).then(res => res.data);
};
// bill payment pdf
export const requestGetBillPaymentBarcode = async (data: URLSearchParams) => {
  return Axios.post(`${API_GB_PAY}/gbp/gateway/barcode`, data, { responseType: 'arraybuffer' }).then(
    res => new Blob([res.data]),
  );
};
export const requestGetTokenForDebitCard = async (tokenPayload: IDebitGetToken, publicKey: string) => {
  return Axios.post<IDebitGetToken>(`${API_GB_PAY}/v2/tokens`, tokenPayload, {
    headers: {
      Authorization: `Basic ${btoa(publicKey + ':')}`,
      'Content-Type': 'application/json',
    },
  }).then(res => res.data.card?.token);
};
export const requestGetDebitCharge = async (data: IGBPayGetQR) => {
  return axios.post<ChargeResultDto>(`${API_STORE}/api/payment/buyers/transactions/charge`, data).then(res => res.data);
};
export const requestGetMobileBanking = async (data: URLSearchParams) => {
  return Axios.post(`${API_GB_PAY}/v2/mobileBanking`, data).then(res => res.data);
};
