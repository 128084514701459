import axios from 'axios';
import { IAnalyticsEvent } from 'types/analytics';
const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

const api = axios.create({
  baseURL: `${API_STORE}/api/analytics/`,
});

export const trackAnalyticsEvent = async (event: IAnalyticsEvent) => {
  try {
    const res = await api.post('event/track', event).then(res => res.data);
    return res;
  } catch (error) {
    return;
  }
};
