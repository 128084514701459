import { DeliveryProfile, Category, CategoryFilterInputParams } from 'types';
import cloneDeep from 'lodash/cloneDeep';
import { CategoryActions, ICategoryAction } from './CategoryActions';

export interface CategoryReduxState {
  categories: Category[];
  displayedCategories: Category[];
  selectedCategory?: Partial<Category>;
  displayFilter: CategoryFilterInputParams;
  deliveryProfiles: DeliveryProfile[];
}

export const DEFAULT_CATEGORY_STATE: CategoryReduxState = {
  categories: [],
  displayedCategories: [],
  displayFilter: {},
  deliveryProfiles: [],
  selectedCategory: {},
};

/* eslint-disable max-lines, max-lines-per-function */
const CategoryReducer = (state = DEFAULT_CATEGORY_STATE, action: ICategoryAction): CategoryReduxState => {
  switch (action.type) {
    case CategoryActions.SET_CATEGORY_DATA:
      return {
        ...state,
        categories: action.data as Category[],
      };

    case CategoryActions.SET_DISPLAYED_CATEGORY_DATA:
      return {
        ...state,
        displayedCategories: action.data as Category[],
      };

    case CategoryActions.CLEAR_CATEGORY_DATA:
      return DEFAULT_CATEGORY_STATE;

    case CategoryActions.DELETE_CATEGORY:
      return {
        ...state,
        displayedCategories: state.displayedCategories.filter(category => category.id !== action.data.categoryId),
      };

    case CategoryActions.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.data,
      };

    case CategoryActions.UPDATE_CATEGORY: {
      const index = state.displayedCategories.findIndex(category => category.id === action.data.id);

      if (index > -1) {
        const displayedCategories = cloneDeep(state.displayedCategories);
        displayedCategories[index] = {
          ...state.displayedCategories[index],
          ...action.data,
        };

        return {
          ...state,
          displayedCategories,
        };
      }
      return state;
    }

    case CategoryActions.SET_CATEGORY_DISPLAY_FILTER:
      return {
        ...state,
        displayFilter: action.data,
      };

    case CategoryActions.FETCH_DELIVERY_PROFILE:
      return {
        ...state,
        deliveryProfiles: action.data,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
