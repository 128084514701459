import axios from 'axios';
import { transformSlug } from 'utils/convert';

import { IGetStoreParams, IPostCode, IUpdateDeliveryOptionParams, Store, UpdateLogo } from '../../types';
import { ApiStorePaths } from '../apiConstant';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

const Axios = axios.create({
  baseURL: API_STORE,
});

export const requestGetStoreInfo = async (slug: string, params?: IGetStoreParams) => {
  const data = await Axios.get<Store>(`${ApiStorePaths.get_buyer_store_info(transformSlug(slug))}`, { params }).then(
    res => res.data,
  );
  return data;
};

export const updateAvatarStore = file => {
  const formData = new FormData();
  formData.append('file', file);
  return Axios.post<UpdateLogo>(`${ApiStorePaths.get_store_avatar}`, formData).then(res => res.data);
};

export const requestCreateStore = body => Axios.post<Store>(`${ApiStorePaths.store}`, body).then(res => res.data);

export const requestUpdateStore = (body: Partial<Store>) => Axios.put(`${ApiStorePaths.store}`, body);

export const requestGetShippingDistance = (body: IUpdateDeliveryOptionParams) =>
  Axios.get<IPostCode>(`${ApiStorePaths.buyer_store(transformSlug(body.slug))}`, {
    params: { fullAddress: body.fullAddress, country: body?.country, lat: body?.lat, lng: body?.lng },
  }).then(res => res.data);
