/* eslint-disable complexity */
import { DeliveryProfile, Product } from '../../types';
import { ProductFilterInputParams } from '../../types/product';
import { ProductActions, IProductAction } from './ProductActions';
import { DataStorage } from 'utils/storage';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';

const ISSERVER = typeof window === 'undefined';
export interface ProductReduxState {
  products: Product[];
  displayedProducts: Product[];
  selectedProduct?: Product;
  displayFilter: ProductFilterInputParams;
  deliveryProfiles: DeliveryProfile[];
  productsInCart: Product[];
  recommendProducts: Product[];
  productDetailToUpdate: Product;
  indexOfProductToUpdate: number;
  isFetchingRecommendList: boolean;
  isOutOfRecommendProduct: boolean;
}

export const DEFAULT_PRODUCT_STATE: ProductReduxState = {
  products: [],
  displayedProducts: [],
  displayFilter: {},
  deliveryProfiles: [],
  productsInCart: [],
  recommendProducts: [],
  isFetchingRecommendList: false,
  isOutOfRecommendProduct: false,
  productDetailToUpdate: {
    name: '',
    type: '',
    description: '',
    unitPrice: 0,
    numberInStock: 0,
    unitType: '',
    unitDetail: '',
    displayProductEnabled: true,
    deliveryProfileId: '',
    categoryIds: [],
    photoUrls: [],
    isInStock: true,
    finalPrice: 0,
    quantity: 0,
  },
  indexOfProductToUpdate: NaN,
};

/* eslint-disable max-lines, max-lines-per-function */
const ProductReducer = (state = DEFAULT_PRODUCT_STATE, action: IProductAction): ProductReduxState => {
  switch (action.type) {
    case ProductActions.SET_PRODUCTS_DATA:
      return {
        ...state,
        products: action.data as Product[],
      };
    case ProductActions.SET_IS_OUT_OF_RECOMMEND_PRODUCT:
      return {
        ...state,
        isOutOfRecommendProduct: action.data,
      };
    case ProductActions.RESET_RECOMMENDED_LIST:
      return {
        ...state,
        recommendProducts: state.recommendProducts.slice(0, 8),
      };
    case ProductActions.SET_FETCHING_RECOMMEND_LIST:
      return {
        ...state,
        isFetchingRecommendList: action.data,
      };

    case ProductActions.SET_DISPLAYED_PRODUCTS_DATA:
      return {
        ...state,
        displayedProducts: action.data as Product[],
      };

    case ProductActions.CLEAR_PRODUCT_DATA:
      return DEFAULT_PRODUCT_STATE;

    case ProductActions.DELETE_PRODUCT:
      return {
        ...state,
        displayedProducts: state.displayedProducts.filter(product => product.id !== action.data.productId),
      };

    case ProductActions.ADD_CART_PRODUCTS: {
      let newSelected = !ISSERVER ? JSON.parse(DataStorage?.load({ key: 'ProductsInCart' })) : state.productsInCart;
      if (newSelected !== null) newSelected.push(action.data);
      else newSelected = [action.data];
      if (!ISSERVER) DataStorage.save({ key: 'ProductsInCart', data: newSelected });
      return {
        ...state,
        productsInCart: newSelected,
      };
    }

    case ProductActions.DELETE_CART_PRODUCTS:
    case ProductActions.UPDATE_CART_PRODUCTS:
      if (!ISSERVER) DataStorage.save({ key: 'ProductsInCart', data: action.data });
      return {
        ...state,
        productsInCart: action.data,
      };
    case ProductActions.SET_SELECTED_PRODUCT:
    case ProductActions.FETCH_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.data,
      };

    case ProductActions.UPDATE_PRODUCT: {
      const index = state.displayedProducts.findIndex(product => product.id === action.data.id);

      if (index > -1) {
        const displayedProducts = cloneDeep(state.displayedProducts);
        displayedProducts[index] = {
          ...state.displayedProducts[index],
          ...action.data,
        };

        return {
          ...state,
          displayedProducts,
        };
      }
      return state;
    }

    case ProductActions.SET_PRODUCT_DISPLAY_FILTER:
      return {
        ...state,
        displayFilter: action.data,
      };

    case ProductActions.FETCH_DELIVERY_PROFILE:
      return {
        ...state,
        deliveryProfiles: action.data,
      };

    case ProductActions.GET_RECOMMENDATION_PRODUCTS:
      return {
        ...state,
        recommendProducts: uniqBy([...state.recommendProducts, ...action.data], 'id'),
      };
    case ProductActions.SET_PRODUCT_DETAIL_TO_UPDATE:
      return {
        ...state,
        productDetailToUpdate: {
          ...state.productDetailToUpdate,
          ...action.data,
        },
      };
    case ProductActions.SET_INDEX_OF_PRODUCT_TO_UPDATE:
      return {
        ...state,
        indexOfProductToUpdate: action.data,
      };
    default:
      return state;
  }
};

export default ProductReducer;
